import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LureTable, LureChip } from '@lureapps/lure-ui';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BookingService from '../../../../services/BookingService';


function CustomerActivity() {
  const user = useSelector((state) => state.auth.user);
  const Customer = useSelector((state) => state.auth.user.Customer);

  const initialActivity = [{
    date: user.createdAt,
    type: 'Created account',
    details: 'Your account was created.',
  }];

  const activityColumns = [{
    title: 'When',
    render: (row) => moment(row.date, 'x').calendar(),
  }, {
    title: 'Activity Type',
    field: 'type',
    render: (row) => <LureChip label={`${row.type}`} />,
  }, {
    title: 'Activity details',
    field: 'details',
  }];

  const [recentActivity, setRecentActivity] = useState(initialActivity);
  const history = useHistory();

  useEffect(() => {
    BookingService.getCustomerBookings(get(Customer, '_id', ''))
      .then(({ data: { customerBookings: Bookings } }) => {
        const last5Bookings = Bookings
          .sort((a, b) => moment(b.createdAt) - moment(a.createdAt)).slice(0, 5)
          .map((booking) => ({
            _id: booking._id,
            date: booking.createdAt,
            type: 'Made a booking',
            details: `You made a booking ${get(booking, 'bookingReference')} at ${get(booking, 'Store.name')}`,
          }));

        setRecentActivity((prevState) => [...prevState, ...last5Bookings]);
      });
  }, [Customer, setRecentActivity]);

  return (
    <>
      <Paper>
        <LureTable
          columns={activityColumns}
          data={recentActivity}
          title={'Activity'}
          options={{ pageSize: 5, showTitle: true, toolbar: true }}
          onRowClick={(e, row) => (row && row._id ? history.push(`/app/bookings/requests/${row._id}`) : null)}
        />
      </Paper>
    </>
  );
}

export default CustomerActivity;
