import React, { useCallback } from 'react';
import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { get } from 'lodash';
import { LureMap, LureIcon } from '@lureapps/lure-ui';
import { useDispatch } from 'react-redux';
import { openStoreModal, openTradingHourModal } from '../../../store/actions/modals';
import { useIsTablet } from '../../shared/mediaQueries';

const styles = (theme) => ({
  padding: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(5)}px`,
  },
  iconContainer: {
    display: 'flex',
    alignSelf: 'center',
    marginRight: theme.spacing(1),
    color: `${theme.colors.darkGrey} !important`,
  },
  flex: {
    display: 'flex',
  },
  muted: {
    color: `${theme.colors.darkGrey} !important`,
  },
  buffer: {
    marginRight: theme.spacing(2),
  },
});

const Information = ({ Store }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();

  const openEditStoreModal = useCallback(() => {
    dispatch(openStoreModal());
  }, [dispatch]);

  const handleStoreHoursEdit = useCallback(() => {
    dispatch(openTradingHourModal(Store));
  }, [dispatch, Store]);

  let lat = -27.4648;
  let lng = 153.0095;

  if (get(Store, 'address.lat', '')) lat = Store.address.lat;
  if (get(Store, 'address.lng', '')) lng = Store.address.lng;

  return (
    <Grid container direction={useIsTablet() ? 'column-reverse' : ''}>
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={6}
        justify={'space-evenly'}
        direction={'column'}
        className={classes.padding}
        spacing={useIsTablet() ? 3 : 0}
      >
        <Grid item>
          <Typography variant={'h5'}>{get(Store, 'name', '')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'}>{get(Store, 'email', '')}</Typography>
          <Typography variant={'body1'}>{get(Store, 'phone', '')}</Typography>
          <Typography variant={'body1'}>{get(Store, 'address.formatted', '')}</Typography>
        </Grid>
        <Grid item container justify={'space-between'}>
          <Grid item className={classes.flex}>
            <Grid item className={classes.buffer}>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={openEditStoreModal}
              >
                Edit Store Information
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={handleStoreHoursEdit}
              >
                Edit Store Hours
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.flex}>
            <Grid container>
              <Grid item className={classes.iconContainer}>
                <LureIcon name={'clock'} className={classes.muted} />
              </Grid>
              <Grid item className={classes.iconContainer}>
                <Typography variant={'body1'} className={classes.muted}>
                  {get(Store, 'timezone', '')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <LureMap
          markers={[{ lat, lng }]}
          zoom={14}
          height={'250px'}
          borderRadius={'0px 18px 18px 0px'}
          center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
        />
      </Grid>
    </Grid>
  );
};

export default Information;
