import { gql } from 'apollo-boost';
import { pick } from 'lodash';
import gqlClient from './GraphQLService';

const SINGLE_STORE = gql`
    fragment SingleStore on Store {
        _id
        name
        email
        phone
        timezone
        address {
            addressLine1
            addressLine2
            suburb
            city
            postcode
            country
            formatted
            lat
            lng
        }
        Staff {
            _id
            firstName
            lastName
            fullName
            email
            phone
            primaryStore {
                _id
                name
            }
        }
        Company {
            name
            website
        }
      tradingHours {
        day
        open {
          hour
          minute
        }
        close {
          hour
          minute
        }
        isOpen
      }
    }
`;

const GQL_GET_STORES = gql`
  query {
    getStores {
      _id
      name
      phone
      email
      timezone
      address {
          formatted
          lat
          lng
      }
      Staff {
          _id
          fullName
      }
    }
  }
`;


const GQL_GET_STORE = gql`
  query getStore($_id:ID) {
    getStore(_id: $_id) {
        ...SingleStore
    }
  }
  ${SINGLE_STORE}
`;

const GQL_CREATE_STORE = gql`
  mutation createStore ($storeInput:StoreInput) {
    createStore(store:$storeInput) {
      _id
      name
      email
      phone
      address {
        addressLine1
        addressLine2
        suburb
        city
        postcode
        country
        formatted
      }
    }
  }
`;

const GQL_UPDATE_STORE = gql`
  mutation updateStore ($_id:ID!, $storeInput:StoreInput) {
    updateStore(_id: $_id, store:$storeInput) {
      ...SingleStore
    }
  }
  ${SINGLE_STORE}
`;

const GQL_DELETE_STORE = gql`
  mutation deleteStore ($_id:ID!) {
    deleteStore(_id: $_id) {
      _id
    }
  }
`;

const GQL_SEARCH_STORES = gql`
  query searchStore($searchQuery:String!) {
    searchStores(searchQuery:$searchQuery) {
      _id
      name
    }
  }
`;

const GQL_GET_STORE_TIMESLOTS = gql`
  query getStoreTimeslots($storeId:ID!) {
    getStoreTimeslots(storeId:$storeId) {
      day
      dayTimestamp
      timeslots
    }
  }
`;

const GQL_GET_GOOGLE_PLACE_DETAIL = gql`
    query placeDetails($placeId: ID!) {
      placeDetails(placeId: $placeId) {
        name
        formattedAddress
        phone
      }
    }
`;

class StoreService {
  static getStores() {
    return gqlClient.query({
      query: GQL_GET_STORES,
    });
  }

  static getStore(_id) {
    return gqlClient.query({
      query: GQL_GET_STORE,
      variables: {
        _id,
      },
    })
      .then((data) => data);
  }

  static createStore(storeData) {
    return gqlClient.mutate({
      mutation: GQL_CREATE_STORE,
      variables: {
        storeInput: this._parseStoreInput(storeData),
      },
    })
      .then((data) => data);
  }

  static updateStore(_id, storeData) {
    return gqlClient.mutate({
      mutation: GQL_UPDATE_STORE,
      variables: {
        _id,
        storeInput: this._parseStoreInput(storeData),
      },
    })
      .then((data) => data);
  }

  static deleteStore(_id) {
    return gqlClient.mutate({
      mutation: GQL_DELETE_STORE,
      variables: {
        _id,
      },
    })
      .then((data) => data);
  }

  static searchStores(searchQuery) {
    return gqlClient.mutate({
      mutation: GQL_SEARCH_STORES,
      variables: {
        searchQuery,
      },
    })
      .then((data) => data);
  }

  static getStoreTimeslots(storeId) {
    return gqlClient.query({
      query: GQL_GET_STORE_TIMESLOTS,
      variables: {
        storeId,
      },
    });
  }

  static googlePlaceDetail(placeId) {
    return gqlClient.query({
      query: GQL_GET_GOOGLE_PLACE_DETAIL,
      variables: {
        placeId,
      },
    });
  }

  static _parseStoreInput(storeInput) {
    return pick(storeInput, [
      '_id',
      'name',
      'phone',
      'email',
      'address.googlePlaceId',
      'address.formatted',
      'tradingHours',
    ]);
  }
}

export default StoreService;
