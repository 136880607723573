import React, { useEffect, useState } from 'react';
import { Button, Grid, MuiThemeProvider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { LureBookingsMenu, LurePageHeading, LurePaper } from '@lureapps/lure-ui';
import { useHistory, useParams } from 'react-router-dom';
import Information from './Information';
import Notes from './Notes';
import AdminActivity from './AdminActivity';
import { getCustomer } from '../../../store/actions/AdminPortal/customer';

import black from '../../../theme/black';

import BookingService from '../../../services/BookingService';

const CustomerDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { _id } = useParams();
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [historicBookings, setHistoricBookings] = useState([]);

  useEffect(() => {
    dispatch(getCustomer(_id));
    BookingService.getBookingTimeline(_id, 'Customer')
      .then(({ data: { bookingTimeline: { upcomingBookings: ub, historicBookings: hb } } }) => {
        setUpcomingBookings(ub);
        setHistoricBookings(hb);
      });
  }, [_id, dispatch]);

  return (
    <>
      <LurePageHeading
        title={'Customers'}
        action={(
          <MuiThemeProvider theme={black}>
            <Button variant={'outlined'} onClick={() => history.goBack()}>Go back</Button>
          </MuiThemeProvider>
        )}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4} xl={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LurePaper shadow>
                <Information />
              </LurePaper>
            </Grid>
            <Grid item xs={12}>
              <LurePaper>
                <LureBookingsMenu
                  upcomingBookings={upcomingBookings}
                  historicBookings={historicBookings}
                  onBookingClick={(booking) => history.push(`/app/bookings/requests/${booking._id}`)}
                  entity={'Customer'}
                />
              </LurePaper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LurePaper noPadding>
                <Notes />
              </LurePaper>
            </Grid>
            <Grid item xs={12}>
              <LurePaper noPadding>
                <AdminActivity bookings={[...upcomingBookings, ...historicBookings]} />
              </LurePaper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerDetail;
