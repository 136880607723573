import React, { useCallback } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';


import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { required } from '../shared/formHelpers/validation';
import { closeRegistrationModal } from '../../store/actions/modals';
import { createCompany, updateCompany } from '../../store/actions/company';
import { renderLureTextField } from '../shared/formHelpers/fields';
import { CUSTOMER } from '../../constants/userRoles';
import { snackError } from '../../store/actions/snackbar';

const RegistrationModal = () => {
  const dispatch = useDispatch();
  const Company = useSelector((state) => state.auth.user.CurrentCompany);
  const userLoading = useSelector((state) => state.auth.userLoading);
  const isOpen = useSelector((state) => state.modals.registrationModal.isOpen);
  const userRole = useSelector((state) => state.auth.user.role);

  const submit = useCallback((values) => {
    if (values._id) {
      return dispatch(updateCompany(values._id, values))
        .then(() => dispatch(closeRegistrationModal()))
        .catch(() => dispatch(snackError('Error updating company details.')));
    }
    return dispatch(createCompany(values))
      .then(() => dispatch(closeRegistrationModal()))
      .catch(() => dispatch(snackError('Error creating company.')));
  }, [dispatch]);

  return (
    <>
      {
        !isOpen
        && !userLoading
        && userRole !== CUSTOMER
        && (!get(Company, 'name') || !get(Company, 'email'))
        && (
          <LureDialog
            open
            maxWidth={'sm'}
            fullWidth
            disableBackdropClick
          >
            <LureDialogTitle>
              Welcome to Lure
            </LureDialogTitle>
            <Form
              onSubmit={submit}
              initialValues={Company}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <LureDialogContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name={'name'}
                          label={'Name *'}
                          placeholder={'Company Name'}
                          component={renderLureTextField}
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name={'email'}
                          label={'Email *'}
                          placeholder={'Company Email (required)'}
                          component={renderLureTextField}
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name={'phone'}
                          label={'Phone'}
                          placeholder={'Company Phone'}
                          component={renderLureTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name={'website'}
                          label={'Website'}
                          placeholder={'Company Website'}
                          component={renderLureTextField}
                        />
                      </Grid>
                    </Grid>
                  </LureDialogContent>
                  <LureDialogActions>
                    <Grid container>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          color={'primary'}
                          variant={'contained'}
                          type={'submit'}
                        >
                          Complete Registration
                        </Button>
                      </Grid>
                    </Grid>
                  </LureDialogActions>
                </form>
              )}
            />
          </LureDialog>
        )
      }
    </>
  );
};

export default RegistrationModal;
