import React, { useCallback, useState } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import {
  LureDialog,
  LureDialogContent,
  LureDialogTitle,
  LureDialogActions,
} from '@lureapps/lure-ui';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { closeChangeAccountModal, closeCustomerSettingsModal } from '../../store/actions/modals';
import { renderLureTextField } from '../shared/formHelpers/fields';
import { required } from '../shared/formHelpers/validation';
import CustomerService from '../../services/CustomerService';
import { snackError, snackSuccess } from '../../store/actions/snackbar';
import { setAuthUser as setUser } from '../../store/actions/auth';

const styles = (theme) => ({
  leftBuffer: {
    marginLeft: theme.spacing(3),
  },
  rightBuffer: {
    marginRight: theme.spacing(3),
  },
});

const CustomerSettingsModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.customerSettingsModal.isOpen);
  const Customer = useSelector((state) => state.modals.customerSettingsModal.Customer);

  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const submit = useCallback(async (values) => {
    setLoading(true);
    CustomerService.updateCustomer(Customer._id, values)
      .then(() => {
        dispatch(snackSuccess('Information Updated'));
        dispatch(setUser());
        dispatch(closeCustomerSettingsModal());
      })
      .catch(() => dispatch(snackError('Could not update information')))
      .finally(() => setLoading(false));
  }, [dispatch, Customer]);

  return (
    <LureDialog
      open={isOpen}
      onClose={() => dispatch(closeChangeAccountModal())}
      maxWidth={'sm'}
      fullWidth
    >
      <LureDialogTitle>
        Edit your information
      </LureDialogTitle>
      <Form
        onSubmit={submit}
        initialValues={Customer}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <LureDialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name={'firstName'}
                        label={'First Name'}
                        placeholder={'Enter your first name'}
                        component={renderLureTextField}
                        validate={required}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name={'lastName'}
                        label={'Last Name'}
                        placeholder={'Enter your last name'}
                        component={renderLureTextField}
                        validate={required}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name={'email'}
                        label={'Email'}
                        placeholder={'Enter email'}
                        component={renderLureTextField}
                        validate={required}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name={'phone'}
                        label={'Phone'}
                        placeholder={'Enter phone'}
                        component={renderLureTextField}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </LureDialogContent>
            <LureDialogActions>
              <Grid container justify={'space-around'}>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant={'outlined'}
                    onClick={() => dispatch(closeCustomerSettingsModal())}
                    className={classes.rightBuffer}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    className={classes.leftBuffer}
                    type={'submit'}
                  >
                    {loading ? 'Loading...' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </LureDialogActions>
          </form>
        )}
      />
    </LureDialog>
  );
};

export default CustomerSettingsModal;
