import React from 'react';
import { useSelector } from 'react-redux';
import { LureTable } from '@lureapps/lure-ui';
import moment from 'moment';
import { Typography } from '@material-ui/core';


function TradingHoursList() {
  const company = useSelector((state) => state.auth.user.CurrentCompany);
  const columns = [{
    title: 'Day',
    field: 'day',
    displayName: 'Day',
    render(th) {
      return (
        <Typography>{moment().day(th.day + 1).format('dddd')}</Typography>
      );
    },
  }, {
    title: 'Opening Hours',
    field: 'open',
    displayName: 'Opening Hours',
    render(th) {
      return (
        <Typography>{th.isOpen
          ? moment().startOf('day').hour(th.open.hour).minute(th.open.minute)
            .format('h:mma')
          : 'Closed'}
        </Typography>
      );
    },
  }, {
    title: 'Closing Hours',
    field: 'close',
    render(th) {
      return (
        <Typography>{th.isOpen
          ? moment().startOf('day').hour(th.close.hour).minute(th.close.minute)
            .format('h:mma')
          : ''}
        </Typography>
      );
    },
  }];

  return (
    <LureTable
      columns={columns}
      options={{ paging: false }}
      data={company.tradingHours}
    />
  );
}

export default TradingHoursList;
