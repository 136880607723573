import React, { useEffect, useState } from 'react';
import { LurePageHeading, LureTable } from '@lureapps/lure-ui';
import { Paper, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';
import BookingService from '../../../services/BookingService';
import { chipHelper, chipStyles } from '../../bookings/requests/Bookings';

const CustomerBookings = () => {
  const history = useHistory();
  const Customer = useSelector((state) => state.auth.user.Customer);
  const [bookings, setBookings] = useState([]);

  const useStyles = makeStyles(chipStyles);
  const classes = useStyles();

  const columns = [{
    title: 'Booking No.',
    field: 'bookingReference',
    render: (Booking) => `${get(Booking, 'bookingReference', '')}`,
  }, {
    title: 'Created',
    field: 'createdAt',
    defaultSort: 'desc',
    render: (Booking) => moment(get(Booking, 'createdAt'), 'x').calendar(),
  }, {
    title: 'Requested Date & Time',
    field: 'timeslots[0].timeslot',
    render: (Booking) => moment(Booking.timeslots[0].timeslot, 'x').format('Do MMM YYYY, h:mma'), // will need to be looked at again when deciding on multi-timeslots
  }, {
    title: 'Company',
    field: 'Company.name',
  }, {
    title: 'Store',
    field: 'Store.name',
  }, {
    title: 'Status',
    field: 'status',
    render: (Booking) => chipHelper(get(Booking, 'status'), classes),
  }];

  useEffect(() => {
    BookingService.customerBookings()
      .then(({ data: { me: { Customer: { Bookings: { nodes } } } } }) => {
        setBookings(nodes);
      });
  }, [Customer]);

  return (
    <>
      <LurePageHeading title={'Your Bookings'} />
      <Paper>
        <LureTable
          columns={columns}
          data={bookings}
          options={{ draggable: false }}
          onRowClick={(e, Booking) => history.push(`/app/bookings/requests/${Booking._id}`)}
        />
      </Paper>
    </>
  );
};

export default CustomerBookings;
