import { gql } from 'apollo-boost';
import { get } from 'lodash';
import gqlClient from './GraphQLService';

const GQL_SINGLE_CUSTOMER = gql`
  fragment SingleCustomer on CompanyCustomer {
    _id
    firstName
    lastName
    fullName
    email
    phone
    createdAt
    primaryStore {
        _id
        name
        phone
        email
        address {
            addressLine1
            addressLine2
            suburb
            city
            postcode
            country
        }
    }
    Notes {
      _id
      content
      createdAt
      CreatedBy {
        _id
        name
      }
    }
    Customer {
      _id
    }
  }
`;

export const GQL_CREATE_CUSTOMER = gql`
  mutation createCompanyCustomer($companyCustomer: CompanyCustomerInput!) {
      createCompanyCustomer(companyCustomer: $companyCustomer) {
      ...SingleCustomer
    }
  }
  ${GQL_SINGLE_CUSTOMER}
`;

export const GQL_UPDATE_COMPANY_CUSTOMER = gql`
  mutation updateCompanyCustomer($_id: ID!, $companyCustomer: CompanyCustomerInput!) {
    updateCompanyCustomer(_id: $_id, companyCustomer: $companyCustomer) {
      ...SingleCustomer
    }
  }
  ${GQL_SINGLE_CUSTOMER}
`;

export const GQL_ADD_CUSTOMER_NOTE = gql`
  mutation customerAddNote($_id: ID!, $note: NoteInput!) {
    companyCustomerAddNote(_id: $_id, note: $note) {
      ...SingleCustomer
    }
  }
  ${GQL_SINGLE_CUSTOMER}
`;

export const GQL_GET_CUSTOMERS = gql`
  query companyCustomers {
    companyCustomers {
      _id
      firstName
      lastName
      fullName
      email
      phone
      createdAt
      primaryStore {
          _id
          name
          phone
          email
      }
      Customer {
        _id
      }
    }
  }
`;

export const GQL_UPDATE_CUSTOMER = gql`
    mutation updateCustomer($_id: ID!, $customer: CustomerInput) {
      updateCustomer(_id: $_id, customer: $customer) {
        _id
        firstName
        lastName
        email
        phone
      }
    }
`;

export const GQL_DELETE_CUSTOMER = gql`
  mutation deleteCompanyCustomer($_id: ID!) {
      deleteCompanyCustomer(_id: $_id) {
      ...SingleCustomer
    }
  }
  ${GQL_SINGLE_CUSTOMER}
`;

export const GQL_GET_CUSTOMER = gql`
  query getCustomer($_id: ID!) {
      companyCustomer(_id: $_id) {
      ...SingleCustomer
    }
  }
  ${GQL_SINGLE_CUSTOMER}
`;

export const GQL_COMPANY_CUSTOMER_EXISTS = gql`
  query customerExists($email:String!){
    companyCustomerExists(email:$email)
  }
`;

class CustomerService {
  static parseCustomer(data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
    };
  }

  static parseCompanyCustomer(data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      primaryStore: get(data, 'primaryStore._id'),
      customerId: get(data, 'Customer._id', null),
    };
  }

  static createCustomer(data) {
    const customerData = CustomerService.parseCompanyCustomer(data);

    return gqlClient.mutate({
      mutation: GQL_CREATE_CUSTOMER,
      variables: { companyCustomer: customerData },
    });
  }

  static updateCustomer(_id, data) {
    return gqlClient.mutate({
      mutation: GQL_UPDATE_COMPANY_CUSTOMER,
      variables: {
        _id,
        companyCustomer: CustomerService.parseCompanyCustomer(data),
      },
    })
      .then(({ data: { updateCompanyCustomer } }) => updateCompanyCustomer);
  }

  static deleteCustomer(_id) {
    return gqlClient.mutate({
      mutation: GQL_DELETE_CUSTOMER,
      variables: {
        _id,
      },
    })
      .then(({ data: { deleteCustomer } }) => deleteCustomer);
  }

  static getCustomers() {
    return gqlClient.query({
      query: GQL_GET_CUSTOMERS,
    })
      .then(({ data: { companyCustomers } }) => companyCustomers);
  }

  static getCustomer(_id) {
    return gqlClient.query({
      query: GQL_GET_CUSTOMER,
      variables: {
        _id,
      },
    })
      .then(({ data: { companyCustomer } }) => companyCustomer);
  }

  static addNote(_id, note) {
    return gqlClient.mutate({
      mutation: GQL_ADD_CUSTOMER_NOTE,
      variables: {
        _id,
        note,
      },
    })
      .then(({ data: { companyCustomerAddNote } }) => companyCustomerAddNote);
  }

  static companyCustomerExists(email) {
    return gqlClient.query({
      query: GQL_COMPANY_CUSTOMER_EXISTS,
      variables: {
        email,
      },
    });
  }
}

export default CustomerService;
