import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import StoreAutocomplete from '../../shared/AutoCompletes/StoreAutocomplete';
import { updateStaffMember } from '../../../store/actions/AdminPortal/staff';

const styles = (theme) => ({
  mutedText: {
    color: theme.colors.darkGrey,
  },
});

const StoreInformation = ({ Store }) => {
  const dispatch = useDispatch();

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const StaffMember = useSelector((state) => state.AdminPortal.staff.activeStaffMember);

  const updateStore = useCallback((store) => {
    StaffMember.primaryStore = store;
    dispatch(updateStaffMember(StaffMember._id, StaffMember));
  }, [StaffMember, dispatch]);

  return (
    <>
      <Grid container justify={'space-between'}>
        <Grid item>
          <Grid container spacing={2} direction={'column'}>
            <Grid item>
              <Typography variant={'body2'} className={classes.mutedText}>Primary Store</Typography>
              <Typography variant={'h6'}>{ get(Store, 'name') }</Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body1'}>{ get(Store, 'phone', '') }</Typography>
              <Typography variant={'body1'}>{ get(Store, 'email', '') }</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <StoreAutocomplete
            label={'Change Primary Store'}
            size={'small'}
            onChange={(e, store) => updateStore(store)}
            getOptionDisabled={(option) => get(option, '_id') === get(Store, '_id')}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StoreInformation;
