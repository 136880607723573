import { get } from 'lodash';
import {
  LOGOUT, REGISTER, SET_CURRENT_COMPANY, SET_USER,
} from '../actions/auth';

const initialState = {
  userIsLoggedIn: false,
  userLoading: true,
  user: {
    CurrentCompany: null,
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        userIsLoggedIn: get(action.payload, '_id', false),
        userLoading: false,
      };

    case SET_CURRENT_COMPANY:
      return {
        ...state,
        user: {
          ...state.user,
          CurrentCompany: {
            ...action.payload,
          },
        },
      };

    case REGISTER:
      return {
        ...state,
        userIsLoggedIn: true,
      };

    case LOGOUT:
      return {
        ...state,
        userIsLoggedIn: false,
        userLoading: false,
        user: initialState.user,
      };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default auth;
