import { createMuiTheme } from '@material-ui/core';
import main from './main';

export default createMuiTheme({
  ...main,
  palette: {
    primary: {
      light: '#5D5D5D',
      main: '#212121',
      dark: '#0C0C0C',
    },
  },
});
