import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { LurePaper } from '@lureapps/lure-ui';
import ProductCard from '../../modals/AcceptBookingModal/ProductCard';
import EmptyCart from '../../../assets/images/empty-cart.svg';

const styles = (theme) => ({
  heading: {
    textTransform: 'uppercase',
    color: theme.colors.darkGrey,
  },
  buffer: {
    margin: theme.spacing(1),
  },
  emptyStateContainer: {
    padding: theme.spacing(3),
  },
  emptyState: {
    maxWidth: theme.spacing(17),
    marginTop: theme.spacing(2),
  },
  emptyStateText: {
    color: theme.colors.darkGrey,
  },
});

function EmptyProductPanel({ classes, emptyStateCopy }) {
  return (
    <Grid container alignItems={'center'} justify={'center'} direction={'column'} spacing={3}>
      <Grid item>
        <img src={EmptyCart} alt={'empty'} className={classes.emptyState} />
      </Grid>
      <Grid item>
        <Typography variant={'subtitle'} className={classes.emptyStateText}>{emptyStateCopy}</Typography>
      </Grid>
    </Grid>
  );
}

function ProductPanel({ Products = [], emptyStateCopy }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <LurePaper shadow>
        <Grid container spacing={2} direction={'column'}>
          <Grid item>
            <Typography variant={'body2'} className={classes.heading} gutterBottom>Fitting Room Products</Typography>
          </Grid>
          {!Products.length
            ? (
              <EmptyProductPanel emptyStateCopy={emptyStateCopy} classes={classes} />
            ) : (
              <Grid item container>
                { Products.map((product) => (
                  <Grid container className={classes.buffer} key={product.handle}>
                    <ProductCard product={product} />
                  </Grid>
                ))}
              </Grid>
            )}
        </Grid>
      </LurePaper>
    </>
  );
}

export default ProductPanel;
