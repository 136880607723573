import {
  CLEAR_ACTIVE_STAFF_MEMBER,
  SET_ACTIVE_STAFF_MEMBER,
  SET_STAFF,
  RESET_STAFF,
} from '../../actions/AdminPortal/staff';

const initialState = {
  staffList: [],
  activeStaffMember: {
    _id: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  },
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF:
      return {
        ...state,
        staffList: action.payload,
      };

    case SET_ACTIVE_STAFF_MEMBER:
      return {
        ...state,
        activeStaffMember: action.payload,
      };

    case CLEAR_ACTIVE_STAFF_MEMBER:
      return {
        ...state,
        activeStaffMember: initialState.activeStaffMember,
      };

    case RESET_STAFF:
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};

export default staff;
