import { createMuiTheme } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';

export const spacing = 6;
const main = '#F7316B';
const green = '#22CC96';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#FFFFFF',
      main,
      dark: '#E12D62',
    },
    secondary: {
      light: '#F7316B',
      main: '#FFFFFF',
      dark: '#FCC6D6',
    },
    background: {
      default: '#F5F6FA',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: "'Open Sans', 'sans-serif'",
    fontStyle: 'normal',
    fontWeightLight: 300,
    fontWeightRegular: 600,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h4: {
      fontSize: 28,
      textTransform: 'none',
    },
    h5: {
      fontWeight: 700,
      textTransform: 'none',
    },
    h6: {
      fontSize: 18,
      textTransform: 'none',
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
  },
  colors: {
    grey: '#FAFAFA',
    darkGrey: '#B9B8B9',
    mediumGrey: '#F8F8F8',
    lightGrey: '#FCFDFD',
    borderGrey: 'rgba(186, 186, 186, 0.3)',
    facebookBlue: '#3b5998',
    green,
    red: '#FC5F65',
    blue: '#00CCF0',
    orange: '#FFBF66',
  },
  spacing,
  shape: {
    borderRadius: 18,
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiDrawer: {
      paper: {
        webkitBoxShadow: '3px 0px 3px 0px rgba(0,0,0,0.08)', // Values here taken from XD Document
        mozBoxShadow: '3px 0px 3px 0px rgba(0,0,0,0.08)',
        boxShadow: '3px 0px 3px 0px rgba(0,0,0,0.08)',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        paddingLeft: spacing * 6,
        paddingRight: spacing * 6,
        boxShadow: 'none !important',
        outline: 'none',
      },
      text: {
        paddingLeft: spacing * 6,
        paddingRight: spacing * 6,
        border: 0,
      },
      contained: {
        border: 0,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10, // 'Magic nubmber 10px' outlined in XD Document too
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: spacing * 5,
        paddingBottom: spacing * 5,
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: '0px !important',
        paddingBottom: '0px',
        paddingLeft: spacing * 10,
        paddingRight: spacing * 10,
        overflowY: 'hidden !important',
      },
    },
    MuiDialogActions: {
      root: {
        paddingTop: spacing * 5,
        paddingBottom: spacing * 5,
        paddingLeft: spacing * 10,
        paddingRight: spacing * 10,
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        '&::before': {
          content: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: '0px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      outlined: {
        padding: `${spacing * 2}px ${spacing * 3}px`,
        fontSize: 14,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '6px',
      },
    },
    MuiAlert: {
      standardSuccess: {
        backgroundColor: main,
        color: 'white',
      },
    },
    MuiChip: {
      root: {
        paddingLeft: spacing * 5,
        paddingRight: spacing * 5,
        backgroundColor: pink[50],
        color: pink[700],
      },
      clickable: {
        '&:hover': {
          backgroundColor: pink[100],
        },
      },
    },
  },
});
