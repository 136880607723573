import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { renderLureTextField } from '../../shared/formHelpers/fields';
import { required } from '../../shared/formHelpers/validation';

const CompanyFields = () => (
  <>
    <Grid item xs={12}>
      <Field
        fullWidth
        name={'user.displayName'}
        label={'Name *'}
        placeholder={'Your Name'}
        component={renderLureTextField}
        validate={required}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        readOnly
        name={'user.email'}
        label={'Email *'}
        placeholder={'Your email'}
        component={renderLureTextField}
        validate={required}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={'user.phone'}
        label={'Phone'}
        placeholder={'Your Phone'}
        component={renderLureTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name={'company.name'}
        label={'Company Name *'}
        placeholder={'Company Name'}
        component={renderLureTextField}
        validate={required}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={'company.email'}
        label={'Company Email *'}
        placeholder={'Company Email'}
        component={renderLureTextField}
        validate={required}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={'company.phone'}
        label={'Company Phone'}
        placeholder={'Company Phone'}
        component={renderLureTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name={'company.website'}
        label={'Company Website'}
        placeholder={'Company Website'}
        component={renderLureTextField}
      />
    </Grid>
  </>
);

export default CompanyFields;
