import React from 'react';
import {
  Route, useRouteMatch, Switch, Redirect, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  BOOKING_CALENDAR_ROUTE,
  BOOKING_DETAIL_ROUTE, BOOKING_REQUEST_ROUTE,
  CUSTOMER_DETAIL_ROUTE,
  CUSTOMERS_ROUTE,
  DASHBOARD_ROUTE, MARKETPLACE_ROUTE,
  SETTINGS_ROUTE,
  STAFF_DETAIL_ROUTE,
  STAFF_ROUTE,
  STORE_DETAIL_ROUTE,
  STORES_ROUTE,
  // CHAT_ROUTE,
} from '../../constants/routes';
import Dashboard from '../dashboard/Dashboard';
import BookingCalendar from '../bookings/calendar/BookingCalendar';
import Bookings from '../bookings/requests/Bookings';
import BookingDetail from '../bookings/BookingDetail/BookingDetail';
import Customers from '../customers/Customers';
import CustomerDetail from '../customers/CustomerDetail/CustomerDetail';
import Stores from '../stores/Stores';
import StoreDetail from '../stores/StoreDetail/StoreDetail';
import Staff from '../staff/Staff';
// import Chat from '../chat/Chat';
import StaffDetail from '../staff/StaffDetail/StaffDetail';
import Settings from '../settings/Settings';
import { COMPANY_ROLES, CUSTOMER_ROLES } from '../../constants/userRoles';
import CustomerDashboard from '../_customer/dashboard/CustomerDashboard';
import CustomerSettings from '../_customer/settings/Settings';
import CustomerBookings from '../_customer/bookings/CustomerBookings';
import Marketplace from '../_customer/marketplace/Marketplace';

function AppRoutes() {
  const { path } = useRouteMatch();
  const { user } = useSelector((state) => state.auth);
  const { state } = useLocation();

  const referrer = get(state, 'referrer', `${path}${DASHBOARD_ROUTE}`);

  return (
    <>
      <Switch>
        <Route path={`${path}${DASHBOARD_ROUTE}`}>
          {(COMPANY_ROLES.includes(user.role) ? <Dashboard /> : <CustomerDashboard />)}
        </Route>
        <Route path={`${path}/bookings`}>
          <Switch>
            {COMPANY_ROLES && <Route path={`${path}${BOOKING_REQUEST_ROUTE}`} exact component={Bookings} />}
            {COMPANY_ROLES && <Route path={`${path}${BOOKING_CALENDAR_ROUTE}`} exact component={BookingCalendar} />}
            <Route path={`${path}${BOOKING_DETAIL_ROUTE}`} component={BookingDetail} />
            <Route path={`${path}/bookings`}>
              {(COMPANY_ROLES.includes(user.role) ? <BookingCalendar /> : <CustomerBookings />)}
            </Route>
          </Switch>
        </Route>
        <Route path={`${path}${SETTINGS_ROUTE}`}>
          {(COMPANY_ROLES.includes(user.role) ? <Settings /> : <CustomerSettings />)}
        </Route>
        {COMPANY_ROLES.includes(user.role) && <Route path={`${path}${CUSTOMERS_ROUTE}`} exact component={Customers} />}
        {COMPANY_ROLES.includes(user.role) && <Route path={`${path}${CUSTOMER_DETAIL_ROUTE}`} component={CustomerDetail} />}
        {COMPANY_ROLES.includes(user.role) && <Route path={`${path}${STORES_ROUTE}`} exact component={Stores} />}
        {COMPANY_ROLES.includes(user.role) && <Route path={`${path}${STORE_DETAIL_ROUTE}`} component={StoreDetail} />}
        {COMPANY_ROLES.includes(user.role) && <Route path={`${path}${STAFF_ROUTE}`} exact component={Staff} />}
        {COMPANY_ROLES.includes(user.role) && <Route path={`${path}${STAFF_DETAIL_ROUTE}`} component={StaffDetail} />}
        {/* Disabled Chat */}
        {CUSTOMER_ROLES.includes(user.role) && <Route path={`${path}${MARKETPLACE_ROUTE}`} component={Marketplace} />}
        <Redirect from={'/app'} exact to={referrer} />
        <Redirect to={`${path}${DASHBOARD_ROUTE}`} />
      </Switch>
    </>
  );
}

export default AppRoutes;
