import { combineReducers } from 'redux';
import bookings from './booking';
import customer from './customer';
import staff from './staff';
import stores from './store';


const AdminPortal = combineReducers({
  bookings,
  customer,
  staff,
  store: stores,
});

export default AdminPortal;
