import {
  SET_BOOKING,
  SET_BOOKINGS,
  SET_CALENDAR_BOOKINGS,
  SET_CALENDAR_FILTERED_BOOKINGS,
  RESET_BOOKINGS,
} from '../../actions/AdminPortal/booking';

const initialState = {
  bookingList: [],
  activeBooking: {},
  calendarBookings: {
    list: [],
    filtered: [],
  },
};

const bookings = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKINGS:
      return {
        ...state,
        bookingList: action.payload,
      };

    case SET_BOOKING:
      return {
        ...state,
        activeBooking: action.payload,
      };

    case SET_CALENDAR_BOOKINGS:
      return {
        ...state,
        calendarBookings: {
          ...state.calendarBookings,
          list: action.payload,
        },
      };

    case SET_CALENDAR_FILTERED_BOOKINGS:
      return {
        ...state,
        calendarBookings: {
          ...state.calendarBookings,
          filtered: action.payload,
        },
      };

    case RESET_BOOKINGS:
      return {
        ...initialState,
      };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default bookings;
