import BookingService from '../../../services/BookingService';
import { snackSuccess } from '../snackbar';

export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_BOOKING = 'SET_BOOKING';
export const SET_CALENDAR_BOOKINGS = 'SET_CALENDAR_BOOKINGS';
export const SET_CALENDAR_FILTERED_BOOKINGS = 'SET_CALENDAR_FILTERED_BOOKINGS';
export const RESET_BOOKINGS = 'RESET_BOOKINGS';

export function getBookings() {
  return (dispatch) => {
    BookingService.getBookings()
      .then(({ data: { bookings: bookingData } }) => dispatch({
        type: SET_BOOKINGS,
        payload: bookingData,
      }));
  };
}

export function getBooking(_id) {
  return (dispatch) => {
    BookingService.getBooking(_id)
      .then(({ data: { booking } }) => {
        dispatch({
          type: SET_BOOKING,
          payload: booking,
        });
      });
  };
}

export function approveBooking(bookingId, timeslotId, staffId, cb) {
  return (dispatch) => BookingService.approveBooking(bookingId, timeslotId, staffId)
    .then(() => {
      dispatch(getBookings());
      dispatch(getBooking(bookingId));
      dispatch(snackSuccess('Booking Accepted'));
      if (cb) cb();
    });
}

export function updateBooking(_id, booking, cb) {
  return (dispatch) => BookingService.updateBooking(_id, booking)
    .then(({ data: { updateBooking: updatedBooking } }) => {
      dispatch(getBooking(updatedBooking._id));
      if (cb) cb();
      dispatch(snackSuccess('Booking Updated'));
    });
}

export function setBookingStatus(bookingId, bookingStatus, cb) {
  return (dispatch) => BookingService.setBookingStatus(bookingId, bookingStatus)
    .then(({ data: { setBookingStatus: updatedBooking } }) => {
      dispatch(getBooking(updatedBooking._id));
      if (cb) {
        cb();
      }
      dispatch(snackSuccess('Booking Reopened'));
    });
}

export function setCalendarBookings(bookings) {
  return (dispatch) => {
    dispatch({
      type: SET_CALENDAR_BOOKINGS,
      payload: bookings,
    });
  };
}

export function setCalendarFilteredBookings(bookings) {
  return (dispatch) => {
    dispatch({
      type: SET_CALENDAR_FILTERED_BOOKINGS,
      payload: bookings,
    });
  };
}
