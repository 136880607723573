import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import classNames from 'classnames';

const FieldValue = ({ value, classes }) => (
  <Typography className={classNames({ [classes.muted]: !value })}>
    { value || 'Not Provided' }
  </Typography>
);

const styles = (theme) => ({
  fieldKeys: {
    borderRight: `1px solid ${theme.colors.darkGrey}`,
    color: theme.colors.darkGrey,
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headingBlock: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
  },
  muted: {
    color: theme.colors.darkGrey,
  },
});

const CustomerDetails = ({ Booking }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid item container xs={6} direction={'column'} spacing={3}>
        <Grid item>
          <Typography variant={'h6'} className={classes.headingBlock}>Customer Details</Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item className={classes.fieldKeys}>
              <Typography align={'right'}>First Name</Typography>
              <Typography align={'right'}>Last Name</Typography>
              <Typography align={'right'}>Phone</Typography>
              <Typography align={'right'}>Email</Typography>
            </Grid>
            <Grid item xs>
              <FieldValue
                value={get(Booking, 'Customer.firstName')}
                classes={classes}
              />
              <FieldValue
                value={get(Booking, 'Customer.lastName')}
                classes={classes}
              />
              <FieldValue
                value={get(Booking, 'Customer.phone')}
                classes={classes}
              />
              <FieldValue
                value={get(Booking, 'Customer.email')}
                classes={classes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerDetails;
