import React, { useCallback } from 'react';
import { Edit2 } from 'react-feather';
import {
  LurePaper,
} from '@lureapps/lure-ui';
import {
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import TradingHoursList from './TradingHoursList';
import { openTradingHourModal } from '../../../store/actions/modals';


const styles = (theme) => ({
  headingSection: {
    marginBottom: theme.spacing(3),
  },
});

function TradingHours() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleTradingHoursEdit = useCallback(() => {
    dispatch(openTradingHourModal());
  }, [dispatch]);

  return (
    <>
      <LurePaper>
        <Grid container justify={'space-between'} alignItems={'center'} spacing={3} className={classes.headingSection}>
          <Grid item>
            <Typography variant={'h6'}>Trading Hours</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleTradingHoursEdit} color={'primary'}>
              <Edit2 size={16} />
            </IconButton>
          </Grid>
        </Grid>
        <TradingHoursList />
      </LurePaper>
    </>
  );
}

export default TradingHours;
