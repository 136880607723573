import React, { Component } from 'react';
import {
  Button, Grid, Typography, withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createStore as _createStore, updateStore as _updateStore } from '../../store/actions/AdminPortal/store';
import { RenderGooglePlacesAutocomplete, renderLureTextField } from '../shared/formHelpers/fields';
import { closeStoreModal as _closeStoreModal } from '../../store/actions/modals';
import { required } from '../shared/formHelpers/validation';
import { snackError as _snackError } from '../../store/actions/snackbar';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  leftBuffer: {
    paddingLeft: theme.spacing(5),
  },
  rightBuffer: {
    paddingRight: theme.spacing(5),
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 10,
    marginBottom: theme.spacing(1.2),
  },
});

class StoreModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  submit = (store) => {
    const {
      createStore,
      activeStore,
      updateStore,
      closeModal,
      snackError,
    } = this.props;
    this.setState({ loading: true });

    if (activeStore._id) {
      return updateStore(activeStore._id, store)
        .then(() => closeModal())
        .catch(() => snackError('Error while updating Store'))
        .finally(() => this.setState({ loading: false }));
    }
    return createStore(store)
      .then(() => closeModal())
      .catch(() => snackError('Error while creating Store'))
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const {
      open, classes, activeStore, closeModal,
    } = this.props;
    const editing = activeStore._id;
    const { loading } = this.state;
    return (
      <LureDialog
        open={open}
        maxWidth={'sm'}
        fullWidth
        disableBackdropClick
      >
        <LureDialogTitle>
          {editing ? 'Edit Store' : 'Add Store'}
        </LureDialogTitle>
        <Form
          onSubmit={this.submit}
          initialValues={activeStore}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <LureDialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Alert severity={'info'}>
                      <AlertTitle>Information</AlertTitle>
                      For most consistent results,
                      let the location finder pre-fill most of
                      your Store&#39;s details — check it out below!
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>Store Location Finder</Typography>
                    <Field
                      name={'address'}
                      placeholder={'Enter store location - e.g. Myer Brisbane'}
                      component={RenderGooglePlacesAutocomplete}
                      validate={required}
                      setMetaFields={(n, p) => {
                        form.change('name', n);
                        form.change('phone', p);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name={'name'}
                      label={'Store Name'}
                      placeholder={'Enter store name'}
                      component={renderLureTextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name={'phone'}
                      label={'Phone'}
                      placeholder={'Enter store phone number'}
                      component={renderLureTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name={'email'}
                      label={'Email'}
                      placeholder={'Enter store email'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
              </LureDialogContent>
              <LureDialogActions>
                <Grid container>
                  <Grid item xs={6} className={classes.rightBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'outlined'}
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.leftBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'contained'}
                      type={'submit'}
                    >
                      {loading ? <CircularProgress size={20} className={classes.loader} /> : 'Save Store'}
                    </Button>
                  </Grid>
                </Grid>
              </LureDialogActions>
            </form>
          )}
        />
      </LureDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    open: state.modals.storeModal.isOpen,
    activeStore: state.AdminPortal.store.activeStore,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeStoreModal()),
    createStore: (storeData) => dispatch(_createStore(storeData)),
    updateStore: (_id, storeData) => dispatch(_updateStore(_id, storeData)),
    snackError: (e) => dispatch(_snackError(e)),
  };
}

StoreModal = withStyles(styles)(StoreModal);
StoreModal = connect(mapStateToProps, mapDispatchToProps)(StoreModal);

export default StoreModal;
