import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { LurePaper } from '@lureapps/lure-ui';
import { useDispatch } from 'react-redux';
import { openProductTestModal } from '../../../store/actions/modals';

const styles = (theme) => ({
  gradient: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, 138%, ${theme.palette.secondary.main} 200%)`,
  },
});

const ProductDemo = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <>
      <LurePaper>
        <Grid container justify={'space-between'} alignItems={'center'} spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Test your Products</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              See how your website&#39;s products will appear on Lure
            </Typography>
          </Grid>
          <Grid item container xs={12} alignItems={'center'} justify={'center'}>
            <Button
              fullWidth
              variant={'contained'}
              color={'primary'}
              className={classes.gradient}
              onClick={() => dispatch(openProductTestModal())}
            >
              Test a Product
            </Button>
          </Grid>
        </Grid>
      </LurePaper>
    </>
  );
};

export default ProductDemo;
