import CustomerService from '../../../services/CustomerService';
import { snackError, snackSuccess } from '../snackbar';

export const LOADING_CUSTOMERS = 'LOADING_CUSTOMERS';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const LOADING_CUSTOMER = 'LOADING_CUSTOMER';
export const SET_ACTIVE_CUSTOMER = 'SET_ACTIVE_CUSTOMER';
export const CLEAR_ACTIVE_CUSTOMER = 'CLEAR_ACTIVE_CUSTOMER';
export const RESET_CUSTOMERS = 'RESET_CUSTOMERS';

export function getCustomers() {
  return function getCustomersAction(dispatch) {
    dispatch({ type: LOADING_CUSTOMERS });
    return CustomerService.getCustomers()
      .then((companyCustomers) => {
        dispatch({
          type: SET_CUSTOMERS,
          payload: companyCustomers,
        });
      });
  };
}

export function createCustomer(customer) {
  return function createCustomerAction(dispatch) {
    return CustomerService.createCustomer(customer)
      .then(() => {
        dispatch(snackSuccess('Customer Saved.'));
        dispatch(getCustomers());
      });
  };
}

export function deleteCustomer(_id) {
  return function deleteCustomerAction(dispatch) {
    return CustomerService.deleteCustomer(_id)
      .then(() => {
        dispatch(snackSuccess('Customer deleted.'));
        dispatch(getCustomers());
      })
      .catch((err) => {
        dispatch(snackError(err.message));
      });
  };
}

export function updateCustomer(_id, customer) {
  return function updateCustomerAction(dispatch) {
    return CustomerService.updateCustomer(_id, customer)
      .then((companyCustomer) => {
        dispatch(snackSuccess('Customer Saved.'));
        dispatch({
          type: SET_ACTIVE_CUSTOMER,
          payload: companyCustomer,
        });
        dispatch(getCustomers());
      });
  };
}

export function getCustomer(_id, callback) {
  return function getCustomersAction(dispatch) {
    dispatch({ type: LOADING_CUSTOMER });
    return CustomerService.getCustomer(_id)
      .then((companyCustomer) => {
        dispatch({
          type: SET_ACTIVE_CUSTOMER,
          payload: companyCustomer,
        });
        if (callback) {
          callback(companyCustomer);
        }
      });
  };
}

export function customerAddNote(_id, note) {
  return function customerAddNoteAction(dispatch) {
    return CustomerService.addNote(_id, note)
      .then((companyCustomer) => {
        dispatch(snackSuccess('Note Saved.'));
        dispatch({
          type: SET_ACTIVE_CUSTOMER,
          payload: companyCustomer,
        });
      });
  };
}

export function clearActiveCustomer() {
  return (dispatch) => dispatch({ type: CLEAR_ACTIVE_CUSTOMER });
}
