import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Grid, Typography, makeStyles, CircularProgress,
} from '@material-ui/core';
import {
  LureDialog,
  LureDialogActions,
  LureDialogContent,
  LureDialogTitle,
} from '@lureapps/lure-ui';
import { useQuery } from '@apollo/react-hooks';
import { closeConfirmModal, closeInstallationModal } from '../../store/actions/modals';
import InstallationGuideImage from '../../assets/images/installation-guide.svg';
import { GQL_COMPANY_SCRIPT } from '../../services/CompanyService';

const styles = (theme) => ({
  img: {
    maxWidth: theme.spacing(25),
  },
  pre: {
    backgroundColor: theme.colors.mediumGrey,
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    overflow: 'scroll',
    margin: 0,
  },
  inlinePre: {
    fontFamily: 'monospace',
    backgroundColor: theme.colors.lightGrey,
    padding: theme.spacing(0.3),
  },
  highlight: {
    color: theme.palette.primary.main,
  },
  overflowScroll: {
    overflowY: 'scroll !important',
  },
});

const LureScript = () => {
  const { loading, error, data } = useQuery(GQL_COMPANY_SCRIPT);

  if (loading) return <CircularProgress />;
  if (error) return 'Could not load your script at this time';

  const { me: { CurrentCompany } } = data;

  return CurrentCompany.LureScript;
};

const InstallationModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modals.installationModal);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <LureDialog
      open={isOpen}
      onClose={() => dispatch(closeConfirmModal())}
      maxWidth={'sm'}
      fullWidth
      disableBackdropClick
    >
      <LureDialogTitle>
        Installation Guide
      </LureDialogTitle>
      <LureDialogContent className={classes.overflowScroll}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container justify={'center'}>
              <img
                className={classes.img}
                src={InstallationGuideImage}
                alt={'installation guide'}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'} gutterBottom>
              1. Installation Script
            </Typography>
            <Typography variant={'body1'}>
              Ready to install Lure onto your site? Great! The setup is
              simple to follow and will have you driving customer engagement
              in no time. First things first, place the following line of code
              anywhere inside your website&#39;s&nbsp;
              <span className={classes.inlinePre}>{'<body>'}</span> tag.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <pre className={classes.pre}>
              <LureScript />
            </pre>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'} gutterBottom>
              2. Button anchor
            </Typography>
            <Typography variant={'body1'}>
              The next code snippet is placed wherever you wish Lure&#39;s
              &#34;Add Product&#34; button to be placed on your website.
              Simply paste the following code where you wish the button
              to render for your customers.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <pre className={classes.pre}>
              {'<div id="lure-product-btn-anchor" />'}
            </pre>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'} gutterBottom>
              3. Done!
            </Typography>
            <Typography variant={'body1'}>
              Congratulations! Lure has been successfully installed onto your website.
              If you require any support, please do not hesitate to contact us at&nbsp;
              <span className={classes.highlight}>dev@lureapp.io</span>
            </Typography>
          </Grid>
        </Grid>
      </LureDialogContent>
      <LureDialogActions>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={() => { dispatch(closeInstallationModal()); }}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </LureDialogActions>
    </LureDialog>
  );
};

export default InstallationModal;
