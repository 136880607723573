import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { LureTable } from '@lureapps/lure-ui';
import { openAddNoteModal as _openAddNoteModal } from '../../../store/actions/modals';
import { getCustomer as _getCustomer } from '../../../store/actions/AdminPortal/customer';

class Notes extends Component {
  columns = [{
    title: 'Date',
    render: (row) => moment(row.createdAt, 'x').format('Do MMM YYYY'),
  }, {
    title: 'Written By',
    field: 'CreatedBy.name',
  }, {
    title: 'Note',
    field: 'content',
  }];

  render() {
    const { Customer, openAddNoteModal } = this.props;
    return (
      <LureTable
        columns={this.columns}
        title={'Notes'}
        options={{ pageSize: 5, showTitle: true, toolbar: true }}
        data={Customer.Notes}
        action={(
          <Button variant={'contained'} color={'primary'} onClick={openAddNoteModal}>
            Add note
          </Button>
        )}
      />
    );
  }
}

const mapStateToProps = function mapStateToProps(state) {
  return {
    loading: state.AdminPortal.customer.activeLoading,
    Customer: state.AdminPortal.customer.active,
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getCustomer: (_id) => dispatch(_getCustomer(_id)),
    openAddNoteModal: () => dispatch(_openAddNoteModal()),
  };
};

Notes = connect(mapStateToProps, mapDispatchToProps)(Notes);

export default Notes;
