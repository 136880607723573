import React, { useCallback, useState } from 'react';
import {
  makeStyles,
  Button, Divider, Grid, Link as MuiLink, Typography,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { renderLureTextField } from '../shared/formHelpers/fields';
import { snackError } from '../../store/actions/snackbar';
import { FORGOT_PASSWORD_ROUTE, REGISTER_ROUTE } from '../../constants/routes';
import PreAuthFooter from './shared/PreAuthFooter';
import SignInWithGoogle from './shared/SignInWithGoogle';
import SignInWithFacebook from './shared/SignInWithFacebook';
import useFirebase, { firebaseApp } from '../../hooks/useFirebase';
import { setAuthUser } from '../../store/actions/auth';
import UserService from '../../services/UserService';
import { openUserRegistrationModal } from '../../store/actions/modals';

const styles = (theme) => ({
  containerPadding: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  signInWithButton: {
    marginTop: theme.spacing(1.5),
  },
  loader: {
    color: 'white',
  },
});

// eslint-disable-next-line import/no-mutable-exports
const Login = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { signInWithGoogle, signInWithFacebook, auth } = useFirebase();
  const [submitting, setSubmitting] = useState(false);

  const _checkForExistingUser = useCallback(async (user, provider) => {
    // Ensure there is a User associated with this login token/uid
    UserService.getUserByUID(get(user, 'uid', null))
      .then(async ({ data: { userByUID: u } }) => {
        // If there isn't a User with this login, display error and clean firebase
        if (!u) {
          dispatch(snackError(`No account found with provided ${provider} login`));
          await firebaseApp.auth().signOut();
          return;
        }
        // Otherwise, there is a User associated, fetch the user & log in.
        dispatch(setAuthUser());
      });
  }, []);

  const handleLogin = async ({ email, password }) => {
    setSubmitting(true);
    await auth.signInWithEmailAndPassword(email, password)
      .then(() => dispatch(setAuthUser((user) => {
        if (!user) {
          dispatch(openUserRegistrationModal());
        }
      })))
      .catch((err) => {
        let errMessage;
        switch (err.code) {
          case 'auth/wrong-password':
            errMessage = 'Incorrect username or password.';
            break;
          case 'auth/user-not-found':
            errMessage = 'User not found. Please check your email address '
              + 'and try again.';
            break;
          default:
            errMessage = 'Please try again. Please check your email address '
              + 'and try again.';
        }
        dispatch(snackError(`Authentication failed. ${errMessage}`));
      })
      .finally(() => setSubmitting(false));
  };

  const loginWithGoogle = useCallback(async () => {
    setSubmitting(true);
    return signInWithGoogle()
      .then(({ user }) => {
        _checkForExistingUser(user, 'Google');
      })
      .catch(({ message }) => dispatch(snackError(`Authentication failed. ${message}`)))
      .finally(() => setSubmitting(false));
  }, [dispatch, signInWithGoogle]);

  const loginWithFacebook = useCallback(async () => {
    setSubmitting(true);
    return signInWithFacebook()
      .then(({ user }) => {
        _checkForExistingUser(user, 'Facebook');
      })
      .catch(({ message }) => dispatch(snackError(`Authentication failed. ${message}`)))
      .finally(() => setSubmitting(false));
  }, [dispatch, signInWithFacebook]);

  return (
    <Form
      onSubmit={handleLogin}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete={'off'}
        >
          <Grid container direction={'column'}>
            <Grid item container className={classes.containerPadding}>
              <Grid item xs={12}>
                <Typography align={'center'} variant={'h4'} gutterBottom>Login</Typography>
              </Grid>
              <Grid container spacing={5} justify={'center'} className={classes.signInWithButton}>
                <Grid item xs={10} md={8} container direction={'column'} spacing={5}>
                  <Grid item>
                    <SignInWithFacebook
                      handleConnectWithFacebook={loginWithFacebook}
                    />
                  </Grid>
                  <Grid item>
                    <SignInWithGoogle
                      handleConnectWithGoogle={loginWithGoogle}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid container item xs={5} alignItems={'center'}>
                <Divider style={{ width: '100%' }} />
              </Grid>
              <Grid container item xs={2} justify={'center'}>
                <Typography>OR</Typography>
              </Grid>
              <Grid container item xs={5} alignItems={'center'}>
                <Divider style={{ width: '100%' }} />
              </Grid>
            </Grid>
            <Grid container className={classes.containerPadding} spacing={3}>
              <Grid container item spacing={3}>
                <Grid container item justify={'center'}>
                  <Grid item xs={10} md={8}>
                    <Field
                      fullWidth
                      label={'Email'}
                      name={'email'}
                      type={'text'}
                      placeholder={'Enter email'}
                      autoComplete={'email'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container item justify={'center'}>
                  <Grid item xs={10} md={8}>
                    <Field
                      fullWidth
                      label={'Password'}
                      name={'password'}
                      placeholder={'Enter password'}
                      type={'password'}
                      autoComplete={'current-password'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container item justify={'center'}>
                  <Grid item xs={10} md={8}>
                    <Button
                      fullWidth
                      variant={'contained'}
                      color={'primary'}
                      type={'submit'}
                    >
                      { submitting ? <CircularProgress size={20} className={classes.loader} /> : 'Sign in' }
                    </Button>
                  </Grid>
                </Grid>
                <Grid container justify={'center'}>
                  <Grid item xs={10} md={8}>
                    <Button fullWidth onClick={() => history.push(FORGOT_PASSWORD_ROUTE)}>
                      I forgot my password
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <PreAuthFooter>
              <Typography variant={'body1'}>
                Don&#39;t have an account?
                { ' ' }
                <MuiLink style={{ cursor: 'pointer' }} onClick={() => history.push(REGISTER_ROUTE)}>
                  Sign
                  Up
                </MuiLink>
              </Typography>
            </PreAuthFooter>
          </Grid>
        </form>
      )}
    />
  );
};

export default Login;
