import React, { useCallback } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { first, toUpper, words } from 'lodash';
import { LureIcon, LureNavListItem, LureNavListMultiItem } from '@lureapps/lure-ui';
import { makeStyles } from '@material-ui/core/styles';
import {
  BOOKING_CALENDAR_ROUTE,
  BOOKING_REQUEST_ROUTE,
  BOOKINGS_ROUTE,
  CUSTOMERS_ROUTE,
  DASHBOARD_ROUTE,
  SETTINGS_ROUTE,
  STAFF_ROUTE,
  STORES_ROUTE,
  // CHAT_ROUTE,
} from '../constants/routes';
import ToolbarLogo from '../assets/logos/full_onwhite.png';

import { logout as _logout } from '../store/actions/auth';

export const drawerWidth = 240;

export function initials(text) {
  return words(toUpper(text)).map(first);
}

export const useStyle = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  toolbarPadding: {
    padding: theme.spacing(2.5),
  },
  sideNavLogo: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${ToolbarLogo})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
  list: {
    marginTop: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function AdminNavDrawer() {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const logout = useCallback(async () => {
    dispatch(_logout());
  }, [dispatch]);
  return (
    <>
      <Drawer
        className={classes.drawer}
        variant={'permanent'}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classNames(classes.toolbar, classes.toolbarPadding)}>
          <div className={classes.sideNavLogo} />
        </div>
        <List className={classes.list}>
          <LureNavListItem
            title={'Dashboard'}
            icon={<LureIcon size={'small'} name={'grid'} />}
            active={pathname.includes(DASHBOARD_ROUTE)}
            onClick={() => history.push(`${path}${DASHBOARD_ROUTE}`)}
          />
          <LureNavListMultiItem
            title={'Bookings'}
            icon={<LureIcon size={'small'} name={'bookmark'} />}
            active={
              pathname.includes(BOOKING_CALENDAR_ROUTE)
              || pathname.includes(BOOKINGS_ROUTE)
              || pathname.includes(`${path}${BOOKINGS_ROUTE}`)
            }
            items={[{
              title: 'Calendar',
              onClick: () => history.push(`${path}${BOOKING_CALENDAR_ROUTE}`),
              active: pathname.includes(BOOKING_CALENDAR_ROUTE),
              route: BOOKING_CALENDAR_ROUTE,
            }, {
              title: 'Requests',
              onClick: () => history.push(`${path}${BOOKING_REQUEST_ROUTE}`),
              active: pathname.includes(BOOKING_REQUEST_ROUTE),
              route: BOOKING_REQUEST_ROUTE,
            }]}
          />
          <LureNavListItem
            title={'Customers'}
            icon={<LureIcon size={'small'} name={'shopping-bag'} />}
            active={pathname.includes(CUSTOMERS_ROUTE)}
            onClick={() => history.push(`${path}${CUSTOMERS_ROUTE}`)}
          />
          <LureNavListItem
            title={'Stores'}
            icon={<LureIcon size={'small'} name={'home'} />}
            active={pathname.includes(STORES_ROUTE)}
            onClick={() => history.push(`${path}${STORES_ROUTE}`)}
          />
          <LureNavListItem
            title={'Staff'}
            icon={<LureIcon size={'small'} name={'user'} />}
            active={pathname.includes(STAFF_ROUTE)}
            onClick={() => history.push(`${path}${STAFF_ROUTE}`)}
          />
          {/* <LureNavListItem
            title={'Chat'}
            icon={<LureIcon size={'small'} name={'message-circle'} />}
            active={pathname.includes(CHAT_ROUTE)}
            onClick={() => history.push(`${path}${CHAT_ROUTE}`)}
          /> */}
          <Divider className={classes.divider} />
          <LureNavListItem
            title={'Settings'}
            icon={<LureIcon size={'small'} name={'settings'} />}
            active={pathname.includes(SETTINGS_ROUTE)}
            onClick={() => history.push(`${path}${SETTINGS_ROUTE}`)}
          />
          <LureNavListItem
            title={'Logout'}
            icon={<LureIcon size={'small'} name={'log-out'} />}
            onClick={() => logout()}
          />
        </List>
      </Drawer>
    </>
  );
}

export default AdminNavDrawer;
