import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Button,
} from '@material-ui/core';
import { LurePaper } from '@lureapps/lure-ui';
import moment from 'moment-timezone';
import { get, orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Add as PlusIcon } from '@material-ui/icons';
import classNames from 'classnames';
import CustomersEmptyState from '../../assets/images/empty-customers.svg';
import { openCustomerModal } from '../../store/actions/modals';
import { clearActiveCustomer } from '../../store/actions/AdminPortal/customer';
import CustomerAvatar from '../shared/CustomerAvatar';

const styles = (theme) => ({
  timeText: {
    color: theme.colors.darkGrey,
    textTransform: 'uppercase',
  },
  customerLink: {
    cursor: 'pointer',
  },
  emptyStateContainer: {
    padding: theme.spacing(3),
  },
  emptyState: {
    maxWidth: theme.spacing(20),
    marginTop: theme.spacing(4),
  },
  gradient: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, 138%, ${theme.palette.secondary.main} 200%)`,
  },
  mute: {
    color: theme.colors.darkGrey,
  },
  bufferRight: {
    marginRight: theme.spacing(),
  },
  bufferBottom: {
    marginBottom: theme.spacing(2),
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  hidden: {
    display: 'none',
  },
});

const EmptyState = ({ classes }) => {
  const dispatch = useDispatch();
  return (
    <Grid
      container
      alignItems={'center'}
      justify={'center'}
      direction={'column'}
      className={classes.emptyStateContainer}
      spacing={3}
    >
      <Grid item>
        <img className={classes.emptyState} src={CustomersEmptyState} alt={'empty'} />
      </Grid>
      <Grid item>
        <Typography variant={'body1'} className={classes.mute}>No new Customers this week</Typography>
      </Grid>
      <Grid item>
        <Button
          variant={'contained'}
          color={'primary'}
          className={classes.gradient}
          onClick={() => dispatch(openCustomerModal())}
        >
          <PlusIcon className={classes.bufferRight} fontSize={'small'} />
          Add Customers
        </Button>
      </Grid>
    </Grid>
  );
};

const RecentCustomers = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [recentCustomers, setRecentCustomers] = useState([]);
  const history = useHistory();
  const Customers = useSelector((state) => state.AdminPortal.customer.list);
  const dispatch = useDispatch();

  // Gets last 3 signed up Customers
  useEffect(() => {
    if (!Customers) return;
    const thisWeek = moment().startOf('week');
    const recent = [];
    Customers.forEach((customer) => {
      if (recent.length >= 3) return;
      const customerSignedUp = moment(customer.createdAt, 'x');
      if (customerSignedUp.isAfter(thisWeek)) {
        recent.push(customer);
      }
    });
    setRecentCustomers(orderBy(recent, ['createdAt'], 'desc'));
  }, [Customers]);

  return (
    <>
      <LurePaper style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={7} lg={7}>
              <Typography variant={'h6'}>
                Recent Sign-ups
              </Typography>
              <Typography variant={'body1'} className={classes.timeText}>
                This week
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Button
                fullWidth
                variant={'contained'}
                color={'primary'}
                className={classNames(
                  classes.gradient,
                  classes.bufferBottom,
                  classes.nowrap,
                  { [classes.hidden]: !recentCustomers.length },
                )}
                onClick={() => {
                  dispatch(clearActiveCustomer());
                  dispatch(openCustomerModal());
                }}
              >
                <PlusIcon className={classes.bufferRight} fontSize={'small'} />
                Add Customers
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {
              !recentCustomers.length && <EmptyState classes={classes} />
            }
            {
              recentCustomers.map((customer) => (
                <List key={get(customer, '_id')}>
                  <ListItem
                    className={classes.customerLink}
                    onClick={() => history.push(`/app/customers/${customer._id}`)}
                  >
                    <ListItemAvatar>
                      <CustomerAvatar customer={customer} className={classes.gradient} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={get(customer, 'fullName')}
                      secondary={`Signed up ${moment(get(customer, 'createdAt'), 'x').fromNow()}`}
                    />
                  </ListItem>
                </List>
              ))
            }
          </Grid>
        </Grid>
      </LurePaper>
    </>
  );
};

export default RecentCustomers;
