import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

const FieldValue = ({ value, classes }) => (
  <Typography className={classNames({ [classes.muted]: !value })}>
    { value || 'Not Set' }
  </Typography>
);

const styles = (theme) => ({
  fieldKeys: {
    borderRight: `1px solid ${theme.colors.darkGrey}`,
    color: theme.colors.darkGrey,
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight: {
    color: theme.palette.primary.main,
  },
  headingBlock: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
  },
  muted: {
    color: theme.colors.darkGrey,
  },
});

const StoreDetails = ({ Booking }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid item container xs={6} direction={'column'} spacing={3}>
        <Grid item>
          <Typography variant={'h6'} className={classes.headingBlock}>Store Details</Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item className={classes.fieldKeys}>
              <Typography align={'right'}>Name</Typography>
              <Typography align={'right'}>Phone</Typography>
              <Typography align={'right'}>Email</Typography>
              <Typography align={'right'}>Date & Time</Typography>
            </Grid>
            <Grid item xs>
              <FieldValue
                value={get(Booking, 'Store.name')}
                classes={classes}
              />
              <FieldValue
                value={get(Booking, 'Store.phone')}
                classes={classes}
              />
              <FieldValue
                value={get(Booking, 'Store.email')}
                classes={classes}
              />
              <FieldValue
                value={moment(get(Booking, 'timeslots[0].timeslot', ''), 'x')
                  .format('Do MMM YYYY, h:mma')}
                classes={classes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StoreDetails;
