import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider, ListItem, ListItemIcon, ListItemText, Menu, makeStyles,
} from '@material-ui/core';
import {
  Check, CheckCircle, RotateCcw, X, XCircle,
} from 'react-feather';
import { get } from 'lodash';
import {
  REQUESTED, ACCEPTED, REJECTED, CANCELLED, COMPLETED,
} from '../../../constants/bookingStatuses';
import {
  closeConfirmModal,
  openAcceptBookingModal, openBookingCompleteModal,
  openConfirmModal,
  openRejectRequestModal,
} from '../../../store/actions/modals';
import { setBookingStatus, updateBooking } from '../../../store/actions/AdminPortal/booking';

const styles = (theme) => ({
  primaryColor: {
    color: theme.palette.primary.main,
  },
  hidden: {
    display: 'none',
  },
});

const BookingActionMenu = ({ anchorEl, setAnchorEl }) => {
  const dispatch = useDispatch();
  const Booking = useSelector((state) => state.AdminPortal.bookings.activeBooking);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const bookingStatus = get(Booking, 'status', '');

  return (
    <Menu
      id={'action-menu'}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <ListItem
        button
        className={(bookingStatus === REQUESTED || bookingStatus === REJECTED) ? '' : classes.hidden}
        onClick={() => {
          setAnchorEl(null);
          dispatch(openAcceptBookingModal(Booking));
        }}
      >
        <ListItemIcon className={classes.primaryColor}>
          <Check size={16} />
        </ListItemIcon>
        <ListItemText
          primary={'Accept Booking Time'}
          secondary={'Accept this Booking time and allocate a Staff Member'}
        />
      </ListItem>
      <ListItem
        button
        className={bookingStatus === ACCEPTED ? '' : classes.hidden}
        onClick={() => {
          setAnchorEl(null);
          dispatch(openBookingCompleteModal(Booking));
        }}
      >
        <ListItemIcon className={classes.primaryColor}>
          <CheckCircle size={16} />
        </ListItemIcon>
        <ListItemText
          primary={'Complete Booking'}
          secondary={'Mark this Booking as having been successfully Completed'}
        />
      </ListItem>
      <ListItem
        button
        className={bookingStatus === REQUESTED ? '' : classes.hidden}
        onClick={() => {
          setAnchorEl(null);
          dispatch(openRejectRequestModal());
        }}
      >
        <ListItemIcon className={classes.primaryColor}>
          <X size={16} />
        </ListItemIcon>
        <ListItemText
          primary={'Reject Booking Time'}
          secondary={'Reject Booking time and alert Customer'}
        />
      </ListItem>
      <ListItem
        button
        className={(bookingStatus === COMPLETED || bookingStatus === CANCELLED) ? '' : classes.hidden}
        onClick={() => {
          setAnchorEl(null);
          dispatch(openConfirmModal({
            title: 'Reopen Booking',
            confirmationText: 'Are you sure you wish to reopen this booking?',
            rejectButtonText: 'Cancel',
            onReject: () => dispatch(closeConfirmModal()),
            acceptButtonText: 'Reopen Booking',
            onAccept: () => {
              dispatch(setBookingStatus(Booking._id, ACCEPTED));
              dispatch(closeConfirmModal());
            },
            alert: {
              title: 'Info',
              severity: 'info',
              content: 'Reopening a booking request will reset it\'s status back to Accepted.',
            },
          }));
        }}
      >
        <ListItemIcon className={classes.primaryColor}>
          <RotateCcw size={16} />
        </ListItemIcon>
        <ListItemText
          primary={'Reopen Booking'}
          secondary={'Reopening a booking will reset the status back to Requested.'}
        />
      </ListItem>
      <Divider className={(bookingStatus === COMPLETED || bookingStatus === CANCELLED) ? classes.hidden : ''} />
      <ListItem
        className={bookingStatus !== CANCELLED ? '' : classes.hidden}
        button
        onClick={() => {
          setAnchorEl(null);
          dispatch(openConfirmModal({
            title: 'Cancel Booking',
            confirmationText: 'Are you sure you wish to cancel this booking?',
            rejectButtonText: 'Back',
            onReject: () => dispatch(closeConfirmModal()),
            acceptButtonText: 'Cancel Booking',
            onAccept: () => {
              dispatch(updateBooking(Booking._id, { status: CANCELLED }));
              dispatch(closeConfirmModal());
            },
            alert: {
              title: 'Warning',
              severity: 'warning',
              content: 'Be careful - this action will cancel the booking and notify the customer',
            },
          }));
        }}
      >
        <ListItemIcon className={classes.primaryColor}>
          <XCircle size={16} />
        </ListItemIcon>
        <ListItemText
          primary={'Cancel Booking'}
          secondary={'Cancel Booking and notify Customer'}
        />
      </ListItem>
    </Menu>
  );
};

export default BookingActionMenu;
