import React, { useCallback } from 'react';
import {
  Button, Divider, Grid, MenuItem, Select, Typography, makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ActiveFilterOptions from './ActiveFilterOptions';
import { setCalendarFilteredBookings } from '../../../store/actions/AdminPortal/booking';

const styles = (theme) => ({
  padding: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(5)}px`,
  },
  bottomBufferSm: {
    marginBottom: theme.spacing(1),
  },
  bottomBufferLg: {
    marginBottom: theme.spacing(4),
  },
});

function Filter({ updateBookings }) {
  const [status, setStatus] = React.useState('');
  const dispatch = useDispatch();
  const Bookings = useSelector((state) => state.AdminPortal.bookings.calendarBookings.list);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const handleSelectChange = (e) => {
    setStatus(e.target.value);
  };

  const clearFilter = useCallback(() => {
    dispatch(setCalendarFilteredBookings(Bookings));
  }, [dispatch, Bookings]);

  return (
    <Grid container>
      <Grid item className={classes.padding} xs={12}>
        <Typography variant={'h6'}>
          Filter
        </Typography>
      </Grid>
      <Divider variant={'fullWidth'} style={{ width: '100%' }} />
      <Grid item className={classes.padding} xs={12}>
        <Typography variant={'body2'} className={classes.bottomBufferSm}>Filter by</Typography>
        <Select
          className={classes.bottomBufferLg}
          value={status}
          onChange={handleSelectChange}
          inputProps={{
            name: 'filter',
          }}
          displayEmpty
          name={'eventFilter'}
          variant={'outlined'}
          fullWidth
        >
          <MenuItem value={''}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={'status'}>Status</MenuItem>
          <MenuItem value={'store'}>Store</MenuItem>
          <MenuItem value={'staff_member'}>Staff Member</MenuItem>
        </Select>
        <ActiveFilterOptions
          activeFilterOption={status}
          Bookings={Bookings}
          updateBookings={updateBookings}
        />
      </Grid>
      <Divider variant={'fullWidth'} style={{ width: '100%' }} />
      <Grid item className={classes.padding} xs={12}>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              color={'primary'}
              variant={'outlined'}
              onClick={clearFilter}
            >
              Clear Filter
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Filter;
