import React, { Component } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { closeStaffModal as _closeStaffModal } from '../../store/actions/modals';
import { renderLureTextField, RenderStoreAutosuggest } from '../shared/formHelpers/fields';
import {
  createStaffMember as _createStaffMember,
  updateStaffMember as _updateStaffMember,
} from '../../store/actions/AdminPortal/staff';
import { snackError as _snackError } from '../../store/actions/snackbar';
import { required } from '../shared/formHelpers/validation';
import CustomerService from "../../services/CustomerService";
import StaffService from "../../services/StaffService";

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  leftBuffer: {
    paddingLeft: theme.spacing(5),
  },
  rightBuffer: {
    paddingRight: theme.spacing(5),
  },
  loader: {
    color: 'white',
  },
});

class StaffModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
    };
  }

  submit = async (values) => {
    const {
      createStaffMember,
      updateStaffMember,
      activeStaffMember,
      snackError,
      closeModal,
    } = this.props;
    this.setState({ loading: true });

    if (values.email) {
      const { data: { staffMemberExists } } = await StaffService
        .staffMemberExists(values.email);
      if (staffMemberExists && (values.email !== activeStaffMember.email)) {
        this.setState({ error: 'A Staff Member with that email already exists', loading: false });
        return;
      }
    }

    if (activeStaffMember._id) {
      updateStaffMember(activeStaffMember._id, values)
        .then(() => closeModal())
        .catch(() => snackError('Error while updating staff member.'))
        .finally(() => this.setState({ loading: false, error: '' }));
      return;
    }

    createStaffMember(values)
      .then(() => closeModal())
      .catch(() => snackError('Error while creating staff member.'))
      .finally(() => this.setState({ loading: false, error: '' }));
  };

  render() {
    const {
      open, classes, activeStaffMember, closeModal,
    } = this.props;
    const { loading, error } = this.state;
    const editing = activeStaffMember._id;
    return (
      <LureDialog
        open={open}
        maxWidth={'sm'}
        fullWidth
        disableBackdropClick
      >
        <LureDialogTitle>
          {editing ? 'Edit Staff Member' : 'Create Staff Member'}
        </LureDialogTitle>
        <Form
          onSubmit={this.submit}
          initialValues={activeStaffMember}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <LureDialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name={'firstName'}
                      label={'First Name'}
                      placeholder={'Enter your first name'}
                      component={renderLureTextField}
                      validate={required}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name={'lastName'}
                      label={'Last Name'}
                      placeholder={'Enter your last name'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name={'email'}
                      label={'Email'}
                      placeholder={'Enter email'}
                      component={renderLureTextField}
                      validate={required}
                    />
                    {
                      error && <Typography color={'error'} variant={'body2'}>{error}</Typography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name={'phone'}
                      label={'Phone'}
                      placeholder={'Enter phone'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      component={RenderStoreAutosuggest}
                      name={'primaryStore'}
                      label={'Primary Store'}
                      size={'small'}
                    />
                  </Grid>
                </Grid>
              </LureDialogContent>
              <LureDialogActions>
                <Grid container>
                  <Grid item xs={6} className={classes.rightBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'outlined'}
                      onClick={() => {
                        this.setState({ error: '' });
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.leftBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'contained'}
                      type={'submit'}
                    >
                      {loading
                        ? <CircularProgress size={20} className={classes.loader} /> : 'Save Staff Member'}
                    </Button>
                  </Grid>
                </Grid>
              </LureDialogActions>
            </form>
          )}
        />
      </LureDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    open: state.modals.staffModal.isOpen,
    activeStaffMember: state.AdminPortal.staff.activeStaffMember,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeStaffModal()),
    createStaffMember: (staffMember) => dispatch(_createStaffMember(staffMember)),
    updateStaffMember: (_id, staffMember) => dispatch(_updateStaffMember(_id, staffMember)),
    snackError: (e) => dispatch(_snackError(e)),
  };
}

StaffModal = withStyles(styles)(StaffModal);
StaffModal = connect(mapStateToProps, mapDispatchToProps)(StaffModal);

export default StaffModal;
