import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { renderLureTextField } from '../../shared/formHelpers/fields';

const NewCustomerForm = () => (
  <>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          label={'First Name'}
          name={'newCustomer.firstName'}
          component={renderLureTextField}
          variant={'outlined'}
          placeholder={'First name'}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          label={'Last Name'}
          name={'newCustomer.lastName'}
          component={renderLureTextField}
          variant={'outlined'}
          placeholder={'Last name'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Field
          fullWidth
          label={'Email'}
          name={'newCustomer.email'}
          component={renderLureTextField}
          variant={'outlined'}
          placeholder={'Email'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Field
          fullWidth
          label={'Phone'}
          name={'newCustomer.phone'}
          component={renderLureTextField}
          variant={'outlined'}
          placeholder={'Phone'}
        />
      </Grid>
    </Grid>
  </>
);


export default NewCustomerForm;
