import React, { useCallback } from 'react';
import {
  Icon, IconButton, Snackbar, SnackbarContent,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../store/actions/snackbar';

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

const styles = (theme) => ({
  success: {
    backgroundColor: theme.colors.green,
  },
  error: {
    backgroundColor: theme.colors.red,
  },
  info: {
    backgroundColor: theme.colors.blue,
  },
  warning: {
    backgroundColor: theme.colors.orange,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function SnackbarLauncher() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();

  const snackOptions = useSelector((state) => state.snackbar.snackOptions);
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);

  const { snackMessage, variant } = snackOptions;

  const handleClose = useCallback(() => {
    const closeOptions = {
      snackMessage: '',
    };
    dispatch(snackbarToggle(closeOptions));
  }, [dispatch]);

  return (
    <>
      {snackMessage.length > 0 && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={6000}
          open={snackbarOpen}
          onClose={handleClose}
        >
          <SnackbarContent
            className={classes[variant]}
            message={(
              <span className={classes.message}>
                <Icon className={classes.iconVariant}>{variantIcon[variant]}</Icon>
                { snackMessage}
              </span>
            )}
            action={[
              <IconButton key={'close'} color={'inherit'} onClick={handleClose}>
                <Icon>close</Icon>
              </IconButton>,
            ]}
          />
        </Snackbar>
      )}
    </>
  );
}

export default SnackbarLauncher;
