import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { get } from 'lodash';

import { Field, Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Edit2 } from 'react-feather';
import { StoreStaffAutocomplete } from '../../shared/formHelpers/fields';
import { updateBooking } from '../../../store/actions/AdminPortal/booking';
import { CUSTOMER } from '../../../constants/userRoles';
import AllocatedStaffMemberEmptyState from '../../../assets/images/empty-staff-allocation.svg';

const styles = (theme) => ({
  heading: {
    textTransform: 'uppercase',
    color: theme.colors.darkGrey,
  },
  rightBuffer: {
    marginRight: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  emptyState: {
    maxHeight: theme.spacing(17),
  },
  emptyStateText: {
    color: theme.colors.darkGrey,
  },
});

function ButtonGroup({ setEditing, classes }) {
  return (
    <>
      <Button
        variant={'outlined'}
        color={'primary'}
        size={'small'}
        onClick={() => setEditing(false)}
        className={classes.rightBuffer}
      >
        Close
      </Button>
      <Button
        variant={'contained'}
        color={'primary'}
        type={'submit'}
        size={'small'}
      >
        Save
      </Button>
    </>
  );
}

const StaffPanel = ({ StaffMember, Booking }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  const userRole = useSelector((state) => state.auth.user.role);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const submit = useCallback(({ staff }, form) => {
    dispatch(updateBooking(Booking._id, {
      staffId: staff._id,
    }, () => {
      setEditing(false);
      form.reset();
    }));
  }, [dispatch, Booking]);

  return (
    <>
      <Form
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} direction={'column'}>
              <Grid item container justify={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Typography variant={'body2'} className={classes.heading} gutterBottom>
                    Allocated Staff Member
                  </Typography>
                  <Typography variant={'h5'}>
                    { get(StaffMember, 'fullName', '') }
                  </Typography>
                </Grid>
                <Grid item>
                  {
                      editing ? <ButtonGroup setEditing={setEditing} classes={classes} /> : (
                        <IconButton
                          className={
                            classNames({
                              [classes.hidden]: userRole === CUSTOMER || !StaffMember,
                            })
                          }
                          onClick={() => setEditing(true)}
                          color={'primary'}
                        >
                          <Edit2 size={16} />
                        </IconButton>
                      )
                    }
                </Grid>
              </Grid>
              {
                  editing ? (
                    <Grid item>
                      <Field
                        name={'staff'}
                        Staff={get(Booking, 'Store.Staff', [])}
                        component={StoreStaffAutocomplete}
                      />
                      <Typography variant={'caption'} className={classes.emptyStateText}>
                        Can&#39;t find a Staff Member? Make sure their Primary Store is set!
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Typography variant={'body1'}>
                        { get(StaffMember, 'email', '') }
                      </Typography>
                      <Typography variant={'body1'}>
                        { get(StaffMember, 'phone', '') }
                      </Typography>
                    </Grid>
                  )
                }
            </Grid>
          </form>
        )}
      />
      {
          !StaffMember && !editing
          && (
            <Grid container direction={'column'} alignItems={'center'} spacing={2}>
              <Grid item xs={12}>
                <img
                  alt={'Staff Member Empty State'}
                  className={classes.emptyState}
                  src={AllocatedStaffMemberEmptyState}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={'subtitle'}
                  className={classes.emptyStateText}
                >
                  No Staff Member allocated yet
                </Typography>
              </Grid>
              <Grid item xs={12} className={userRole === CUSTOMER ? classes.hidden : ''}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  size={'medium'}
                  onClick={() => setEditing(true)}
                >
                  Allocate Staff now
                </Button>
              </Grid>
            </Grid>
          )
        }
    </>
  );
};

export default StaffPanel;
