import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const middlewares = [thunk];

const logger = createLogger({
  predicate: (getState, action) => !action.type.includes('@@redux-form'),
});

if (process.env.NODE_ENV === 'development') middlewares.push(logger);

const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares),
);

export default store;
