import { gql } from 'apollo-boost';
import gqlClient from './GraphQLService';

export const GQL_UPSERT_PRODUCT = gql`
  mutation upsertProductByURL($url: String!, $companyUID: ID!) {
    upsertByUrl(url:$url, companyUID: $companyUID) {
      _id
      title
      description
      price
      formattedPrice
      formattedName
      image
      url
      variant {
        title
      }
      meta {
        key
        value
      }
    }
  }
`;

export function upsertProduct(url, companyUID) {
  return gqlClient.mutate({
    mutation: GQL_UPSERT_PRODUCT,
    variables: {
      url,
      companyUID,
    },
  });
}
