import React, { useEffect, useState } from 'react';
import { Button, Grid, MuiThemeProvider } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { LureBookingsMenu, LurePageHeading, LurePaper } from '@lureapps/lure-ui';

import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import StoreInformation from './StoreInformation';
import { getStaffMember } from '../../../store/actions/AdminPortal/staff';
import Information from './Information';
import black from '../../../theme/black';

import BookingService from '../../../services/BookingService';

const StaffDetail = () => {
  const { staffId } = useParams();
  const dispatch = useDispatch();
  const StaffMember = useSelector((state) => state.AdminPortal.staff.activeStaffMember);
  const history = useHistory();
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [historicBookings, setHistoricBookings] = useState([]);

  useEffect(() => {
    dispatch(getStaffMember(staffId));
    BookingService.getBookingTimeline(staffId, 'StaffMember')
      .then(({
        data: {
          bookingTimeline: {
            upcomingBookings: upcoming,
            historicBookings: historic,
          },
        },
      }) => {
        setUpcomingBookings(upcoming);
        setHistoricBookings(historic);
      });
  }, [dispatch, staffId]);

  return (
    <>
      <LurePageHeading
        title={'Staff'}
        action={(
          <MuiThemeProvider theme={black}>
            <Button variant={'outlined'} onClick={() => history.goBack()}>Go back</Button>
          </MuiThemeProvider>
        )}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4} xl={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LurePaper shadow>
                <Information />
              </LurePaper>
            </Grid>
            <Grid item xs={12}>
              <LurePaper>
                <LureBookingsMenu
                  upcomingBookings={upcomingBookings}
                  historicBookings={historicBookings}
                  onBookingClick={(booking) => history.push(`/app/bookings/requests/${booking._id}`)}
                  entity={'StaffMember'}
                />
              </LurePaper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <LurePaper>
            <StoreInformation Store={get(StaffMember, 'primaryStore', {})} />
          </LurePaper>
        </Grid>
      </Grid>
    </>
  );
};

export default StaffDetail;
