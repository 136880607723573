import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import snackbar from './snackbar';
import modals from './modals';
import CustomerPortal from './CustomerPortal';
import AdminPortal from './AdminPortal';

export default combineReducers({
  form: formReducer,
  auth,
  snackbar,
  modals,
  AdminPortal,
  CustomerPortal,
});
