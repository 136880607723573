import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button, Divider, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as firebase from 'firebase';
import { renderLureTextField } from '../../shared/formHelpers/fields';
import SignInWithFacebook from '../shared/SignInWithFacebook';
import SignInWithGoogle from '../shared/SignInWithGoogle';
import useFirebase from '../../../hooks/useFirebase';
import { snackError } from '../../../store/actions/snackbar';
import UserService from '../../../services/UserService';
import { EMAIL_EXISTS } from '../../../constants/firebaseErrorCodes';
import { setAuthUser } from '../../../store/actions/auth';

const styles = (theme) => ({
  inputContainer: {
    marginBottom: theme.spacing(1.5),
  },
  signInWithButton: {
    marginTop: theme.spacing(1.5),
  },
  loader: {
    color: 'white',
  },
  containerPadding: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
});

const AuthSelection = ({ setRegistrationStep, formValues, role }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { signInWithGoogle, signInWithFacebook } = useFirebase();
  const [submitting, setSubmitting] = useState(false);

  const handleRegisterUser = async (user) => {
    UserService.registerUser(user, role)
      .then(() => {
        dispatch(setAuthUser());
      })
      .catch((err) => {
        dispatch(snackError(err));
      });
  };

  const handleConnectWithGoogle = useCallback(async () => {
    setSubmitting(true);
    return signInWithGoogle()
      .then(({ user }) => handleRegisterUser(user))
      .catch(({ message }) => dispatch(snackError(`Authentication failed. ${message}`)))
      .finally(() => setSubmitting(false));
  }, [dispatch, signInWithGoogle]);

  const handleConnectWithFacebook = useCallback(async () => {
    setSubmitting(true);
    return signInWithFacebook()
      .then(({ user }) => {
        handleRegisterUser(user);
      })
      .catch(({ message }) => dispatch(snackError(`Authentication failed. ${message}`)))
      .finally(() => setSubmitting(false));
  }, [dispatch, signInWithFacebook]);

  const handleConnectWithEmailAndPassword = useCallback(async () => {
    const { email, password, ...values } = formValues;
    try {
      setSubmitting(true);
      const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
      await user.updateProfile({
        displayName: values.name,
      });
      await handleRegisterUser({ ...user, ...values });
    } catch (e) {
      if (e.code === EMAIL_EXISTS) {
        try {
          const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);
          await handleRegisterUser(user);
        } catch (err) {
          dispatch(snackError(`Error signing up. ${err.message}`));
        }
        return;
      }
      dispatch(snackError(`Error signing up. ${e.message}`));
    } finally {
      setSubmitting(false);
    }
  }, [dispatch, formValues]);

  return (
    <>
      <Grid container direction={'column'}>
        <Grid item container className={classes.containerPadding}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'h4'} gutterBottom>Register</Typography>
          </Grid>
          <Grid container spacing={5} justify={'center'} className={classes.signInWithButton}>
            <Grid item xs={8} container direction={'column'} spacing={5}>
              <Grid item>
                <SignInWithFacebook
                  handleConnectWithFacebook={handleConnectWithFacebook}
                />
              </Grid>
              <Grid item>
                <SignInWithGoogle
                  handleConnectWithGoogle={handleConnectWithGoogle}
                  buttonText={'Sign up with Google'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item xs={5} alignItems={'center'}>
            <Divider style={{ width: '100%' }} />
          </Grid>
          <Grid container item xs={2} justify={'center'}>
            <Typography>OR</Typography>
          </Grid>
          <Grid container item xs={5} alignItems={'center'}>
            <Divider style={{ width: '100%' }} />
          </Grid>
        </Grid>
        <Grid item container className={classes.containerPadding}>
          <Grid container spacing={3} justify={'center'}>
            <Grid item xs={8}>
              <Field
                fullWidth
                name={'name'}
                label={'Your name'}
                type={'text'}
                placeholder={'Enter your full name'}
                className={classes.inputContainer}
                component={renderLureTextField}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                fullWidth
                name={'email'}
                label={'Your email'}
                type={'email'}
                placeholder={'Enter your email'}
                className={classes.inputContainer}
                component={renderLureTextField}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                fullWidth
                name={'phone'}
                label={'Your phone'}
                type={'phone'}
                placeholder={'Enter your phone number'}
                className={classes.inputContainer}
                component={renderLureTextField}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                fullWidth
                name={'password'}
                label={'Password'}
                type={'password'}
                placeholder={'Enter a password'}
                className={classes.inputContainer}
                component={renderLureTextField}
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                fullWidth
                variant={'contained'}
                color={'primary'}
                onClick={handleConnectWithEmailAndPassword}
              >
                {submitting ? <CircularProgress size={20} className={classes.loader} /> : 'Register'}
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Button
                fullWidth
                variant={'outlined'}
                onClick={() => setRegistrationStep(0)}
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthSelection;
