const initialState = {
  companies: [],
};

const marketplace = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export default marketplace;
