export const CUSTOMER = 'CUSTOMER';
export const NO_ROLE = 'NO_ROLE';
export const ADMIN = 'ADMIN';
export const COMPANY_MANAGER = 'COMPANY_MANAGER';
export const COMPANY_STAFF = 'COMPANY_STAFF';

export const COMPANY_ROLES = [
  NO_ROLE,
  ADMIN,
  COMPANY_MANAGER,
  COMPANY_STAFF,
];

export const CUSTOMER_ROLES = [
  CUSTOMER,
];
