import React from 'react';
import { Avatar } from '@material-ui/core';
import { get } from 'lodash';

const CustomerAvatar = ({ customer, className }) => {
  let initials = '';
  const firstName = get(customer, 'firstName');
  const lastName = get(customer, 'lastName');

  if (firstName) initials += firstName[0];
  if (lastName) initials += lastName[0];
  return (
    <>
      <Avatar className={className}>
        {initials}
      </Avatar>
    </>
  );
};

export default CustomerAvatar;
