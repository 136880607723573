import React from 'react';
import { LureChip, LureTable, LureTheme } from '@lureapps/lure-ui';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { openStaffModal } from '../../../store/actions/modals';
import { SET_ACTIVE_STAFF_MEMBER } from '../../../store/actions/AdminPortal/staff';
import StaffEmptyState from '../../../assets/images/empty-customers.svg';

const styles = (theme) => ({
  emptyStateContainer: {
    padding: theme.spacing(3),
  },
  emptyState: {
    maxWidth: theme.spacing(20),
    marginTop: theme.spacing(4),
  },
});

function EmptyState({ classes }) {
  return (
    <Grid container direction={'column'} spacing={3}>
      <Grid item>
        <img src={StaffEmptyState} alt={'empty'} className={classes.emptyState} />
      </Grid>
      <Grid item>
        <Typography variant={'body1'}>No staff members</Typography>
      </Grid>
    </Grid>
  );
}

const StaffList = ({ Staff }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const columns = [{
    title: 'Name',
    field: 'fullName',
  }, {
    title: 'Email',
    field: 'email',
    render: (staff) => (get(staff, 'email')
      ? get(staff, 'email')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No email provided</span>),
  }, {
    title: 'Phone',
    field: 'phone',
    render: (staff) => (get(staff, 'phone')
      ? get(staff, 'phone')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No phone provided</span>),
  }, {
    title: '',
    render: function EditChip(StaffMember) {
      return (
        <LureChip
          color={'pink'}
          label={'Edit'}
          onClick={(e) => {
            e.stopPropagation();
            dispatch({ type: SET_ACTIVE_STAFF_MEMBER, payload: StaffMember });
            dispatch(openStaffModal());
          }}
        />
      );
    },

  }];

  return (
    <LureTable
      columns={columns}
      title={'Staff List'}
      options={{ pageSize: 5, showTitle: true, toolbar: true }}
      data={Staff}
      onRowClick={(e, StaffMember) => history.push(`/app/staff/${StaffMember._id}`)}
      localization={{
        body: {
          emptyDataSourceMessage: <EmptyState classes={classes} />,
        },
      }}
    />
  );
};

export default StaffList;
