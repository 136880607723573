import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminNavDrawer from '../layout/AdminNavDrawer';
import Header from '../layout/header/Header';
import Modals from './modals/modals';
import CustomerNavDrawer from '../layout/CustomerNavDrawer';
import AppRoutes from '../components/routing/AppRoutes';
import { COMPANY_ROLES } from '../constants/userRoles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
}));

function Main() {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const { path } = useRouteMatch();
  return (
    <>
      <div className={classes.root}>
        <Modals />
        <CssBaseline />
        <Header />
        { COMPANY_ROLES.includes(user.role)
          ? <AdminNavDrawer path={path} />
          : <CustomerNavDrawer path={path} /> }
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth={'xl'}>
            <AppRoutes />
          </Container>
        </main>
      </div>
    </>
  );
}

export default Main;
