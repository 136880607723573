import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { LurePaper } from '@lureapps/lure-ui';
import { Form, Field } from 'react-final-form';
import { get } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment-timezone';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { renderLureSelectField } from '../../shared/formHelpers/fields';
import StoreService from '../../../services/StoreService';
import { REQUESTED } from '../../../constants/bookingStatuses';
import { updateBookingTimeslot } from '../../../store/actions/CustomerPortal/booking';
import { updateBooking } from '../../../store/actions/AdminPortal/booking';

const styles = (theme) => ({
  heading: {
    textTransform: 'uppercase',
    color: theme.colors.darkGrey,
  },
});

const BookingUpdatePanel = () => {
  const dispatch = useDispatch();
  const Booking = useSelector((state) => state.AdminPortal.bookings.activeBooking);
  const [days, setDays] = useState([]);
  const [timeslots, setTimeslots] = useState([]);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const submit = useCallback(({ time }) => {
    dispatch(updateBookingTimeslot(Booking._id, time));
    dispatch(updateBooking(Booking._id, { status: REQUESTED }));
  }, [dispatch, Booking]);

  useEffect(() => {
    if (!get(Booking, '_id', '')) {
      return;
    }
    StoreService.getStoreTimeslots(get(Booking, 'Store._id'))
      .then(({ data: { getStoreTimeslots } }) => {
        setDays(getStoreTimeslots);
      });
  }, [Booking]);

  return (
    <>
      <Badge
        badgeContent={'!'}
        color={'primary'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <LurePaper>
          <Form
            onSubmit={submit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant={'body2'}
                      className={classes.heading}
                    >
                      Update Booking Time
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'}>
                      Unfortunately, your requested time was not able to be fulfilled.
                      Please request an alternative time below.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        name={'date'}
                        component={renderLureSelectField}
                        variant={'outlined'}
                        displayEmpty
                        fullWidth
                        onSelect={(day) => setTimeslots(get(day, 'timeslots'))}
                      >
                        <MenuItem value={''} disabled>Please select a day..</MenuItem>
                        {days.map((day) => (
                          <MenuItem value={day} key={day}>
                            {
                              moment(get(day, 'dayTimestamp'), 'x')
                                .tz(get(Booking, 'Store.timezone'))
                                .format('dddd, Do MMM YYYY')
                            }
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        displayEmpty
                        name={'time'}
                        variant={'outlined'}
                        component={renderLureSelectField}
                        disabled={!timeslots.length}
                      >
                        <MenuItem value={''} disabled>.. and a time</MenuItem>
                        {timeslots.map((timeslot) => (
                          <MenuItem value={timeslot} key={timeslot}>
                            {
                              moment(timeslot, 'x')
                                .tz(get(Booking, 'Store.timezone'))
                                .format('h:mma')
                            }
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Button fullWidth variant={'contained'} color={'primary'} type={'submit'}>
                        Request Booking Time
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </LurePaper>
      </Badge>
    </>
  );
};

export default BookingUpdatePanel;
