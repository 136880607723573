import React from 'react';
import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { ToggleButton } from '@material-ui/lab';
import { renderButtonGroup } from '../../shared/formHelpers/fields';
import { COMPANY_MANAGER, CUSTOMER } from '../../../constants/userRoles';

const styles = (theme) => ({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 10,
    marginBottom: theme.spacing(1.2),
  },
  buffer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  extraPadding: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  btnGroup: {
    width: '100%',
  },
  btnGroupButtons: {
    width: '100%',
  },
});

const RoleSelection = ({ setRegistrationStep }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'h4'}
          gutterBottom
        >
          Register
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'h6'}
          gutterBottom
        >
          Let&#39;s get you setup
        </Typography>
      </Grid>
      <Grid item container justify={'center'} spacing={3}>
        <Typography align={'center'} className={classes.buffer}>
          Begin by selecting which type of account you&#39;d like to register
        </Typography>
        <Grid item xs={8}>
          <Typography className={classes.label}>Account Type</Typography>
          <Field
            name={'role'}
            component={renderButtonGroup}
            className={classes.btnGroup}
            size={'small'}
            exclusive
          >
            <ToggleButton value={COMPANY_MANAGER} className={classes.btnGroupButtons}>
              Company
            </ToggleButton>
            <ToggleButton value={CUSTOMER} className={classes.btnGroupButtons}>
              Customer
            </ToggleButton>
          </Field>
        </Grid>
        <Grid item xs={8}>
          <Button
            fullWidth
            className={classes.extraPadding}
            variant={'contained'}
            color={'primary'}
            onClick={() => setRegistrationStep(1)}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default RoleSelection;
