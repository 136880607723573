import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { capitalize, get } from 'lodash';
import { Settings, Users, LogOut } from 'react-feather';
import {
  AppBar,
  Toolbar,
  MenuList,
  MenuItem,
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { drawerWidth, initials } from '../AdminNavDrawer';
import { logout } from '../../store/actions/auth';
import { SETTINGS_ROUTE } from '../../constants/routes';
import { NO_ROLE } from '../../constants/userRoles';
import { openChangeAccountModal } from '../../store/actions/modals';

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: 'white',
    color: 'black',
  },
  appBarContainer: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(3),
  },
  avatarInitials: {
    backgroundColor: theme.palette.primary.light,
  },
  dropdown: {
    cursor: 'pointer',
  },
  menu: {
    width: '200px',
    marginTop: '-10px',
    marginLeft: '20px',
    borderRadius: '4px',
  },
  icon: {
    position: 'relative',
    top: '3px',
    color: theme.palette.primary.main,
  },
});

const Header = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const { path } = useRouteMatch();

  const [role, setRole] = useState('');
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    const rawRole = get(user, 'role', '');

    if (user.role === null || user.role === NO_ROLE) {
      setRole('');
      return;
    }

    setRole(capitalize(rawRole.replace('_', ' ')));
  }, [user]);

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleSettings = useCallback(() => {
    history.push(`${path}${SETTINGS_ROUTE}`);
    setAnchor(null);
  }, [history, path]);

  return (
    <>
      <AppBar position={'fixed'} className={classes.appBar}>
        <Toolbar>
          <Grid style={{ width: drawerWidth }} />
          <Grid container justify={'space-between'} alignItems={'center'} className={classes.appBarContainer}>
            <Grid item xs={3} />
            <Grid item>
              { user && (
                <>
                  <List
                    dense
                    className={classes.dropdown}
                    aria-haspopup={'true'}
                    aria-controls={'user-settings-dropdown'}
                    onClick={handleClick}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        { user.picture
                          ? <Avatar alt={user.name} src={user.picture} size={'small'} />
                          : <Avatar className={classes.avatarInitials} size={'small'}>{ initials(user.name) }</Avatar> }

                      </ListItemAvatar>
                      <ListItemText primary={user.name} secondary={role} />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge={'end'}
                          size={'small'}
                        >
                          {anchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  <Popper
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        in={TransitionProps.in}
                        onEnter={TransitionProps.onEnter}
                        onExited={TransitionProps.onExited}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center right' : 'center right',
                        }}
                      >
                        <Grid container md={12}>
                          <Grid item>
                            <Paper className={classes.menu}>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={Boolean(anchor)} id={'user-settings-dropdown'}>
                                  <MenuItem className={classes.menuItem} onClick={handleSettings}>
                                    <Grid container justify={'space-between'} alignItems={'center'}>
                                      <Grid item xs={4}>
                                        <Settings className={classes.icon} size={16} />
                                      </Grid>
                                      <Grid item xs={8}>Settings</Grid>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => dispatch(openChangeAccountModal())}
                                  >
                                    <Grid container justify={'space-between'} alignItems={'center'}>
                                      <Grid item xs={4}>
                                        <Users className={classes.icon} size={16} />
                                      </Grid>
                                      <Grid item xs={8}>Change account</Grid>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem className={classes.menuItem} onClick={handleLogout}>
                                    <Grid container justify={'space-between'} alignItems={'center'}>
                                      <Grid item xs={4}>
                                        <LogOut className={classes.icon} size={16} />
                                      </Grid>
                                      <Grid item xs={8}>Logout</Grid>
                                    </Grid>
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grow>
                    )}
                  </Popper>
                </>
              ) }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
