import React, { useEffect, useState } from 'react';
import { LurePageHeading } from '@lureapps/lure-ui';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CompanyService from '../../../services/CompanyService';
import CompanyCard from './CompanyCard';

const Marketplace = () => {
  const [customerCompanies, setCustomerCompanies] = useState([]);
  const Customer = useSelector((state) => state.auth.user.Customer);

  useEffect(() => {
    CompanyService.customerCompanies(Customer._id)
      .then(({ data: { customerCompanies: cc } }) => setCustomerCompanies(cc));
  }, [Customer]);

  return (
    <>
      <LurePageHeading title={'Marketplace'} />
      <Grid container spacing={3}>
        {
          customerCompanies.map((Company) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={Company._id}>
              <CompanyCard Company={Company} />
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};

export default Marketplace;
