import React, { Component } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';

import CircularProgress from '@material-ui/core/CircularProgress';
import { renderLureTextField, RenderStoreAutosuggest } from '../shared/formHelpers/fields';
import {
  createCustomer as _createCustomer,
  getCustomer as _getCustomer,
  updateCustomer as _updateCustomer,
} from '../../store/actions/AdminPortal/customer';
import { closeCustomerModal as _closeCustomerModal } from '../../store/actions/modals';
import { snackError as _snackError } from '../../store/actions/snackbar';
import { required } from '../shared/formHelpers/validation';
import CustomerService from "../../services/CustomerService";

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  leftBuffer: {
    paddingLeft: theme.spacing(5),
  },
  rightBuffer: {
    paddingRight: theme.spacing(5),
  },
  loader: {
    color: 'white',
  },
});

class AddCustomerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
    };
  }

  submit = async (values) => {
    const {
      _id: isEdit,
      createCustomer,
      updateCustomer,
      customer: { _id, email: preEditEmail },
      closeModal,
      snackError,
    } = this.props;
    this.setState({ loading: true });

    if (values.email) {
      const { data: { companyCustomerExists } } = await CustomerService.companyCustomerExists(values.email);
      if (companyCustomerExists && (values.email !== preEditEmail)) {
        this.setState({ error: 'A Customer with that email already exists', loading: false });
        return;
      }
    }

    if (isEdit) {
      return updateCustomer(_id, values)
        .then(() => {
          closeModal();
        })
        .catch(() => snackError('Error while updating customer.'))
        .finally(() => this.setState({ loading: false, error: '' }));
    }

    return createCustomer(values)
      .then(() => closeModal())
      .catch(() => snackError('Error while creating customer.'))
      .finally(() => this.setState({ loading: false, error: '' }));
  };

  render() {
    const {
      open, classes, _id: isEdit, customer, closeModal,
    } = this.props;
    const { loading, error } = this.state;
    return (
      <LureDialog
        open={open}
        maxWidth={'sm'}
        fullWidth
        disableBackdropClick
      >
        <LureDialogTitle>
          {isEdit ? 'Edit Customer' : 'Add Customer'}
        </LureDialogTitle>
        <Form
          onSubmit={this.submit}
          initialValues={customer}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LureDialogContent>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name={'firstName'}
                      label={'First Name'}
                      placeholder={'Enter your first name'}
                      component={renderLureTextField}
                      validate={required}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name={'lastName'}
                      label={'Last Name'}
                      placeholder={'Enter your last name'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name={'email'}
                      label={'Email'}
                      placeholder={'Enter email'}
                      component={renderLureTextField}
                    />
                    {
                      error && <Typography color={'error'} variant={'body2'}>{error}</Typography>
                    }
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name={'phone'}
                      label={'Phone'}
                      placeholder={'Enter phone'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name={'primaryStore'}
                      label={'Primary Store'}
                      placeholder={'Enter your primary store'}
                      size={'small'}
                      component={RenderStoreAutosuggest}
                    />
                  </Grid>
                </Grid>
              </LureDialogContent>
              <LureDialogActions>
                <Grid container>
                  <Grid item xs={6} className={classes.rightBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'outlined'}
                      onClick={() => {
                        this.setState({ error: '', loading: false });
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.leftBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'contained'}
                      type={'submit'}
                    >
                      {loading
                        ? <CircularProgress size={20} className={classes.loader} /> : 'Save Customer'}
                    </Button>
                  </Grid>
                </Grid>
              </LureDialogActions>
            </form>

          )}
        />
      </LureDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    open: state.modals.addCustomerModal.isOpen,
    _id: state.modals.addCustomerModal._id,
    cb: state.modals.addCustomerModal.options.cb,
    customer: state.AdminPortal.customer.active,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeCustomerModal()),
    createCustomer: (data) => dispatch(_createCustomer(data)),
    updateCustomer: (_id, data) => dispatch(_updateCustomer(_id, data)),
    getCustomer: (_id) => dispatch(_getCustomer(_id)),
    snackError: (e) => dispatch(_snackError(e)),
  };
}

AddCustomerModal = withStyles(styles)(AddCustomerModal);
AddCustomerModal = connect(mapStateToProps, mapDispatchToProps)(AddCustomerModal);

export default AddCustomerModal;
