import { gql } from 'apollo-boost';
import gqlClient from './GraphQLService';

const SINGLE_BOOKING_FRAGMENT = gql`
    fragment SingleBooking on Booking {
        _id
        bookingReference
        status
        createdAt
        message
        Customer {
            _id
            firstName
            lastName
            fullName
            email
            phone
            primaryStore {
                _id
                name
            }
        }
        Store {
            _id
            name
            phone
            email
            timezone
            address {
                addressLine1
                addressLine2
                suburb
                postcode
                city
            }
            Staff {
                _id
                fullName
            }
        }
        StaffMember {
            _id
            firstName
            lastName
            fullName
            email
            phone
        }
        BookingProducts(first: 100) {
          totalCount
          nodes {
            _id
            qty
            note
            Product {
              _id
              title
              formattedName
              formattedPrice
              price
              image
            }
          }
        }
        Company {
            _id
            name
        }
        timeslots {
            _id
            timeslot
        }
        acceptedTimeslot {
            timeslot
        }
    }
`;

const GQL_GET_BOOKINGS = gql`
  query getBookings {
    bookings {
        ...SingleBooking
    }
  }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_GET_BOOKING = gql`
    query getBooking($bookingId:ID!) {
        booking(_id:$bookingId) {
            ...SingleBooking
        }
    }
    ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_UPDATE_BOOKING = gql`
    mutation updateBooking($_id: ID!, $booking: BookingUpdateInput) {
        updateBooking(_id: $_id, booking: $booking) {
            ...SingleBooking
        }
    }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_SET_BOOKING_STATUS = gql`
    mutation setBookingStatus($_id: ID!, $bookingStatus: BookingStatus) {
      setBookingStatus(_id: $_id, bookingStatus: $bookingStatus) {
        ...SingleBooking
      }
    }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_APPROVE_BOOKING = gql`
  mutation acceptBooking($bookingId:ID!, $bookingTimeslotId:ID!, $staffId:String!) {
      acceptBooking(_id:$bookingId, bookingTimeslotId:$bookingTimeslotId, staffId:$staffId) {
          ...SingleBooking
      }
  }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_GET_BOOKING_TIMELINE = gql`
    query bookingTimeline($_id:ID!, $entity:String!) {
        bookingTimeline(_id:$_id, entity:$entity) {
            upcomingBookings {
                ...SingleBooking
            }
            historicBookings {
                ...SingleBooking
            }
        }
    }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_GET_CUSTOMER_BOOKINGS = gql`
    query customerBookings($customerId: ID!) {
        customerBookings(customerId: $customerId) {
            ...SingleBooking
        }
    }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_CUSTOMER_BOOKINGS = gql`
  query me($queryOptions: QueryOptions!, $bookingOptions: BookingOptions) {
    me {
      Customer {
        Bookings(queryOptions: $queryOptions, bookingOptions: $bookingOptions) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              _id
              bookingReference
              status
              createdAt
              timeslots {
                timeslot
              }
              Company {
                name
              }
              Store {
                name
              }
            }
          }
          nodes {
            _id
            status
            bookingReference
            createdAt
            timeslots {
              timeslot
            }
            Company {
              name
            }
            Store {
              name
            }
          }
        }
      }
    }
  }
`;

const GQL_UPDATE_BOOKING_TIMESLOT = gql`
    mutation updateBookingTimeslot($_id: ID!, $timeslot: String!) {
        updateBookingTimeslot(_id: $_id, timeslot: $timeslot) {
          ...SingleBooking
        }
    }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_CREATE_BOOKING = gql`
    mutation createBookingFromBMS($booking: BookingCreateInput!) {
      createBookingFromBMS(booking: $booking) {
        ...SingleBooking
      }
    }
  ${SINGLE_BOOKING_FRAGMENT}
`;

const GQL_COMPLETE_BOOKING = gql`
    mutation completeBooking($_id: ID!, $completeBookingInput: CompleteBookingInput!){
      completeBooking(_id: $_id, completeBookingInput: $completeBookingInput) {
        ...SingleBooking
      }
    }
  ${SINGLE_BOOKING_FRAGMENT}
`;

class BookingService {
  static getBookings() {
    return gqlClient.query({
      query: GQL_GET_BOOKINGS,
    });
  }

  static getBooking(_id) {
    return gqlClient.query({
      query: GQL_GET_BOOKING,
      variables: {
        bookingId: _id,
      },
    });
  }

  static updateBooking(_id, booking) {
    return gqlClient.mutate({
      mutation: GQL_UPDATE_BOOKING,
      variables: {
        _id,
        booking,
      },
    });
  }

  static setBookingStatus(_id, bookingStatus) {
    return gqlClient.mutate({
      mutation: GQL_SET_BOOKING_STATUS,
      variables: {
        _id,
        bookingStatus,
      },
    });
  }

  static getBookingTimeline(_id, entity) {
    return gqlClient.query({
      query: GQL_GET_BOOKING_TIMELINE,
      variables: {
        _id,
        entity,
      },
    });
  }

  static approveBooking(bookingId, timeslotId, staffId) {
    return gqlClient.mutate({
      mutation: GQL_APPROVE_BOOKING,
      variables: {
        bookingId,
        bookingTimeslotId: timeslotId,
        staffId,
      },
    })
      .then((data) => data);
  }

  static getCustomerBookings(customerId) {
    return gqlClient.query({
      query: GQL_GET_CUSTOMER_BOOKINGS,
      variables: {
        customerId,
      },
    });
  }

  static updateBookingTimeslot(bookingId, timeslot) {
    return gqlClient.mutate({
      mutation: GQL_UPDATE_BOOKING_TIMESLOT,
      variables: {
        _id: bookingId,
        timeslot,
      },
    });
  }

  static createBooking(booking) {
    return gqlClient.mutate({
      mutation: GQL_CREATE_BOOKING,
      variables: {
        booking,
      },
    });
  }

  static completeBooking(_id, completeBookingInput) {
    return gqlClient.mutate({
      mutation: GQL_COMPLETE_BOOKING,
      variables: {
        _id,
        completeBookingInput,
      },
    });
  }

  static customerBookings(queryOptions = { first: 10 }) {
    return gqlClient.query({
      query: GQL_CUSTOMER_BOOKINGS,
      variables: {
        queryOptions,
      },
    });
  }
}

export default BookingService;
