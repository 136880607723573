import React, { useEffect } from 'react';
import { Button, Grid, MuiThemeProvider } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { LurePageHeading, LurePaper } from '@lureapps/lure-ui';
import { useDispatch, useSelector } from 'react-redux';
import black from '../../../theme/black';
import Information from './Information';
import StaffList from './StaffList';
import StoreBookings from './StoreBookings';

import { getStore } from '../../../store/actions/AdminPortal/store';

const StoreDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Store = useSelector((state) => state.AdminPortal.store.activeStore);
  const { storeId } = useParams();

  useEffect(() => {
    dispatch(getStore(storeId));
  }, [dispatch, storeId, Store]);

  return (
    <>
      <LurePageHeading
        title={'Stores'}
        action={(
          <MuiThemeProvider theme={black}>
            <Button variant={'outlined'} onClick={() => history.goBack()}>Go Back</Button>
          </MuiThemeProvider>
        )}
      />
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <LurePaper shadow noPadding>
              <Information Store={Store} />
            </LurePaper>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <LurePaper>
              <StoreBookings storeId={storeId} />
            </LurePaper>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <LurePaper noPadding>
              <StaffList Staff={Store.Staff} />
            </LurePaper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StoreDetail;
