import {
  CLOSE_ACCEPT_BOOKING_MODAL,
  CLOSE_ADD_NOTE_MODAL,
  CLOSE_ADD_STAFF_MODAL,
  CLOSE_CUSTOMER_MODAL,
  CLOSE_REGISTRATION_MODAL,
  CLOSE_REJECT_REQUEST_MODAL,
  CLOSE_STORE_MODAL,
  OPEN_ACCEPT_BOOKING_MODAL,
  OPEN_ADD_NOTE_MODAL,
  OPEN_ADD_STAFF_MODAL,
  OPEN_CUSTOMER_MODAL,
  OPEN_REGISTRATION_MODAL,
  OPEN_REJECT_REQUEST_MODAL,
  OPEN_STORE_MODAL,
  OPEN_CONFIRM_MODAL,
  CLOSE_CONFIRM_MODAL,
  OPEN_CHANGE_ACCOUNT_MODAL,
  CLOSE_CHANGE_ACCOUNT_MODAL,
  OPEN_BOOKING_MODAL,
  CLOSE_BOOKING_MODAL,
  OPEN_COMPANY_MODAL,
  CLOSE_COMPANY_MODAL,
  OPEN_TRADING_HOURS_MODAL,
  CLOSE_TRADING_HOURS_MODAL,
  OPEN_CUSTOMER_SETTINGS_MODAL,
  CLOSE_CUSTOMER_SETTINGS_MODAL,
  OPEN_BOOKING_COMPLETE_MODAL,
  CLOSE_BOOKING_COMPLETE_MODAL,
  OPEN_INSTALLATION_MODAL,
  OPEN_PRODUCT_TEST_MODAL,
  CLOSE_PRODUCT_TEST_MODAL,
  CLOSE_INSTALLATION_MODAL,
  CLOSE_USER_REGISTRATION_MODAL,
  OPEN_USER_REGISTRATION_MODAL,
} from '../actions/modals';

const initialState = {
  addCustomerModal: {
    isOpen: false,
    _id: null,
    options: {},
  },
  addNoteModal: {
    isOpen: false,
  },
  registrationModal: {
    isOpen: false,
  },
  staffModal: {
    isOpen: false,
  },
  storeModal: {
    isOpen: false,
  },
  rejectRequestModal: {
    isOpen: false,
  },
  acceptBookingModal: {
    isOpen: false,
    Booking: {},
  },
  confirmModal: {
    isOpen: false,
    options: {},
  },
  changeAccountModal: {
    isOpen: false,
  },
  companyModal: {
    isOpen: false,
    options: {},
  },
  tradingHoursModal: {
    isOpen: false,
    options: {},
  },
  bookingModal: {
    isOpen: false,
  },
  customerSettingsModal: {
    isOpen: false,
    Customer: {},
  },
  bookingCompleteModal: {
    isOpen: false,
    Booking: {},
  },
  installationModal: {
    isOpen: false,
  },
  productTestModal: {
    isOpen: false,
  },
  userRegistrationModal: {
    isOpen: false,
  },
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CUSTOMER_MODAL:
      return {
        ...state,
        addCustomerModal: {
          isOpen: true,
          _id: action.payload.customerId,
          options: {
            cb: action.payload.cb,
          },
        },
      };

    case CLOSE_CUSTOMER_MODAL:
      return {
        ...state,
        addCustomerModal: {
          isOpen: false,
          _id: null,
          options: {},
        },
      };

    case OPEN_ADD_NOTE_MODAL:
      return {
        ...state,
        addNoteModal: {
          isOpen: true,
        },
      };

    case CLOSE_ADD_NOTE_MODAL:
      return {
        ...state,
        addNoteModal: {
          isOpen: false,
        },
      };

    case OPEN_ADD_STAFF_MODAL:
      return {
        ...state,
        staffModal: {
          isOpen: true,
        },
      };

    case CLOSE_ADD_STAFF_MODAL:
      return {
        ...state,
        staffModal: {
          isOpen: false,
        },
      };

    case OPEN_REGISTRATION_MODAL:
      return {
        ...state,
        registrationModal: {
          isOpen: true,
        },
      };

    case CLOSE_REGISTRATION_MODAL:
      return {
        ...state,
        registrationModal: {
          isOpen: false,
        },
      };

    case OPEN_STORE_MODAL:
      return {
        ...state,
        storeModal: {
          isOpen: true,
        },
      };

    case CLOSE_STORE_MODAL:
      return {
        ...state,
        storeModal: {
          isOpen: false,
        },
      };

    case OPEN_REJECT_REQUEST_MODAL:
      return {
        ...state,
        rejectRequestModal: {
          isOpen: true,
        },
      };

    case CLOSE_REJECT_REQUEST_MODAL:
      return {
        ...state,
        rejectRequestModal: {
          isOpen: false,
        },
      };

    case OPEN_ACCEPT_BOOKING_MODAL:
      return {
        ...state,
        acceptBookingModal: {
          isOpen: true,
          Booking: action.payload,
        },
      };

    case CLOSE_ACCEPT_BOOKING_MODAL:
      return {
        ...state,
        acceptBookingModal: {
          isOpen: false,
          Booking: {},
        },
      };

    case OPEN_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          isOpen: true,
          options: action.payload,
        },
      };

    case CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          isOpen: false,
          options: {},
        },
      };

    case OPEN_CHANGE_ACCOUNT_MODAL:
      return {
        ...state,
        changeAccountModal: {
          isOpen: true,
        },
      };

    case CLOSE_CHANGE_ACCOUNT_MODAL:
      return {
        ...state,
        changeAccountModal: {
          isOpen: false,
        },
      };

    case OPEN_BOOKING_MODAL:
      return {
        ...state,
        bookingModal: {
          isOpen: true,
        },
      };

    case CLOSE_BOOKING_MODAL:
      return {
        ...state,
        bookingModal: {
          isOpen: false,
        },
      };

    case OPEN_COMPANY_MODAL:
      return {
        ...state,
        companyModal: {
          isOpen: true,
          options: action.payload,
        },
      };

    case CLOSE_COMPANY_MODAL:
      return {
        ...state,
        companyModal: {
          isOpen: false,
          options: {},
        },
      };

    case OPEN_TRADING_HOURS_MODAL:
      return {
        ...state,
        tradingHoursModal: {
          isOpen: true,
          options: { ...action.payload },
        },
      };

    case CLOSE_TRADING_HOURS_MODAL:
      return {
        ...state,
        tradingHoursModal: {
          isOpen: false,
          options: {},
        },
      };

    case OPEN_CUSTOMER_SETTINGS_MODAL:
      return {
        ...state,
        customerSettingsModal: {
          isOpen: true,
          Customer: action.payload,
        },
      };

    case CLOSE_CUSTOMER_SETTINGS_MODAL:
      return {
        ...state,
        customerSettingsModal: {
          isOpen: false,
        },
      };

    case OPEN_BOOKING_COMPLETE_MODAL:
      return {
        ...state,
        bookingCompleteModal: {
          isOpen: true,
          Booking: action.payload,
        },
      };

    case CLOSE_BOOKING_COMPLETE_MODAL:
      return {
        ...state,
        bookingCompleteModal: {
          isOpen: false,
        },
      };

    case OPEN_INSTALLATION_MODAL:
      return {
        ...state,
        installationModal: {
          isOpen: true,
        },
      };

    case CLOSE_INSTALLATION_MODAL:
      return {
        ...state,
        installationModal: {
          isOpen: false,
        },
      };

    case OPEN_PRODUCT_TEST_MODAL:
      return {
        ...state,
        productTestModal: {
          isOpen: true,
        },
      };

    case CLOSE_PRODUCT_TEST_MODAL:
      return {
        ...state,
        productTestModal: {
          isOpen: false,
        },
      };

    case OPEN_USER_REGISTRATION_MODAL:
      return {
        ...state,
        userRegistrationModal: {
          isOpen: true,
        },
      };

    case CLOSE_USER_REGISTRATION_MODAL:
      return {
        ...state,
        userRegistrationModal: {
          isOpen: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default modals;
