import React, { useEffect } from 'react';
import { LurePageHeading } from '@lureapps/lure-ui';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import BookingAnalytics from './BookingAnalytics';
import RecentCustomers from './RecentCustomers';
import RecentBookings from './RecentBookings';
import { getBookings } from '../../store/actions/AdminPortal/booking';
import { getCustomers } from '../../store/actions/AdminPortal/customer';

const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !user.CurrentCompany) return;
    dispatch(getBookings());
    dispatch(getCustomers());
  }, [user]);

  return (
    <>
      <LurePageHeading title={'Dashboard'} />
      <Grid container spacing={5}>
        <BookingAnalytics />
        <Grid item container direction={'column'} xs={12} lg={4}>
          <RecentCustomers />
        </Grid>
        <Grid item xs={12} lg={8}>
          <RecentBookings />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
