import React, { useCallback } from 'react';
import { Edit2 } from 'react-feather';
import {
  LurePaper,
} from '@lureapps/lure-ui';
import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { openCompanyModal } from '../../../store/actions/modals';


const styles = (theme) => ({
  headingSection: {
    marginBottom: theme.spacing(3),
  },
});

function GeneralSettings() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const dispatch = useDispatch();
  const Company = useSelector((state) => state.auth.user.CurrentCompany);

  const handleCompanyEdit = useCallback(() => {
    dispatch(openCompanyModal());
  }, [dispatch]);

  return (
    <>
      <LurePaper>
        <Grid container justify={'space-between'} alignItems={'center'} className={classes.headingSection}>
          <Grid item>
            <Typography variant={'h6'}>Company Details</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleCompanyEdit} color={'primary'}>
              <Edit2 size={16} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container direction={'column'} spacing={1}>
          <Grid item>
            <Typography
              variant={'subtitle1'}
            >
              {`${get(Company, 'name')}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'}>{get(Company, 'email', '-')}</Typography>
          </Grid>
          <Grid item>
            <Typography>{get(Company, 'website', '-')}</Typography>
          </Grid>
          <Grid item>
            <Typography>{get(Company, 'phone', '-')}</Typography>
          </Grid>
        </Grid>
      </LurePaper>
    </>
  );
}

export default GeneralSettings;
