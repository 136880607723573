import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import * as firebase from 'firebase';

export const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const user = firebase.auth().currentUser;
  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
