import React from 'react';
import {
  LurePageHeading,
} from '@lureapps/lure-ui';
import { Grid, Button } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import GeneralSettings from './shared/GeneralSettings';
import InstallationGuide from './shared/InstallationGuide';
import TradingHours from './shared/TradingHours';
import { openInstallationModal } from '../../store/actions/modals';
import ProductDemo from './shared/ProductDemo';

function Settings() {
  const dispatch = useDispatch();
  return (
    <>
      <LurePageHeading
        title={'Settings'}
        action={(
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={() => dispatch(openInstallationModal())}
          >
            Install Lure on my website
          </Button>
        )}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4} xl={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <GeneralSettings />
            </Grid>
            <Grid item xs={12}>
              <InstallationGuide />
            </Grid>
            <Grid item xs={12}>
              <ProductDemo />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <TradingHours />
        </Grid>
      </Grid>
    </>
  );
}

export default Settings;
