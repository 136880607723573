import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Edit2 } from 'react-feather';
import { get } from 'lodash';

import Button from '@material-ui/core/Button';
import { Field, Form } from 'react-final-form';
import { RenderStoreAutosuggest, StoreStaffAutocomplete } from '../../shared/formHelpers/fields';
import { updateBooking } from '../../../store/actions/AdminPortal/booking';
import { CUSTOMER } from '../../../constants/userRoles';

const styles = (theme) => ({
  heading: {
    textTransform: 'uppercase',
    color: theme.colors.darkGrey,
  },
  rightBuffer: {
    margin: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
});

function ButtonGroup({ setEditing, classes }) {
  return (
    <>
      <Button
        variant={'outlined'}
        color={'primary'}
        size={'small'}
        onClick={() => setEditing(false)}
        className={classes.rightBuffer}
      >
        Close
      </Button>
      <Button
        variant={'contained'}
        color={'primary'}
        type={'submit'}
        size={'small'}
      >
        Save
      </Button>
    </>
  );
}

const StorePanel = ({ Store, Booking }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [activeStore, setActiveStore] = useState({});
  const userRole = useSelector((state) => state.auth.user.role);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const submit = useCallback(({ store, staff }, form) => {
    dispatch(updateBooking(Booking._id, {
      storeId: store._id,
      staffId: staff._id,
    }, () => {
      setEditing(false);
      form.reset();
    }));
  }, [dispatch, Booking]);

  return (
    <>
      <Form
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} direction={'column'}>
              <Grid item container justify={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Typography variant={'body2'} className={classes.heading} gutterBottom>Store</Typography>
                  <Typography variant={'h5'}>
                    { editing ? 'Update Store' : get(Store, 'name', '') }
                  </Typography>
                </Grid>
                <Grid item>
                  {
                      editing ? <ButtonGroup classes={classes} setEditing={setEditing} />
                        : (
                          <IconButton
                            className={userRole === CUSTOMER ? classes.hidden : ''}
                            onClick={() => setEditing(true)}
                            color={'primary'}
                          >
                            <Edit2 size={16} />
                          </IconButton>
                        )
                    }
                </Grid>
              </Grid>
              {
                  editing
                    ? (
                      <Grid container direction={'column'} spacing={2}>
                        <Grid item>
                          <Field
                            name={'store'}
                            size={'small'}
                            getStoreOnChange={(s) => setActiveStore(s)}
                            component={RenderStoreAutosuggest}
                          />
                        </Grid>
                        <Grid item>
                          <Field
                            name={'staff'}
                            label={'Allocate a new Staff Member'}
                            Staff={get(activeStore, 'Staff', [])}
                            disabled={!get(activeStore, '_id', '')}
                            component={StoreStaffAutocomplete}
                          />
                        </Grid>
                      </Grid>
                    )
                    : (
                      <Grid item>
                        { !Store || !Store.email
                          ? <Typography variant={'body1'}>No email provided</Typography>
                          : (
                            <Typography variant={'body1'}>
                              { get(Store, 'email', '') }
                            </Typography>
                          )}
                        { !Store || !Store.phone
                          ? <Typography variant={'body1'}>No phone provided</Typography>
                          : (
                            <Typography variant={'body1'}>
                              { get(Store, 'phone', '') }
                            </Typography>
                          )}
                      </Grid>
                    )
                }
            </Grid>
          </form>
        )}
      />
    </>
  );
};

export default StorePanel;
