import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {
  LureDialog,
  LureDialogContent,
  LureDialogTitle,
} from '@lureapps/lure-ui';
import { get, startCase, toLower } from 'lodash';
import { closeChangeAccountModal } from '../../store/actions/modals';
import ChangeAccountsImage from '../../assets/images/changeAccounts.svg';
import { initials } from '../../layout/AdminNavDrawer';
import UserService from '../../services/UserService';

const styles = (theme) => ({
  image: {
    maxWidth: theme.spacing(20),
  },
  list: {
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
});

const parseRole = (role) => startCase(toLower(role));

const ChangeAccountModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modals.changeAccountModal);
  const User = useSelector((state) => state.auth.user);
  const { UserCompanies } = User;
  const activeUserRole = get(User, 'role');

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const updateActiveUserCompany = useCallback(async (userCompanyId) => {
    await UserService.updateActiveUserCompany(userCompanyId);
    window.location.reload(false);
  }, []);

  return (
    <LureDialog
      open={isOpen}
      onClose={() => dispatch(closeChangeAccountModal())}
      maxWidth={'sm'}
      fullWidth
    >
      <LureDialogTitle>
        Change User Account
      </LureDialogTitle>
      <LureDialogContent>
        <Grid container direction={'column'} alignItems={'center'} spacing={3}>
          <Grid item>
            <img alt={'Change accounts'} src={ChangeAccountsImage} className={classes.image} />
          </Grid>
          <Grid item>
            <Typography
              variant={'body1'}
              align={'center'}
            >
              Here you can swap between your different Lure accounts.
              Your accounts are listed below.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List className={classes.list}>
            {
              UserCompanies.map((userCompany) => (
                <ListItem key={get(userCompany, 'role')}>
                  <ListItemAvatar>
                    <Avatar
                      src={User.picture}
                      size={'small'}
                    >
                      {initials(User.name)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <Typography>
                        {get(User, 'name')}
                      </Typography>
                    )}
                    secondary={parseRole(get(userCompany, 'role'))}
                  />
                  <ListItemSecondaryAction>
                    <Button
                      disabled={get(userCompany, 'role') === activeUserRole}
                      variant={'contained'}
                      color={'primary'}
                      onClick={() => updateActiveUserCompany(get(userCompany, '_id'))}
                    >
                      {
                        get(userCompany, 'role') === activeUserRole
                          ? 'Active'
                          : 'Log In'
                      }
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </Grid>
      </LureDialogContent>
    </LureDialog>
  );
};

export default ChangeAccountModal;
