import { gql } from 'apollo-boost';
import { get, pick } from 'lodash';
import gqlClient from './GraphQLService';

const GQL_SINGLE_STAFF_MEMBER = gql`
    fragment SingleStaffMember on StaffMember {
        _id
        firstName
        lastName
        fullName
        phone
        email
        primaryStore {
            _id
            name
            phone
            email
            address {
                addressLine1
                addressLine2
                suburb
                city
                postcode
                country
            }
        }
    }
`;

const GQL_GET_STAFF = gql`
  query getStaff {
    getStaff {
        ...SingleStaffMember
    }
  }
  ${GQL_SINGLE_STAFF_MEMBER}
`;

const GQL_GET_STAFF_MEMBER = gql`
  query getStaffMember($_id:ID!) {
    getStaffMember(_id:$_id) {
        ...SingleStaffMember
    }
  }
  ${GQL_SINGLE_STAFF_MEMBER}
`;

const GQL_CREATE_STAFF_MEMBER = gql`
  mutation createStaffMember($staffMember:StaffMemberInput!) {
    createStaffMember(staffMember:$staffMember) {
        ...SingleStaffMember
    }
  }
  ${GQL_SINGLE_STAFF_MEMBER}
`;

const GQL_UPDATE_STAFF_MEMBER = gql`
  mutation updateStaffMember($_id:ID!, $staffMember:StaffMemberInput) {
    updateStaffMember(_id:$_id, staffMember:$staffMember) {
        ...SingleStaffMember
    }
  }
  ${GQL_SINGLE_STAFF_MEMBER}
`;

const GQL_DELETE_STAFF_MEMBER = gql`
  mutation deleteStaffMember($_id:ID!) {
    deleteStaffMember(_id:$_id) {
      _id
    }
  }
`;

const GQL_STAFF_MEMBER_EXISTS = gql`
  query staffMemberExists($email:String!) {
    staffMemberExists(email:$email)
  }
`;

export class StaffService {
  static getStaff() {
    return gqlClient.query({
      query: GQL_GET_STAFF,
    });
  }

  static getStaffMember(_id) {
    return gqlClient.query({
      query: GQL_GET_STAFF_MEMBER,
      variables: {
        _id,
      },
    });
  }

  static createStaffMember(input) {
    const staffMember = this._parseStaffMember(input);
    return gqlClient.mutate({
      mutation: GQL_CREATE_STAFF_MEMBER,
      variables: { staffMember },
    });
  }

  static updateStaffMember(_id, input) {
    const staffMember = this._parseStaffMember(input);
    return gqlClient.mutate({
      mutation: GQL_UPDATE_STAFF_MEMBER,
      variables: {
        _id,
        staffMember,
      },
    });
  }

  static deleteStaffMember(_id) {
    return gqlClient.mutate({
      mutation: GQL_DELETE_STAFF_MEMBER,
      variables: { _id },
    });
  }

  static staffMemberExists(email) {
    return gqlClient.query({
      query: GQL_STAFF_MEMBER_EXISTS,
      variables: {
        email,
      },
    });
  }

  static _parseStaffMember(input) {
    const storeId = get(input, 'primaryStore._id');
    const parsedStaffMember = pick(input, [
      'firstName',
      'lastName',
      'phone',
      'email',
    ]);
    if (storeId) parsedStaffMember.primaryStore = storeId;
    return parsedStaffMember;
  }
}

export default StaffService;
