const userHasAllFields = (user) => {
  let hasAllUserFields = true;

  if (!user) return false;

  if (!user.displayName || !user.email) hasAllUserFields = false;

  return hasAllUserFields;
};

export default userHasAllFields;
