import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Checkbox,
  ListItemIcon,
} from '@material-ui/core';
import { get } from 'lodash';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

const ProductList = ({ Booking, form, updatePurchaseTotal }) => (
  <>
    <List dense>
      <FieldArray name={'BookingProducts.nodes'}>
        {
            ({ fields }) => fields.map((field) => (
              <Field
                name={`${field}.purchased`}
                type={'checkbox'}
                component={({ input: { checked, onChange } }) => (
                  <ListItem key={field}>
                    <ListItemIcon>
                      <Checkbox
                        edge={'start'}
                        checked={Boolean(checked)}
                        onChange={(e, val) => {
                          e.stopPropagation();
                          onChange(val);
                          updatePurchaseTotal(get(form.getState(), 'values.BookingProducts.nodes', []));
                        }}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemAvatar>
                      <Avatar
                        src={get(Booking, `${field}.Product.image`)}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={get(Booking, `${field}.Product.title`)}
                      secondary={
                        `${get(Booking, `${field}.Product.formattedName`)} - ${get(Booking, `${field}.Product.formattedPrice`)} `
                      }
                    />
                  </ListItem>
                )}
              />
            ))
          }
      </FieldArray>
    </List>
  </>
);

export default ProductList;
