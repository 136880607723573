import React, { useCallback } from 'react';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { RenderStaffAutosuggest } from '../../shared/formHelpers/fields';
import { ACCEPTED, REQUESTED } from '../../../constants/bookingStatuses';
import { setCalendarFilteredBookings } from '../../../store/actions/AdminPortal/booking';
import StoreAutoComplete from '../../shared/AutoCompletes/StoreAutocomplete';

const StatusFilterOption = () => {
  const [status, setStatus] = React.useState('');
  const dispatch = useDispatch();
  const Bookings = useSelector((state) => state.AdminPortal.bookings.calendarBookings.list);

  const handleStatusChange = useCallback((newStatus) => {
    const filteredBookings = Bookings.filter((br) => br.status === newStatus);
    dispatch(setCalendarFilteredBookings(filteredBookings));
    setStatus(newStatus);
  }, [Bookings, dispatch]);

  return (
    <FormControl component={'fieldset'}>
      <FormLabel component={'legend'}>Options</FormLabel>
      <RadioGroup value={status} onChange={(e) => handleStatusChange(e.target.value)}>
        <FormControlLabel value={REQUESTED} control={<Radio color={'primary'} />} label={'Requested'} />
        <FormControlLabel value={ACCEPTED} control={<Radio color={'primary'} />} label={'Accepted'} />
      </RadioGroup>
    </FormControl>
  );
};

const StoreFilterOption = () => {
  const Bookings = useSelector((state) => state.AdminPortal.bookings.calendarBookings.list);
  const dispatch = useDispatch();
  const handleStoreChange = useCallback((Store) => {
    if (!Store) {
      dispatch(setCalendarFilteredBookings(Bookings));
      return;
    }
    const filteredBookings = Bookings.filter((br) => get(br, 'Store._id', '-') === get(Store, '_id'));
    dispatch(setCalendarFilteredBookings(filteredBookings));
  }, [Bookings, dispatch]);
  return <StoreAutoComplete size={'small'} onChange={(e, Store) => handleStoreChange(Store)} />;
};

const StaffFilterOption = () => {
  const Bookings = useSelector((state) => state.AdminPortal.bookings.calendarBookings.list);
  const dispatch = useDispatch();
  const handleStaffChange = useCallback((StaffMember) => {
    if (!StaffMember) {
      dispatch(setCalendarFilteredBookings(Bookings));
      return;
    }
    const filteredBookings = Bookings.filter((br) => get(br, 'StaffMember._id', '-') === get(StaffMember, '_id'));
    dispatch(setCalendarFilteredBookings(filteredBookings));
  }, [Bookings, dispatch]);
  return <RenderStaffAutosuggest size={'small'} onChange={(e, StaffMember) => handleStaffChange(StaffMember)} />;
};

const ActiveFilterOptions = ({ activeFilterOption }) => {
  const renderFilterOption = useCallback(() => {
    switch (activeFilterOption) {
      case 'status':
        return <StatusFilterOption />;

      case 'store':
        return <StoreFilterOption />;

      case 'staff_member':
        return <StaffFilterOption />;

      default:
        return <></>;
    }
  }, [activeFilterOption]);
  return (
    <>
      { renderFilterOption() }
    </>
  );
};

export default ActiveFilterOptions;
