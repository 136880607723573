import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Edit2 } from 'react-feather';
import { get } from 'lodash';
import { openCustomerModal } from '../../../store/actions/modals';
import { SET_ACTIVE_CUSTOMER } from '../../../store/actions/AdminPortal/customer';
import { getBooking } from '../../../store/actions/AdminPortal/booking';

const styles = (theme) => ({
  heading: {
    textTransform: 'uppercase',
    color: theme.colors.darkGrey,
  },
});

function CustomerPanel({ Customer, bookingId }) {
  const dispatch = useDispatch();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const handleEditClick = useCallback(() => {
    if (!Customer || !Customer._id) {
      return;
    }
    dispatch({ type: SET_ACTIVE_CUSTOMER, payload: Customer });
    dispatch(openCustomerModal(Customer._id, () => dispatch(getBooking(bookingId))));
  }, [Customer, dispatch, bookingId]);

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Grid container spacing={2} direction={'column'}>
            <Grid item justify={'space-between'} alignItems={'center'}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    variant={'body2'}
                    className={classes.heading}
                    gutterBottom
                  >
                    Customer
                  </Typography>
                  <Typography variant={'h5'}>
                    { get(Customer, 'firstName', '') }
                    { ' ' }
                    { get(Customer, 'lastName', '') }
                  </Typography>
                </Grid>

              </Grid>
            </Grid>
            <Grid item>
              { !Customer || !Customer.email
                ? <Typography variant={'body1'}>No email provided</Typography>
                : (
                  <Typography variant={'body1'}>
                    { get(Customer, 'email', '') }
                  </Typography>
                )}
              { !Customer || !Customer.phone
                ? <Typography variant={'body1'}>No phone provided</Typography>
                : (
                  <Typography variant={'body1'}>
                    { get(Customer, 'phone', '') }
                  </Typography>
                )}
            </Grid>
          </Grid>

        </Grid>
        <Grid item>
          <IconButton onClick={handleEditClick} color={'primary'}>
            <Edit2 size={16} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerPanel;
