import React, { Component } from 'react';
import {
  Button, Grid, IconButton, Paper, withStyles, Chip,
} from '@material-ui/core';
import { Add as PlusIcon, Delete } from '@material-ui/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LurePageHeading, LureTable, LureTheme } from '@lureapps/lure-ui';
import { get } from 'lodash';
import { openStaffModal as _openStaffModal } from '../../store/actions/modals';
import {
  clearActiveStaffMember as _clearActiveStaffMember,
  deleteStaffMember as _deleteStaffMember,
  getStaff as _getStaff,
  setActiveStaffMember as _setActiveStaffMember,
} from '../../store/actions/AdminPortal/staff';

const styles = (theme) => ({
  pageHeading: {
    marginBottom: theme.spacing(3),
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
});

class Staff extends Component {
  columns = [{
    title: 'Name',
    render: (StaffMember) => `${get(StaffMember, 'firstName', '')} ${get(StaffMember, 'lastName', '')}`,
  }, {
    title: 'Phone',
    field: 'phone',
    render: (StaffMember) => (get(StaffMember, 'phone')
      ? get(StaffMember, 'phone')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No phone provided</span>),
  }, {
    title: 'Email',
    field: 'email',
    render: (StaffMember) => (get(StaffMember, 'email')
      ? get(StaffMember, 'email')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No email provided</span>),
  }, {
    title: 'Primary Store',
    field: 'primaryStore.name',
    render: (StaffMember) => (get(StaffMember, 'primaryStore.name', '')
      ? get(StaffMember, 'primaryStore.name')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No store provided</span>),
  }, {
    title: 'Last contact date',
    field: 'contactDate',
    render: (customer) => moment(customer.contactDate).format('Do MMM YYYY'),
  }, {
    title: 'Actions',
    render: (StaffMember) => this.renderActionButtons(StaffMember),
  }];

  componentDidMount() {
    const { getStaff } = this.props;
    getStaff();
  }

  renderActionButtons = (StaffMember) => (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <Chip
            label={'Edit'}
            color={'pink'}
            onClick={(e) => this.handleEditClick(e, StaffMember)}
          />
        </Grid>
        <Grid item>
          <IconButton
            variant={'text'}
            color={'primary'}
            size={'small'}
            onClick={(e) => this.handleDeleteClick(e, StaffMember._id)}
          >
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )

  handleEditClick = (e, staffMemberInfo) => {
    const { setActiveStaffMember, openStaffModal } = this.props;
    e.stopPropagation();
    setActiveStaffMember(staffMemberInfo);
    openStaffModal();
  }

  handleDeleteClick = (e, _id) => {
    const { deleteStaffMember } = this.props;
    e.stopPropagation();
    deleteStaffMember(_id);
  }

  handleAddStaffMemberClick = () => {
    const { clearActiveStaffMember, openStaffModal } = this.props;
    clearActiveStaffMember();
    openStaffModal();
  }

  render() {
    const {
      classes, history, staffList,
    } = this.props;
    return (
      <Grid container direction={'column'}>
        <LurePageHeading
          title={'Staff'}
          action={(
            <Button variant={'contained'} color={'primary'} onClick={this.handleAddStaffMemberClick}>
              <PlusIcon className={classes.addIcon} fontSize={'small'} />
              Add Staff Member
            </Button>
          )}
        />
        <Grid item>
          <Paper>
            <LureTable
              columns={this.columns}
              data={staffList}
              onRowClick={(e, StaffMember) => history.push(`/app/staff/${StaffMember._id}`)}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    staffList: state.AdminPortal.staff.staffList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openStaffModal: () => dispatch(_openStaffModal()),
    getStaff: () => dispatch(_getStaff()),
    setActiveStaffMember: (staffMemberInfo) => dispatch(_setActiveStaffMember(staffMemberInfo)),
    clearActiveStaffMember: () => dispatch(_clearActiveStaffMember()),
    deleteStaffMember: (_id) => dispatch(_deleteStaffMember(_id)),
  };
}

Staff = connect(mapStateToProps, mapDispatchToProps)(Staff);
Staff = withStyles(styles)(Staff);
Staff = withRouter(Staff);

export default Staff;
