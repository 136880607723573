import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { LureBookingsMenu, LureIcon } from '@lureapps/lure-ui';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BookingService from '../../../services/BookingService';

const styles = (theme) => ({
  bottomBuffer: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
});

const StoreBookings = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { storeId } = useParams();
  const history = useHistory();
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [historicBookings, setHistoricBookings] = useState([]);

  useEffect(() => {
    BookingService.getBookingTimeline(storeId, 'Store')
      .then(({
        data:
        {
          bookingTimeline:
          {
            upcomingBookings: upcoming,
            historicBookings: historic,
          },
        },
      }) => {
        setUpcomingBookings(upcoming);
        setHistoricBookings(historic);
      });
  }, [storeId]);

  return (
    <>
      <Grid container justify={'space-between'} alignItems={'center'}>
        <Button
          fullWidth
          variant={'contained'}
          color={'primary'}
          className={classes.bottomBuffer}
        >
          <LureIcon name={'plus'} className={classes.margin} />
          Add New Booking
        </Button>
      </Grid>
      <LureBookingsMenu
        upcomingBookings={upcomingBookings}
        historicBookings={historicBookings}
        onBookingClick={(booking) => history.push(`/app/bookings/requests/${booking._id}`)}
        entity={'Store'}
      />
    </>
  );
};

export default StoreBookings;
