// Pre Auth
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const ACCEPT_INVITE_ROUTE = '/invite';

// Private
export const DASHBOARD_ROUTE = '/dashboard';
export const BOOKING_CALENDAR_ROUTE = '/bookings/calendar';
export const BOOKING_REQUEST_ROUTE = '/bookings/request';
export const BOOKINGS_ROUTE = '/bookings';
export const BOOKING_DETAIL_ROUTE = '/bookings/requests/:bookingId';
export const CUSTOMERS_ROUTE = '/customers';
export const CUSTOMER_DETAIL_ROUTE = '/customers/:_id';
export const STORES_ROUTE = '/stores';
export const STORE_DETAIL_ROUTE = '/stores/:storeId';
export const STAFF_ROUTE = '/staff';
export const STAFF_DETAIL_ROUTE = '/staff/:staffId';
export const SETTINGS_ROUTE = '/settings';
export const CHAT_ROUTE = '/chat';
export const MARKETPLACE_ROUTE = '/marketplace';
