import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import {
  LureDialog,
  LureDialogActions,
  LureDialogContent,
  LureDialogTitle,
} from '@lureapps/lure-ui';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import numeral from 'numeral';
import { closeBookingCompleteModal, closeConfirmModal } from '../../../store/actions/modals';
import ProductList from './ProductList';
import { renderTextField } from '../../shared/formHelpers/fields';
import BookingService from '../../../services/BookingService';
import { snackError, snackSuccess } from '../../../store/actions/snackbar';
import { getBooking } from '../../../store/actions/AdminPortal/booking';

const styles = (theme) => ({
  alert: {
    borderRadius: '5px',
  },
  muted: {
    color: theme.colors.darkGrey,
  },
});

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const { isOpen, Booking } = useSelector((state) => state.modals.bookingCompleteModal);
  const [purchaseTotal, setPurchaseTotal] = useState(0.00);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const completeBooking = ({ BookingProducts, message = '' }) => {
    const purchasedProducts = BookingProducts.nodes.filter((Product) => Product.purchased);
    const purchasedProductIds = purchasedProducts.map((purchasedProduct) => purchasedProduct._id);
    const completeBookingInput = {
      purchasedProducts: purchasedProductIds,
      message,
      purchaseTotal,
    };

    BookingService.completeBooking(Booking._id, completeBookingInput)
      .then(() => {
        dispatch(snackSuccess('Booking Completed'));
        dispatch(getBooking(Booking._id));
        dispatch(closeBookingCompleteModal());
      })
      .catch(() => dispatch(snackError('Error while completing Booking')));
  };

  const updatePurchaseTotal = useCallback((BookingProducts) => {
    if (!BookingProducts) return 0.00;
    let total = 0.00;
    BookingProducts.forEach((BookingProduct) => {
      if (BookingProduct.purchased) {
        total += BookingProduct.Product.price;
      }
    });
    return setPurchaseTotal(total);
  });

  return (
    <LureDialog
      open={isOpen}
      onClose={() => dispatch(closeConfirmModal())}
      maxWidth={'md'}
      fullWidth
      disableBackdropClick
    >
      <LureDialogTitle>
        Complete Booking
      </LureDialogTitle>
      <Form
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={completeBooking}
        initialValues={Booking}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <LureDialogContent>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Typography variant={'h6'} gutterBottom>Purchased Items</Typography>
                  <Typography
                    gutterBottom
                    className={classes.muted}
                    variant={'body1'}
                  >
                    Please select which items the Customer purchased
                  </Typography>
                  <ProductList
                    Booking={Booking}
                    form={form}
                    updatePurchaseTotal={updatePurchaseTotal}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom variant={'h6'}>
                    TOTAL
                  </Typography>
                  <Typography
                    gutterBottom
                    className={classes.muted}
                    variant={'body1'}
                  >
                    Purchased items total
                  </Typography>
                  <Typography variant={'h3'}>
                    {`${numeral(purchaseTotal).format('$0,0.00')}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={'h6'}
                    className={classes.notesPadding}
                    gutterBottom
                  >
                    Notes
                  </Typography>
                  <Field
                    fullWidth
                    name={'message'}
                    placeholder={'Anything related that may need recording...'}
                    component={renderTextField}
                    multiline
                    variant={'outlined'}
                    rows={4}
                  />
                </Grid>
              </Grid>
            </LureDialogContent>
            <LureDialogActions>
              <Grid container spacing={3} justify={'space-between'}>
                <Grid item xs={3} className={classes.rightBuffer}>
                  <Button
                    fullWidth
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() => dispatch(closeBookingCompleteModal())}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={3} className={classes.leftBuffer}>
                  <Button
                    fullWidth
                    type={'submit'}
                    color={'primary'}
                    variant={'contained'}
                  >
                    Complete Booking
                  </Button>
                </Grid>
              </Grid>
            </LureDialogActions>
          </form>
        )}
      />
    </LureDialog>
  );
};

export default ConfirmModal;
