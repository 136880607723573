/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import {
  KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import useMomentTz from '../../../hooks/useMomentTz';

function KeyboardDateTimePicker({ input: { value, onChange }, ...rest }) {
  const [date, setDate] = useState(null);
  const momentTz = useMomentTz();

  useEffect(() => {
    if (momentTz(value, 'D MMM Y h:mm a').isValid()) {
      setDate(momentTz(value, 'D MMM Y h:mm a'));
    }
  }, [value]);

  const handleChange = useCallback((e, inputValue) => {
    const currentTime = moment();
    if (!moment(inputValue).isBefore(currentTime)) {
      onChange(inputValue);
    }
  }, [onChange]);

  return (
    <MuiKeyboardDateTimePicker
      variant={'inline'}
      disablePast
      ampm
      format={'D MMM Y h:mm a'}
      {...rest}
      value={date}
      onChange={handleChange}
    />
  );
}

export default KeyboardDateTimePicker;
