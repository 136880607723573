import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import ProductPlaceholder from '../../../assets/images/product_placeholder.jpg';

const styles = (theme) => ({
  image: {
    maxHeight: theme.spacing(10),
    borderRadius: '50%',
  },
  bold: {
    fontWeight: 700,
  },
});

const ProductCard = ({ product }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const title = get(product, 'Product.title', '');
  const displayName = get(product, 'Product.formattedName', '');
  const price = get(product, 'Product.formattedPrice', '0.0');
  const image = get(product, 'Product.image', '');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <img className={classes.image} src={image || ProductPlaceholder} alt={'Product'} />
        </Grid>
        <Grid item container direction={'column'} justify={'center'} xs>
          <Typography variant={'body1'} className={classes.bold}>{ title }</Typography>
          <Typography variant={'body2'}>
            { displayName }
            { ' ' }
            -
            { ' ' }
            { `${price}` }
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductCard;
