import React, { Component } from 'react';
import {
  Grid, IconButton, Typography, withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { LureIcon } from '@lureapps/lure-ui';
import { getCustomer as _getCustomer } from '../../../store/actions/AdminPortal/customer';
import { openCustomerModal as _openCustomerModal } from '../../../store/actions/modals';

const styles = (theme) => ({
  bottomBuffer: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.colors.darkGrey,
  },
});

class Information extends Component {
  handleEditClick = () => {
    const { openCustomerModal } = this.props;
    openCustomerModal(true);
  };

  render() {
    const { classes, customer } = this.props;
    return (
      <Grid container direction={'column'}>
        <Grid item container justify={'space-between'} alignItems={'center'} className={classes.bottomBuffer}>
          <Typography variant={'h4'}>{get(customer, 'fullName')}</Typography>
          <IconButton onClick={this.handleEditClick} color={'primary'}>
            <LureIcon name={'edit-2'} color={'primary'} />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant={'body1'}>{get(customer, 'email', '-')}</Typography>
        </Grid>
        <Grid item>
          <Typography>{get(customer, 'phone', '-')}</Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = function mapStateToProps(state) {
  return {
    loading: state.AdminPortal.customer.activeLoading,
    customer: state.AdminPortal.customer.active,
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getCustomer: (_id) => dispatch(_getCustomer(_id)),
    openCustomerModal: (isEdit) => dispatch(_openCustomerModal(isEdit)),
  };
};

Information = withStyles(styles)(Information);
Information = connect(mapStateToProps, mapDispatchToProps)(Information);

export default Information;
