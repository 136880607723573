import StoreService from '../../../services/StoreService';
import { snackError, snackSuccess } from '../snackbar';

export const SET_STORES = 'SET_STORES';
export const SET_ACTIVE_STORE = 'SET_ACTIVE_STORE';
export const CLEAR_ACTIVE_STORE = 'CLEAR_ACTIVE_STORE';
export const SET_STORE_SUGGESTIONS = 'SET_STORE_SUGGESTIONS';
export const CLEAR_STORE_SUGGESTIONS = 'CLEAR_STORE_SUGGESTIONS';
export const RESET_STORES = 'RESET_STORES';

export function getStores() {
  return (dispatch) => {
    StoreService.getStores()
      .then(({ data: { getStores: storeData } }) => {
        dispatch({
          type: SET_STORES,
          payload: storeData,
        });
      });
  };
}

export function getStore(_id) {
  return (dispatch) => {
    StoreService.getStore(_id)
      .then(({ data: { getStore: storeData } }) => {
        dispatch({
          type: SET_ACTIVE_STORE,
          payload: storeData,
        });
      });
  };
}

export function createStore(storeData) {
  return (dispatch) => StoreService.createStore(storeData)
    .then(() => {
      dispatch(snackSuccess('Store created successfully.'));
      dispatch(getStores());
    });
}

export function updateStore(_id, storeData, cb) {
  return (dispatch) => StoreService.updateStore(_id, storeData)
    .then(({ data: { updateStore: updatedStore } }) => {
      dispatch(snackSuccess('Store updated successfully.'));
      dispatch(getStores());
      dispatch({
        type: SET_ACTIVE_STORE,
        payload: updatedStore,
      });
      if (cb) {
        cb(updatedStore);
      }
    });
}

export function deleteStore(_id) {
  return (dispatch) => {
    StoreService.deleteStore(_id)
      .then(() => {
        dispatch(snackSuccess('Store deleted successfully.'));
        dispatch(getStores());
      })
      .catch(() => {
        dispatch(snackError('Error while deleting Store'));
      });
  };
}

export function searchStores(searchQuery) {
  return (dispatch) => {
    StoreService.searchStores(searchQuery)
      .then(({ data: { searchStores: storeData } }) => {
        dispatch({
          type: SET_STORE_SUGGESTIONS,
          payload: storeData,
        });
      })
      .catch(() => {
        dispatch(snackError('Error while searching Stores...'));
      });
  };
}

export function clearStoreSuggestions() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STORE_SUGGESTIONS,
    });
  };
}

export function setActiveStore(storeData) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_STORE,
      payload: storeData,
    });
  };
}

export function clearActiveStore() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ACTIVE_STORE,
    });
  };
}
