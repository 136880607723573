import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LureTheme, LureBusiness } from '@lureapps/lure-ui';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ApolloProvider } from '@apollo/react-hooks';
import SnackbarLauncher from './components/snackbar/SnackbarLauncher';
import LureRoutes from './components/routing/LureRoutes';
import { CUSTOMER } from './constants/userRoles';
import { client } from './services/GraphQLService';
import UserRegistrationModal from './components/modals/UserRegistrationModal/UserRegistrationModal';

function App() {
  const user = useSelector((state) => state.auth.user);
  let theme = LureTheme;
  if (user && user.role !== CUSTOMER) {
    theme = LureBusiness;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <LureRoutes />
          </Router>
          <SnackbarLauncher />
          <UserRegistrationModal />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
