import {
  Route, Switch, useLocation, Redirect, useRouteMatch,
} from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Main from '../../containers/Main';
import PreAuthRoutes from './PreAuth/PreAuthRoutes';
import { setAuthUser } from '../../store/actions/auth';
import useFirebase from '../../hooks/useFirebase';
import { COMPANY_ROLES, CUSTOMER_ROLES } from '../../constants/userRoles';
import { DASHBOARD_ROUTE } from '../../constants/routes';
import userHasAllFields from '../../services/FirebaseService';

function LureRoutes() {
  const { user, userIsLoggedIn } = useSelector((state) => state.auth);
  const AUTH_ROLES = [].concat(COMPANY_ROLES, CUSTOMER_ROLES);
  const dispatch = useDispatch();

  const { state } = useLocation();
  const { path } = useRouteMatch();
  const referrer = get(state, 'referrer', `${path}${DASHBOARD_ROUTE}`);

  const {
    user: firebaseUser,
    loading: firebaseLoading,
  } = useFirebase();

  useEffect(() => {
    if (userHasAllFields(firebaseUser)) {
      dispatch(setAuthUser());
    }
  }, [firebaseUser, dispatch]);

  return (
    <>
      <Switch>
        {user && AUTH_ROLES.includes(user.role) && <Route path={'/app'} component={Main} />}
        {!(firebaseUser || firebaseLoading || userIsLoggedIn) && <PreAuthRoutes />}
        <Redirect
          from={'/login'}
          exact
          to={{
            pathname: '/app',
            state: { referrer },
          }}
        />
        <Redirect
          from={'/register'}
          exact
          to={{
            pathname: '/app',
          }}
        />
        {!firebaseLoading && <Redirect to={'/app'} />}
      </Switch>
    </>
  );
}

export default LureRoutes;
