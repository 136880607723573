import React, { useCallback, useState } from 'react';
import {
  Button, Grid, Link as MuiLink, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LOGIN_ROUTE } from '../../constants/routes';
import { renderLureTextField } from '../shared/formHelpers/fields';
import PreAuthFooter from './shared/PreAuthFooter';
import { snackError as _snackError, snackSuccess as _snackSuccess } from '../../store/actions/snackbar';
import useFirebase from '../../hooks/useFirebase';

const styles = (theme) => ({
  inputContainer: {
    marginBottom: theme.spacing(1.5),
  },
  containerPadding: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  bottomBuffer: {
    marginBottom: theme.spacing(5),
  },
});

const ForgotPassword = () => {
  const [submitting, setSubmitting] = useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const { auth } = useFirebase();
  const dispatch = useDispatch();

  let handlePasswordReset = useCallback(async (data) => {
    try {
      setSubmitting(true);
      await auth.sendPasswordResetEmail(data.email);
      dispatch(_snackSuccess('Password reset successfully.'));
      history.push(LOGIN_ROUTE);
    } catch (e) {
      dispatch(_snackError('Reset password failed. Please try again'));
    } finally {
      setSubmitting(false);
    }
  }, [auth, dispatch, history]);

  return (
    <>
      <Form
        onSubmit={handlePasswordReset}
        render={({ handleSubmit }) => {
          handlePasswordReset = handleSubmit;
          return (
            <Grid container direction={'column'}>
              <Grid item container className={classes.containerPadding} justify={'center'}>
                <Grid item xs={7}>
                  <Typography align={'center'} variant={'h4'} gutterBottom>Reset your password</Typography>
                  <Typography align={'center'} variant={'body1'}>
                    Your email will be sent instructions on how to reset your
                    password.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container justify={'center'} spacing={3}>
                <Grid item xs={8}>
                  <form onSubmit={handleSubmit} autoComplete={'off'}>
                    <Field
                      fullWidth
                      name={'email'}
                      label={'Email'}
                      type={'text'}
                      placeholder={'Enter your email'}
                      className={classes.inputContainer}
                      component={renderLureTextField}
                    />
                  </form>
                </Grid>
                <Grid item container xs={8} className={classes.bottomBuffer}>
                  <Button
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    onClick={((event) => handlePasswordReset(event))}
                  >
                    { submitting ? <CircularProgress size={20} className={classes.loader} /> : 'Continue' }
                  </Button>
                </Grid>
              </Grid>
              <PreAuthFooter>
                <Typography variant={'body1'}>
                  Return to
                  { ' ' }
                  <MuiLink style={{ cursor: 'pointer' }} onClick={() => history.push(LOGIN_ROUTE)}>Login</MuiLink>
                </Typography>
              </PreAuthFooter>
            </Grid>
          );
        }}
      />
    </>
  );
};

export default ForgotPassword;
