import React from 'react';
import { Typography } from '@material-ui/core';
import { LurePaper } from '@lureapps/lure-ui';
import { get } from 'lodash';

const CompanyCard = ({ Company }) => (
  <>
    <LurePaper>
      <Typography variant={'h4'} gutterBottom>
        {get(Company, 'name')}
      </Typography>
      <Typography variant={'subtitle1'}>
        {get(Company, 'email')}
      </Typography>
      <Typography variant={'subtitle1'}>
        {get(Company, 'phone')}
      </Typography>
      <Typography variant={'subtitle1'}>
        {get(Company, 'website')}
      </Typography>
    </LurePaper>
  </>
);

export default CompanyCard;
