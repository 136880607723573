import React from 'react';
import { LureMap } from '@lureapps/lure-ui';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const StoresMap = () => {
  const Stores = useSelector((state) => state.AdminPortal.store.stores);
  const markers = Stores.map((store) => ({
    lat: get(store, 'address.lat'),
    lng: get(store, 'address.lng'),
  }));

  return (
    <LureMap
      markers={markers}
    />
  );
};

export default StoresMap;
