import React from 'react';
import { LurePageHeading, LurePaper } from '@lureapps/lure-ui';
import Grid from '@material-ui/core/Grid';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import CustomerActivity from './Activity/CustomerActivity';

function CustomerDashboard() {
  return (
    <>
      <LurePageHeading title={'Dashboard'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <LurePaper>
            <CustomerDetails />
          </LurePaper>
        </Grid>
        <Grid item xs={12} md={9}>
          <CustomerActivity />
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerDashboard;
