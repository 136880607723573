import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  signUpContainer: {
    backgroundColor: theme.colors.mediumGrey,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
});

const PreAuthFooter = ({ children }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid item container className={classes.signUpContainer} justify={'center'}>
        { children }
      </Grid>
    </>
  );
};

export default PreAuthFooter;
