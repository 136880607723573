import React from 'react';
import {
  Route, Redirect, useLocation, Switch,
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { LurePaper } from '@lureapps/lure-ui';
import { makeStyles } from '@material-ui/core/styles';
import {
  ACCEPT_INVITE_ROUTE,
  FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE,
} from '../../../constants/routes';
import Login from '../../preAuth/Login';
import Register from '../../preAuth/Register/Register';
import ForgotPassword from '../../preAuth/ForgotPassword';
import AcceptInvite from '../../preAuth/Invite';

const styles = (theme) => ({
  container: {
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    overflow: 'scroll',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  preAuthPaper: {
    boxShadow: '0px 3px 4px 0px rgba(0,0,0,0.14)',
  },
  socialSignin: {
    padding: theme.spacing(3, 0, 0, 3),
  },
});


function PreAuthRoutes() {
  const { pathname } = useLocation();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid container justify={'center'} alignItems={'center'} className={classes.container}>
        <Grid item xs={10} md={6} lg={4}>
          <LurePaper className={classes.preAuthPaper}>
            <Switch>
              <Route path={ACCEPT_INVITE_ROUTE} component={AcceptInvite} />
              <Route path={LOGIN_ROUTE} component={Login} />
              <Route path={REGISTER_ROUTE} component={Register} />
              <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
              <Redirect
                to={{
                  pathname: LOGIN_ROUTE,
                  state: { referrer: pathname },
                }}
              />
            </Switch>
          </LurePaper>
        </Grid>
      </Grid>
    </>
  );
}

export default PreAuthRoutes;
