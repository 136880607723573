import React, { useCallback } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { LureIcon, LureNavListItem } from '@lureapps/lure-ui';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { logout as _logout } from '../store/actions/auth';
import { useStyle } from './AdminNavDrawer';

import {
  BOOKINGS_ROUTE,
  DASHBOARD_ROUTE, MARKETPLACE_ROUTE,
  SETTINGS_ROUTE,
} from '../constants/routes';

function CustomerNavDrawer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const classes = useStyle();


  const logout = useCallback(async () => {
    dispatch(_logout());
  }, [dispatch]);

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant={'permanent'}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classNames(classes.toolbar, classes.toolbarPadding)}>
          <div className={classes.sideNavLogo} />
        </div>
        <List className={classes.list}>
          <LureNavListItem
            title={'Dashboard'}
            icon={<LureIcon size={'small'} name={'grid'} />}
            active={pathname.includes(DASHBOARD_ROUTE)}
            onClick={() => history.push(path + DASHBOARD_ROUTE)}
          />
          <LureNavListItem
            title={'Bookings'}
            icon={<LureIcon size={'small'} name={'bookmark'} />}
            active={pathname.includes(BOOKINGS_ROUTE)}
            onClick={() => history.push(path + BOOKINGS_ROUTE)}
          />
          <LureNavListItem
            title={'Marketplace'}
            icon={<LureIcon size={'small'} name={'shopping-bag'} />}
            active={pathname.includes(MARKETPLACE_ROUTE)}
            onClick={() => history.push(path + MARKETPLACE_ROUTE)}
          />
          <Divider className={classes.divider} />
          <LureNavListItem
            title={'Settings'}
            icon={<LureIcon size={'small'} name={'settings'} />}
            active={pathname.includes(SETTINGS_ROUTE)}
            onClick={() => history.push(path + SETTINGS_ROUTE)}
          />
          <LureNavListItem
            title={'Logout'}
            icon={<LureIcon size={'small'} name={'log-out'} />}
            onClick={() => logout()}
          />
        </List>
      </Drawer>
    </>
  );
}

export default CustomerNavDrawer;
