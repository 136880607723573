import React, { useEffect, useState } from 'react';
import { LureChip, LureTable } from '@lureapps/lure-ui';
import moment from 'moment';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';


function AdminActivity({ bookings }) {
  const activityColumns = [{
    title: 'When',
    render: (row) => moment(row.date, 'x').calendar(),
  }, {
    title: 'Activity Type',
    field: 'type',
    render: (row) => <LureChip label={`${row.type}`} />,
  }, {
    title: 'Activity details',
    field: 'details',
  }];

  const [activity, setActivity] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const bookingActivity = bookings
      .sort((a, b) => moment(b.createdAt) - moment(a.createdAt)).slice(0, 5)
      .map((booking) => ({
        _id: booking._id,
        date: booking.createdAt,
        type: 'Made a booking',
        details: `${get(booking, 'Customer.fullName', 'Customer.firstName')} made a booking ${get(booking, 'bookingReference')} at ${get(booking, 'Store.name')}`,
      }));

    setActivity(bookingActivity);
  }, [bookings, setActivity]);

  return (
    <LureTable
      columns={activityColumns}
      data={activity}
      title={'Activity'}
      options={{ pageSize: 5, showTitle: true, toolbar: true }}
      onRowClick={(e, booking) => history.push(`/app/bookings/requests/${booking._id}`)}
    />
  );
}

export default AdminActivity;
