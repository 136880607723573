import React, { useCallback } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  LureDialog,
  LureDialogActions,
  LureDialogContent,
  LureDialogTitle,
} from '@lureapps/lure-ui';
import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';
import { closeRejectRequestModal } from '../../store/actions/modals';
import { renderLureTextField } from '../shared/formHelpers/fields';
import { updateBooking } from '../../store/actions/AdminPortal/booking';
import { REJECTED } from '../../constants/bookingStatuses';
import { snackError } from '../../store/actions/snackbar';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  leftBuffer: {
    paddingLeft: theme.spacing(5),
  },
  rightBuffer: {
    paddingRight: theme.spacing(5),
  },
});

const RejectRequestModal = () => {
  const dispatch = useDispatch();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const open = useSelector((state) => state.modals.rejectRequestModal.isOpen);
  const Booking = useSelector((state) => state.AdminPortal.bookings.activeBooking);

  const submit = useCallback(() => {
    dispatch(updateBooking(get(Booking, '_id'), { status: REJECTED }))
      .then(() => dispatch(closeRejectRequestModal()))
      .catch(() => dispatch(snackError('Error while rejecting request.')));
  }, [dispatch, Booking]);

  return (
    <LureDialog
      onClose={() => dispatch(closeRejectRequestModal())}
      open={open}
      maxWidth={'sm'}
      fullWidth
      disableBackdropClick
    >
      <LureDialogTitle onClose={() => dispatch(closeRejectRequestModal())}>
        Reject Request
      </LureDialogTitle>
      <Form
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <LureDialogContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Alert severity={'warning'} className={classes.alert} style={{ borderRadius: '5px' }}>
                    <AlertTitle>Warning</AlertTitle>
                    The Customer will be notified via email of this change
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={'reason'}
                    label={'Reason'}
                    placeholder={'E.g: Clashes with an existing booking'}
                    component={renderLureTextField}
                  />
                </Grid>
              </Grid>
            </LureDialogContent>
            <LureDialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.rightBuffer}>
                  <Button
                    fullWidth
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() => dispatch(closeRejectRequestModal())}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.leftBuffer}>
                  <Button
                    fullWidth
                    color={'primary'}
                    variant={'contained'}
                    type={'submit'}
                  >
                    Reject Request
                  </Button>
                </Grid>
              </Grid>
            </LureDialogActions>
          </form>
        )}
      />
    </LureDialog>
  );
};

export default RejectRequestModal;
