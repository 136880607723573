import BookingService from '../../../services/BookingService';
import { snackError } from '../snackbar';

export const SET_BOOKING = 'SET_BOOKING';

export function updateBookingTimeslot(bookingId, timeslot) {
  return (dispatch) => {
    BookingService.updateBookingTimeslot(bookingId, timeslot)
      .then(({ data: { updateBookingTimeslot: Booking } }) => {
        dispatch({
          type: SET_BOOKING,
          payload: Booking,
        });
      })
      .catch(() => {
        dispatch((snackError('Error while attempting to request booking time')));
      });
  };
}
