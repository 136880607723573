import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import ProductCard from './ProductCard';

const styles = (theme) => ({
  headingBlock: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
  },
});

const FittingRoomDetails = ({ Booking }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const BookingProducts = get(Booking, 'BookingProducts.nodes', []);

  return (
    <>
      <Grid item container xs={12} direction={'column'} spacing={3}>
        <Grid item>
          <Typography variant={'h6'} className={classes.headingBlock}>Fitting Room Details</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction={'column'}>
            { BookingProducts.map((BookingProduct) => (
              <Grid item key={BookingProduct._id}>
                <ProductCard product={BookingProduct} />
              </Grid>
            )) }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FittingRoomDetails;
