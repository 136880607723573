import React, { Component } from 'react';
import { Button, Grid, withStyles } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';

import CircularProgress from '@material-ui/core/CircularProgress';
import { customerAddNote as _customerAddNote } from '../../store/actions/AdminPortal/customer';
import { renderTextField } from '../shared/formHelpers/fields';
import { closeAddNoteModal as _closeAddNoteModal } from '../../store/actions/modals';
import { snackError as _snackError } from '../../store/actions/snackbar';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  leftBuffer: {
    paddingLeft: theme.spacing(5),
  },
  rightBuffer: {
    paddingRight: theme.spacing(5),
  },
  loader: {
    color: 'white',
  },
});

class AddNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  submit = (values) => {
    const {
      customer: { _id },
      addNote,
      closeModal,
      snackError,
    } = this.props;
    this.setState({ loading: true });

    addNote(_id, values)
      .then(() => closeModal())
      .catch(() => snackError('Error while adding a note.'))
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const {
      classes, closeModal, open,
    } = this.props;
    const { loading } = this.state;
    return (
      <LureDialog
        open={open}
        maxWidth={'sm'}
        fullWidth
        disableBackdropClick
      >
        <LureDialogTitle>
          Add Note
        </LureDialogTitle>
        <Form
          onSubmit={this.submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LureDialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name={'content'}
                      label={'Note'}
                      component={renderTextField}
                      multiline
                      variant={'outlined'}
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </LureDialogContent>
              <LureDialogActions>
                <Grid container>
                  <Grid item xs={6} className={classes.rightBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'outlined'}
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.leftBuffer}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'contained'}
                      type={'submit'}
                    >
                      {loading ? <CircularProgress size={20} className={classes.loader} /> : 'Add Note'}
                    </Button>
                  </Grid>
                </Grid>
              </LureDialogActions>
            </form>
          )}
        />
      </LureDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    open: state.modals.addNoteModal.isOpen,
    customer: state.AdminPortal.customer.active,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeAddNoteModal()),
    addNote: (_id, note, cb) => dispatch(_customerAddNote(_id, note, cb)),
    snackError: (e) => dispatch(_snackError(e)),
  };
}

AddNoteModal = withStyles(styles)(AddNoteModal);
AddNoteModal = connect(mapStateToProps, mapDispatchToProps)(AddNoteModal);


export default AddNoteModal;
