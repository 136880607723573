import StaffSvc from '../../../services/StaffService';
import { snackError, snackSuccess } from '../snackbar';

export const SET_STAFF = 'SET_STAFF';
export const SET_ACTIVE_STAFF_MEMBER = 'SET_ACTIVE_STAFF_MEMBER';
export const CLEAR_ACTIVE_STAFF_MEMBER = 'CLEAR_ACTIVE_STAFF_MEMBER';
export const RESET_STAFF = 'RESET_STAFF';

export function getStaff() {
  return (dispatch) => {
    StaffSvc.getStaff()
      .then(({ data: { getStaff: getStaffData } }) => {
        dispatch({
          type: SET_STAFF,
          payload: getStaffData,
        });
      });
  };
}

export function getStaffMember(_id) {
  return (dispatch) => {
    StaffSvc.getStaffMember(_id)
      .then(({ data: { getStaffMember: StaffMember } }) => {
        dispatch({
          type: SET_ACTIVE_STAFF_MEMBER,
          payload: StaffMember,
        });
      });
  };
}

export function createStaffMember(staffMemberData) {
  return (dispatch) => StaffSvc.createStaffMember(staffMemberData)
    .then(() => {
      dispatch(snackSuccess('Successfully created StaffMember'));
      dispatch(getStaff());
    });
}

export function setActiveStaffMember(staffMember) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_STAFF_MEMBER,
      payload: staffMember,
    });
  };
}

export function updateStaffMember(_id, staffMemberData) {
  return (dispatch) => StaffSvc.updateStaffMember(_id, staffMemberData)
    .then(({ data: { updateStaffMember: UpdatedStaffMember } }) => {
      dispatch(snackSuccess('Successfully updated StaffMember'));
      dispatch(getStaff());
      dispatch(setActiveStaffMember(UpdatedStaffMember));
    });
}

export function deleteStaffMember(staffMemberId) {
  return (dispatch) => {
    StaffSvc.deleteStaffMember(staffMemberId)
      .then(() => {
        dispatch(snackSuccess('Successfully deleted StaffMember'));
        dispatch(getStaff());
      })
      .catch(() => {
        dispatch(snackError('Error while deleting staff member'));
      });
  };
}


export function clearActiveStaffMember() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ACTIVE_STAFF_MEMBER,
    });
  };
}
