import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { Form } from 'react-final-form';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';
import { get } from 'lodash';
import { closeAcceptBookingModal } from '../../../store/actions/modals';
import CustomerDetails from './CustomerDetails';
import StoreDetails from './StoreDetails';
import FittingRoomDetails from './FittingRoomDetails';
import AllocateStaff from './AllocateStaff';
import { approveBooking } from '../../../store/actions/AdminPortal/booking';
import { snackError } from '../../../store/actions/snackbar';

const AcceptBookingModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.acceptBookingModal.isOpen);
  const Booking = useSelector((state) => state.modals.acceptBookingModal.Booking);
  const closeModal = useCallback(() => dispatch(closeAcceptBookingModal()), [dispatch]);

  const submit = useCallback(({ StaffMember }) => {
    dispatch(
      approveBooking(
        get(Booking, '_id', ''),
        get(Booking, 'timeslots[0]._id', ''),
        StaffMember._id,
        closeModal,
      ),
    )
      .then(() => dispatch(closeAcceptBookingModal()))
      .catch(() => dispatch(snackError('Unable to accept booking')));
  }, [Booking, dispatch, closeModal]);

  return (
    <>
      <LureDialog
        open={isOpen}
        maxWidth={'md'}
        fullWidth
        disableBackdropClick
      >
        <Form
          onSubmit={submit}
          initialValues={Booking}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LureDialogTitle>
                Accept Booking Request
              </LureDialogTitle>
              <LureDialogContent>
                <Grid container spacing={7}>
                  <CustomerDetails Booking={Booking} />
                  <StoreDetails Booking={Booking} />
                  <FittingRoomDetails Booking={Booking} />
                  <AllocateStaff Booking={Booking} />
                </Grid>
              </LureDialogContent>
              <LureDialogActions>
                <Grid container justify={'space-between'}>
                  <Grid item>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      type={'submit'}
                    >
                      Accept Booking
                    </Button>
                  </Grid>
                </Grid>
              </LureDialogActions>
            </form>
          )}
        />
      </LureDialog>
    </>
  );
};

export default AcceptBookingModal;
