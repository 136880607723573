export const OPEN_CUSTOMER_MODAL = 'OPEN_CUSTOMER_MODAL';
export const CLOSE_CUSTOMER_MODAL = 'CLOSE_CUSTOMER_MODAL';

export const OPEN_ADD_NOTE_MODAL = 'OPEN_ADD_NOTE_MODAL';
export const CLOSE_ADD_NOTE_MODAL = 'CLOSE_ADD_NOTE_MODAL';

export const OPEN_REGISTRATION_MODAL = 'OPEN_REGISTRATION_MODAL';
export const CLOSE_REGISTRATION_MODAL = 'CLOSE_REGISTRATION_MODAL';

export const OPEN_ADD_STAFF_MODAL = 'OPEN_ADD_STAFF_MODAL';
export const CLOSE_ADD_STAFF_MODAL = 'CLOSE_ADD_STAFF_MODAL';

export const OPEN_STORE_MODAL = 'OPEN_STORE_MODAL';
export const CLOSE_STORE_MODAL = 'CLOSE_STORE_MODAL';

export const OPEN_REJECT_REQUEST_MODAL = 'OPEN_REJECT_REQUEST_MODAL';
export const CLOSE_REJECT_REQUEST_MODAL = 'CLOSE_REJECT_REQUEST_MODAL';

export const OPEN_ACCEPT_BOOKING_MODAL = 'OPEN_ACCEPT_BOOKING_MODAL';
export const CLOSE_ACCEPT_BOOKING_MODAL = 'CLOSE_ACCEPT_BOOKING_MODAL';

export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';

export const OPEN_CHANGE_ACCOUNT_MODAL = 'OPEN_CHANGE_ACCOUNT_MODAL';
export const CLOSE_CHANGE_ACCOUNT_MODAL = 'CLOSE_CHANGE_ACCOUNT_MODAL';

export const OPEN_BOOKING_MODAL = 'OPEN_BOOKING_MODAL';
export const CLOSE_BOOKING_MODAL = 'CLOSE_BOOKING_MODAL';

export const OPEN_COMPANY_MODAL = 'OPEN_COMPANY_MODAL';
export const CLOSE_COMPANY_MODAL = 'CLOSE_COMPANY_MODAL';

export const OPEN_TRADING_HOURS_MODAL = 'OPEN_TRADING_HOURS_MODAL';
export const CLOSE_TRADING_HOURS_MODAL = 'CLOSE_TRADING_HOURS_MODAL';

export const OPEN_CUSTOMER_SETTINGS_MODAL = 'OPEN_CUSTOMER_SETTINGS_MODAL';
export const CLOSE_CUSTOMER_SETTINGS_MODAL = 'CLOSE_CUSTOMER_SETTINGS_MODAL';

export const OPEN_BOOKING_COMPLETE_MODAL = 'OPEN_BOOKING_COMPLETE_MODAL';
export const CLOSE_BOOKING_COMPLETE_MODAL = 'CLOSE_BOOKING_COMPLETE_MODAL';

export const OPEN_INSTALLATION_MODAL = 'OPEN_INSTALLATION_MODAL';
export const CLOSE_INSTALLATION_MODAL = 'CLOSE_INSTALLATION_MODAL';

export const OPEN_PRODUCT_TEST_MODAL = 'OPEN_PRODUCT_TEST_MODAL';
export const CLOSE_PRODUCT_TEST_MODAL = 'CLOSE_PRODUCT_TEST_MODAL';

export const OPEN_USER_REGISTRATION_MODAL = 'OPEN_USER_REGISTRATION_MODAL';
export const CLOSE_USER_REGISTRATION_MODAL = 'CLOSE_USER_REGISTRATION_MODAL';

export function openCustomerModal(customerId, cb) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CUSTOMER_MODAL,
      payload: {
        customerId,
        cb,
      },
    });
  };
}

export function closeCustomerModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_CUSTOMER_MODAL,
    });
  };
}

export function openAddNoteModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_ADD_NOTE_MODAL,
    });
  };
}

export function closeAddNoteModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ADD_NOTE_MODAL,
    });
  };
}

export function closeRegistrationModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_REGISTRATION_MODAL,
    });
  };
}

export function openStaffModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_ADD_STAFF_MODAL,
    });
  };
}

export function closeStaffModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ADD_STAFF_MODAL,
    });
  };
}

export function openStoreModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_STORE_MODAL,
    });
  };
}

export function closeStoreModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_STORE_MODAL,
    });
  };
}

export function openRejectRequestModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_REJECT_REQUEST_MODAL,
    });
  };
}

export function closeRejectRequestModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_REJECT_REQUEST_MODAL,
    });
  };
}

export function openAcceptBookingModal(Booking) {
  return (dispatch) => {
    dispatch({
      type: OPEN_ACCEPT_BOOKING_MODAL,
      payload: Booking,
    });
  };
}

export function closeAcceptBookingModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ACCEPT_BOOKING_MODAL,
    });
  };
}

export function openConfirmModal(options = {}) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CONFIRM_MODAL,
      payload: options,
    });
  };
}

export function closeConfirmModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_CONFIRM_MODAL,
    });
  };
}

export function openChangeAccountModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_CHANGE_ACCOUNT_MODAL,
    });
  };
}

export function closeChangeAccountModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_CHANGE_ACCOUNT_MODAL,
    });
  };
}

export function openBookingModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_BOOKING_MODAL,
    });
  };
}

export function closeBookingModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_BOOKING_MODAL,
    });
  };
}

export function openCompanyModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_COMPANY_MODAL,
    });
  };
}

export function closeCompanyModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_COMPANY_MODAL,
    });
  };
}

export function openTradingHourModal(Store) {
  return (dispatch) => {
    dispatch({
      type: OPEN_TRADING_HOURS_MODAL,
      payload: Store,
    });
  };
}

export function closeTradingHourModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_TRADING_HOURS_MODAL,
    });
  };
}

export function openCustomerSettingsModal(Customer) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CUSTOMER_SETTINGS_MODAL,
      payload: Customer,
    });
  };
}

export function closeCustomerSettingsModal(Customer) {
  return (dispatch) => {
    dispatch({
      type: CLOSE_CUSTOMER_SETTINGS_MODAL,
      payload: Customer,
    });
  };
}

export function openBookingCompleteModal(Booking) {
  return (dispatch) => {
    dispatch({
      type: OPEN_BOOKING_COMPLETE_MODAL,
      payload: Booking,
    });
  };
}

export function closeBookingCompleteModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_BOOKING_COMPLETE_MODAL,
    });
  };
}

export function openInstallationModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_INSTALLATION_MODAL,
    });
  };
}

export function closeInstallationModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_INSTALLATION_MODAL,
    });
  };
}

export function openProductTestModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_PRODUCT_TEST_MODAL,
    });
  };
}

export function closeProductTestModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_PRODUCT_TEST_MODAL,
    });
  };
}

export function openUserRegistrationModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_USER_REGISTRATION_MODAL,
    });
  };
}

export function closeUserRegistrationModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_USER_REGISTRATION_MODAL,
    });
  };
}
