import React from 'react';
import { LurePageHeading, LurePaper } from '@lureapps/lure-ui';
import {
  Grid, Typography, makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const styles = (theme) => ({
  subTitle: {
    color: theme.colors.darkGrey,
    textTransform: 'uppercase',
    fontSize: 10,
  },
  mutedText: {
    color: theme.colors.darkGrey,
  },
});

const CustomerSettings = () => {
  const Customer = useSelector((state) => state.auth.user.Customer);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const firstName = get(Customer, 'firstName', '');
  const lastName = get(Customer, 'lastName', '');
  const email = get(Customer, 'email', '');
  const phone = get(Customer, 'phone', '');

  return (
    <>
      <LurePageHeading title={'Settings'} />
      <Grid container>
        <Grid item xs={12} md={5}>
          <LurePaper>
            <Grid container spacing={2}>
              <Grid item container justify={'space-between'}>
                <Grid item>
                  <Typography variant={'h5'} gutterBottom>
                    My Information
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subTitle}>First name</Typography>
                <Typography>
                  {firstName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subTitle}>Last name</Typography>
                <Typography>
                  {lastName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subTitle}>Email</Typography>
                <Typography className={email ? '' : classes.mutedText}>
                  {email || 'No email provided'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subTitle}>Phone</Typography>
                <Typography className={phone ? '' : classes.mutedText}>
                  {phone || 'No phone provided'}
                </Typography>
              </Grid>
            </Grid>
          </LurePaper>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerSettings;
