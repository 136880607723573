import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Chip, makeStyles, Grid, Typography,
} from '@material-ui/core';
import { LureTable, LurePaper } from '@lureapps/lure-ui';
import moment from 'moment';
import { get, orderBy } from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useHistory } from 'react-router-dom';
import { Add as PlusIcon } from '@material-ui/icons';
import { openBookingModal } from '../../store/actions/modals';
import EmptyBookings from '../../assets/images/empty-bookings-state.svg';
import { ACCEPTED, REQUESTED } from '../../constants/bookingStatuses';

const styles = (theme) => ({
  chip: {
    backgroundColor: fade(theme.palette.secondary.main, 0.2),
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.3),
    },
  },
  muted: {
    color: theme.colors.darkGrey,
  },
  gradient: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, 138%, ${theme.palette.secondary.main} 200%)`,
  },
  emptyState: {
    maxWidth: theme.spacing(20),
  },
  bufferRight: {
    marginRight: theme.spacing(),
  },
});

const EmptyState = ({ classes }) => (
  <Grid container direction={'column'} spacing={3}>
    <Grid item>
      <img src={EmptyBookings} alt={'empty'} className={classes.emptyState} />
    </Grid>
    <Grid item>
      <Typography variant={'body1'}>No upcoming bookings</Typography>
    </Grid>
  </Grid>
);

const RecentBookings = () => {
  const Bookings = useSelector((state) => state.AdminPortal.bookings.bookingList);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [upcomingBookings, setUpcomingBookings] = useState([]);

  // Gets the next 5 upcoming bookings
  useEffect(() => {
    const bookings = [];
    const bookingsOrderedByTime = orderBy(Bookings, 'timeslots[0].timeslot', 'asc');


    bookingsOrderedByTime.forEach((booking) => {
      if (bookings.length >= 5) return;
      const bookingTime = moment(get(booking, 'timeslots[0].timeslot'), 'x');
      const now = moment();
      const bookingStatus = get(booking, 'status');
      if ((bookingTime.isAfter(now)) && (bookingStatus === ACCEPTED || REQUESTED)) {
        bookings.push(booking);
      }
    });

    setUpcomingBookings(bookings);
  }, [Bookings]);

  const columns = [{
    title: 'Status',
    field: 'status',
  }, {
    title: 'When',
    defaultSort: 'asc',
    render: (row) => moment(row.date, 'x').calendar(),
    field: 'date',
  }, {
    title: 'Customer',
    render: (row) => (
      get(row, 'Customer')
        ? get(row, 'Customer')
        : <span className={classes.muted}>No Customer on Booking</span>),
  }, {
    title: 'Store',
    render: (row) => (
      get(row, 'Store')
        ? get(row, 'Store')
        : <span className={classes.muted}>No Store on Booking</span>),
  }, {
    title: 'Actions',
    render: (row) => (
      <Chip
        label={'View Booking'}
        size={'medium'}
        className={classes.chip}
        onClick={() => history.push(`/app/bookings/requests/${row._id}`)}
      />
    ),
  }];

  return (
    <>
      <LurePaper noPadding>
        <LureTable
          title={'Upcoming Bookings'}
          columns={columns}
          data={upcomingBookings.map((Booking) => ({
            status: get(Booking, 'status'),
            _id: get(Booking, '_id'),
            date: get(Booking, 'timeslots[0].timeslot'),
            Customer: get(Booking, 'Customer.fullName'),
            Store: get(Booking, 'Store.name'),
          }))}
          options={{
            pageSize: 5,
            showTitle: true,
            toolbar: true,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: <EmptyState classes={classes} />,
            },
          }}
          action={(
            <Button
              variant={'contained'}
              color={'primary'}
              className={classes.gradient}
              onClick={() => dispatch(openBookingModal())}
            >
              <PlusIcon className={classes.bufferRight} fontSize={'small'} />
              Add Booking
            </Button>
          )}
        />
      </LurePaper>
    </>
  );
};

export default RecentBookings;
