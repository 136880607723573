import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import {
  LureDialog,
  LureDialogActions,
  LureDialogContent,
  LureDialogTitle,
} from '@lureapps/lure-ui';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { closeConfirmModal } from '../../store/actions/modals';

const styles = () => ({
  alert: {
    borderRadius: '5px',
  },
});

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const { isOpen, options } = useSelector((state) => state.modals.confirmModal);
  const {
    title,
    confirmationText,
    rejectButtonText,
    onReject,
    acceptButtonText,
    onAccept,
    alert,
  } = options;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <LureDialog
      open={isOpen}
      onClose={() => dispatch(closeConfirmModal())}
      maxWidth={'sm'}
      fullWidth
      disableBackdropClick
    >
      <LureDialogTitle>
        {title}
      </LureDialogTitle>
      <LureDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {alert && (
              <Alert severity={alert.severity} className={classes.alert}>
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.content}
              </Alert>
            )}
          </Grid>
          <Grid item>
            <Typography>{confirmationText}</Typography>
          </Grid>
        </Grid>
      </LureDialogContent>
      <LureDialogActions>
        <Grid container spacing={3}>
          <Grid item xs={6} className={classes.rightBuffer}>
            <Button
              fullWidth
              color={'primary'}
              variant={'outlined'}
              onClick={onReject}
            >
              {rejectButtonText}
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.leftBuffer}>
            <Button
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={onAccept}
            >
              {acceptButtonText}
            </Button>
          </Grid>
        </Grid>
      </LureDialogActions>
    </LureDialog>
  );
};

export default ConfirmModal;
