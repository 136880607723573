import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { LurePaper } from '@lureapps/lure-ui';
import moment from 'moment-timezone';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { CANCELLED, REQUESTED } from '../../constants/bookingStatuses';

const styles = (theme) => ({
  mutedText: {
    color: theme.colors.darkGrey,
    textTransform: 'uppercase',
  },
});

const BookingAnalytics = () => {
  const [bookingAnalytics, setBookingAnalytics] = useState({
    numBookings: 0,
    numRequested: 0,
    numCancelled: 0,
  });

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const Bookings = useSelector((state) => state.AdminPortal.bookings.bookingList);

  useEffect(() => {
    if (!Bookings) return;
    const thisWeek = moment().startOf('week');
    let numBookings = 0;
    let numRequested = 0;
    let numCancelled = 0;
    Bookings.forEach((booking) => {
      const dateOfBookingCreated = moment(booking.createdAt, 'x');
      if (dateOfBookingCreated.isAfter(thisWeek)) {
        numBookings += 1;
      }
      if (dateOfBookingCreated.isAfter(thisWeek) && booking.status === REQUESTED) {
        numRequested += 1;
      }
      if (dateOfBookingCreated.isAfter(thisWeek) && booking.status === CANCELLED) {
        numCancelled += 1;
      }
    });
    setBookingAnalytics({ numBookings, numRequested, numCancelled });
  }, [Bookings]);

  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <LurePaper shadow>
          <Typography variant={'h6'}>
            Total number of Bookings created
          </Typography>
          <Typography variant={'body1'} className={classes.mutedText}>
            This Week
          </Typography>
          <Typography variant={'h2'}>
            <CountUp
              end={bookingAnalytics.numBookings}
              duration={2}
            />
          </Typography>
        </LurePaper>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <LurePaper shadow>
          <Typography variant={'h6'}>
            Requested Bookings
          </Typography>
          <Typography variant={'body1'} className={classes.mutedText}>
            This Week
          </Typography>
          <Typography variant={'h2'}>
            <CountUp
              end={bookingAnalytics.numRequested}
              duration={2}
            />
          </Typography>
        </LurePaper>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <LurePaper shadow>
          <Typography variant={'h6'}>
            Cancelled Bookings
          </Typography>
          <Typography variant={'body1'} className={classes.mutedText}>
            This Week
          </Typography>
          <Typography variant={'h2'}>
            <CountUp
              end={bookingAnalytics.numCancelled}
              duration={2}
            />
          </Typography>
        </LurePaper>
      </Grid>
    </>
  );
};

export default BookingAnalytics;
