import React, { Component } from 'react';
import {
  Button, Grid, IconButton, Paper, withStyles, Chip,
} from '@material-ui/core';
import { Add as PlusIcon, Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LurePageHeading, LureTable, LureTheme } from '@lureapps/lure-ui';
import { get } from 'lodash';
import { openStoreModal as _openStoreModal } from '../../store/actions/modals';
import {
  clearActiveStore as _clearActiveStore,
  deleteStore as _deleteStore,
  getStores as _getStores,
  setActiveStore as _setActiveStore,
} from '../../store/actions/AdminPortal/store';

import StoresMap from './StoresMap/StoresMap';

const styles = (theme) => ({
  pageHeading: {
    marginBottom: theme.spacing(3),
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
});

function ActionButtons({ Store, handleEditClick, handleDeleteClick }) {
  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <Chip
            label={'Edit'}
            size={'large'}
            onClick={(e) => handleEditClick(e, Store)}
          />
        </Grid>
        <Grid item>
          <IconButton
            variant={'text'}
            color={'primary'}
            size={'small'}
            onClick={(e) => handleDeleteClick(e, Store._id)}
          >
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

class Stores extends Component {
  columns = [{
    title: 'Name',
    field: 'name',
    render: (Store) => get(Store, 'name', ''),
  }, {
    title: 'Phone',
    field: 'phone',
    render: (Store) => (get(Store, 'phone')
      ? get(Store, 'phone')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No phone provided</span>),
  }, {
    title: 'Email',
    field: 'email',
    render: (Store) => (get(Store, 'email')
      ? get(Store, 'email')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No email provided</span>),
  }, {
    title: 'Address',
    field: 'address.formatted',
  }, {
    title: 'Actions',
    render: (Store) => (
      <ActionButtons
        Store={Store}
        handleEditClick={
          this.handleEditClick
        }
        handleDeleteClick={this.handleDeleteClick}
      />
    ),
  }];

  async componentDidMount() {
    const { getStores } = this.props;
    await getStores();
  }

  handleRowClick = (e, Store) => {
    const { setActiveStore, history } = this.props;
    e.stopPropagation();
    setActiveStore(Store);
    history.push(`/app/stores/${Store._id}`);
  }

  handleEditClick = (e, storeData) => {
    const { setActiveStore, openAddStoreModal } = this.props;
    e.stopPropagation();
    setActiveStore(storeData);
    openAddStoreModal();
  }

  handleDeleteClick = (e, _id) => {
    const { deleteStore } = this.props;
    e.stopPropagation();
    deleteStore(_id);
  }

  handleAddStoreClick = () => {
    const { clearActiveStore, openAddStoreModal } = this.props;
    clearActiveStore();
    openAddStoreModal();
  }

  render() {
    const {
      classes,
      stores,
    } = this.props;
    return (
      <Grid container direction={'column'} spacing={3}>
        <LurePageHeading
          title={'Stores'}
          action={(
            <Button variant={'contained'} color={'primary'} onClick={this.handleAddStoreClick}>
              <PlusIcon className={classes.addIcon} fontSize={'small'} />
              Add Store
            </Button>
          )}
        />
        <Grid item xs={12} style={{ minWidth: '100%' }}>
          <StoresMap />
        </Grid>
        <Grid item>
          <Paper>
            <LureTable
              columns={this.columns}
              data={stores}
              options={{ pageSize: 5 }}
              onRowClick={(e, Store) => this.handleRowClick(e, Store)}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    stores: state.AdminPortal.store.stores,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openAddStoreModal: () => dispatch(_openStoreModal()),
    getStores: () => dispatch(_getStores()),
    setActiveStore: (storeData) => dispatch(_setActiveStore(storeData)),
    clearActiveStore: () => dispatch(_clearActiveStore()),
    deleteStore: (_id) => dispatch(_deleteStore(_id)),
  };
}

Stores = connect(mapStateToProps, mapDispatchToProps)(Stores);
Stores = withStyles(styles)(Stores);
Stores = withRouter(Stores);

export default Stores;
