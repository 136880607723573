import {
  CLEAR_ACTIVE_STORE,
  CLEAR_STORE_SUGGESTIONS,
  SET_ACTIVE_STORE,
  SET_STORE_SUGGESTIONS,
  SET_STORES,
  RESET_STORES,
} from '../../actions/AdminPortal/store';

const initialState = {
  stores: [],
  activeStore: {
    name: '',
    phone: '',
    email: '',
    address: '',
  },
  searchStoreSuggestions: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORES:
      return {
        ...state,
        stores: action.payload,
      };

    case SET_ACTIVE_STORE:
      return {
        ...state,
        activeStore: action.payload,
      };

    case CLEAR_ACTIVE_STORE:
      return {
        ...state,
        activeStore: initialState.activeStore,
      };

    case SET_STORE_SUGGESTIONS:
      return {
        ...state,
        searchStoreSuggestions: action.payload,
      };

    case CLEAR_STORE_SUGGESTIONS:
      return {
        ...state,
        searchStoreSuggestions: [],
      };

    case RESET_STORES:
      return {
        ...initialState,
      };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default stores;
