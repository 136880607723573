/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
  Chip, Grid, Paper, withStyles, Button,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { LurePageHeading, LureTable, LureTheme } from '@lureapps/lure-ui';
import { withRouter } from 'react-router-dom';
import {
  green, red, purple, orange, blue,
} from '@material-ui/core/colors';
import { Add as PlusIcon } from '@material-ui/icons';
import {
  openBookingModal as _openBookingModal,
  openRejectRequestModal as _openRejectRequestModal,
} from '../../../store/actions/modals';
import {
  approveBooking as _approveBooking,
  getBookings as _getBookings,
} from '../../../store/actions/AdminPortal/booking';
import {
  ACCEPTED, CANCELLED, COMPLETED, REJECTED, REQUESTED,
} from '../../../constants/bookingStatuses';


export const chipStyles = (theme) => ({
  acceptedChip: { backgroundColor: blue[50], color: blue[700] },
  rejectedChip: { backgroundColor: orange[50], color: orange[700] },
  requestedChip: { backgroundColor: purple[50], color: purple[700] },
  completedChip: { backgroundColor: green[50], color: green[700] },
  cancelledChip: { backgroundColor: red[50], color: red[700] },
  addIcon: {
    marginRight: theme.spacing(1),
  },
});

export function chipHelper(status, classes) {
  switch (status) {
    case REQUESTED:
      return (
        <Chip
          className={classes.requestedChip}
          label={'Requested'}
        />
      );

    case ACCEPTED:
      return (
        <Chip
          label={'Accepted'}
          className={classes.acceptedChip}
        />
      );

    case REJECTED:
      return (
        <Chip
          label={'Rejected'}
          className={classes.rejectedChip}
        />
      );

    case COMPLETED:
      return (
        <Chip
          label={'Completed'}
          className={classes.completedChip}
        />
      );

    case CANCELLED:
      return (
        <Chip
          label={'Cancelled'}
          className={classes.cancelledChip}
        />
      );

    default:
      return <Chip label={'-'} />;
  }
}

class Bookings extends Component {
  columns = [{
    title: 'Created',
    field: 'createdAt',
    defaultSort: 'desc',
    render: (Booking) => moment(get(Booking, 'createdAt'), 'x').fromNow(),
  }, {
    title: 'Booking No.',
    field: 'bookingReference',
    render: (Booking) => `${get(Booking, 'bookingReference', '')}`,
  }, {
    title: 'Customer name',
    render: (Booking) => `${get(Booking, 'Customer.firstName', '')} ${get(Booking, 'Customer.lastName', '')}`,
  }, {
    title: 'Customer phone',
    render: (Booking) => (get(Booking, 'Customer.phone')
      ? get(Booking, 'Customer.phone')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No phone provided</span>),
  }, {
    title: 'Customer email',
    render: (Booking) => (get(Booking, 'Customer.email')
      ? get(Booking, 'Customer.email')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No email provided</span>),
  }, {
    title: 'Store',
    field: 'Store.name',
    render: (Booking) => (get(Booking, 'Store.name', '')
      ? get(Booking, 'Store.name')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No store provided</span>),
  }, {
    title: 'Requested Time',
    render: (Booking) => (get(Booking, 'Store._id')
      ? moment(Booking.timeslots[0].timeslot, 'x').tz(get(Booking, 'Store.timezone')).format('Do MMM YYYY, h:mma') // will need to be looked at again when deciding on multi-timeslots
      : <span style={{ color: LureTheme.colors.darkGrey }}>No time selected</span>),
  }, {
    title: 'Status',
    field: 'status',
    render: (Booking) => chipHelper(Booking.status, this.props.classes),
  }];

  async componentDidMount() {
    const { getBookings } = this.props;
    await getBookings();
  }

  approveBooking = (Booking) => {
    const { approveBooking } = this.props;
    approveBooking(Booking._id, Booking.timeslots[0]._id);
    this.closeActionMenu();
  }

  render() {
    const {
      bookings, history, classes, openBookingModal,
    } = this.props;
    return (
      <Grid container direction={'column'}>
        <LurePageHeading
          title={'Requests'}
          action={(
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => openBookingModal()}
            >
              <PlusIcon className={classes.addIcon} fontSize={'small'} />
              Create Booking
            </Button>
          )}
        />
        <Grid item>
          <Paper>
            <LureTable
              columns={this.columns}
              data={bookings}
              onRowClick={(e, Booking) => history.push(`/app/bookings/requests/${Booking._id}`)}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookings: state.AdminPortal.bookings.bookingList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openRejectRequestModal: () => dispatch(_openRejectRequestModal()),
    getBookings: () => dispatch(_getBookings()),
    approveBooking: (bookingId, timeslotId) => dispatch(_approveBooking(bookingId, timeslotId)),
    openBookingModal: () => dispatch(_openBookingModal()),
  };
}

Bookings = connect(mapStateToProps, mapDispatchToProps)(Bookings);
Bookings = withRouter(Bookings);
Bookings = withStyles(chipStyles)(Bookings);

export default Bookings;
