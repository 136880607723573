const initialState = {
  list: [],
  active: {},
};

const booking = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export default booking;
