import {
  CLEAR_ACTIVE_CUSTOMER,
  LOADING_CUSTOMER,
  LOADING_CUSTOMERS,
  SET_ACTIVE_CUSTOMER,
  SET_CUSTOMERS,
  RESET_CUSTOMERS,
} from '../../actions/AdminPortal/customer';

const initialState = {
  list: [],
  listLoading: false,
  active: {
    Notes: [],
  },
  activeLoading: false,
  new: {},
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_CUSTOMERS:
      return {
        ...state,
        listLoading: true,
      };
    case LOADING_CUSTOMER:
      return {
        ...state,
        activeLoading: true,
        active: { ...initialState.active },
      };
    case SET_CUSTOMERS: {
      return {
        ...state,
        list: action.payload,
        listLoading: false,
      };
    }
    case SET_ACTIVE_CUSTOMER:
      return {
        ...state,
        activeLoading: false,
        active: {
          ...state.active,
          ...action.payload,
        },
      };
    case CLEAR_ACTIVE_CUSTOMER:
      return {
        ...state,
        activeLoading: false,
        active: initialState.active,
      };

    case RESET_CUSTOMERS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default customer;
