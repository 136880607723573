import React from 'react';
import {
  LurePaper,
} from '@lureapps/lure-ui';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

function InstallationGuide() {
  const Company = useSelector((state) => state.auth.user.CurrentCompany);
  return (
    <>
      <LurePaper>
        <Grid container justify={'space-between'} alignItems={'center'} spacing={3}>
          <Grid item>
            <Typography variant={'h6'}>App ID</Typography>
          </Grid>
        </Grid>
        <Grid container direction={'column'} spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              {get(Company, '_id')}
            </Typography>
          </Grid>
        </Grid>
      </LurePaper>
    </>
  );
}

export default InstallationGuide;
