import React from 'react';
import AddCustomerModal from '../../components/modals/AddCustomerModal';
import AddNoteModal from '../../components/modals/AddNoteModal';
import RegistrationModal from '../../components/modals/RegistrationModal';
import StaffModal from '../../components/modals/StaffModal';
import StoreModal from '../../components/modals/StoreModal';
import RejectRequestModal from '../../components/modals/RejectRequestModal';
import AcceptBookingModal from '../../components/modals/AcceptBookingModal/AcceptBookingModal';
import ConfirmModal from '../../components/modals/ConfirmModal';
import ChangeAccountModal from '../../components/modals/ChangeAccountModal';
import CompanyModal from '../../components/modals/CompanyModal';
import BookingModal from '../../components/modals/BookingModal/BookingModal';
import TradingHoursModal from '../../components/modals/TradingHoursModal';
import CustomerSettingsModal from '../../components/modals/CustomerSettings';
import CompleteBookingModal from '../../components/modals/CompleteBookingModal/CompleteBookingModal';
import InstallationModal from '../../components/modals/InstallationModal';
import ProductTestModal from '../../components/modals/ProductTestModal/ProductTestModal';

function Modals() {
  return (
    <>
      <AddCustomerModal />
      <AddNoteModal />
      <RegistrationModal />
      <StaffModal />
      <StoreModal />
      <RejectRequestModal />
      <AcceptBookingModal />
      <ConfirmModal />
      <ChangeAccountModal />
      <CompanyModal />
      <BookingModal />
      <TradingHoursModal />
      <CustomerSettingsModal />
      <CompleteBookingModal />
      <InstallationModal />
      <ProductTestModal />
    </>
  );
}

export default Modals;
