import { gql } from 'apollo-boost';
import { createBrowserHistory } from 'history';
import { client } from '../../services/GraphQLService';
import { firebaseApp } from '../../hooks/useFirebase';
import { LOGIN_ROUTE } from '../../constants/routes';
import { RESET_STORES } from './AdminPortal/store';
import { RESET_CUSTOMERS } from './AdminPortal/customer';
import { RESET_STAFF } from './AdminPortal/staff';
import { RESET_BOOKINGS } from './AdminPortal/booking';

export const SET_USER = 'SET_USER';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';

const history = createBrowserHistory();

const GQL_ME = gql`
  query me {
    me {
      _id
      uid
      name
      picture
      role
      email
      Customer {
          _id
          firstName
          lastName
          fullName
          email
          phone
      }
      UserCompanies {
        _id
        role
        company
        Company {
          _id
          name
        }
      }
      CurrentCompany {
        _id
        uid
        name
        website
        email
        phone
        tradingHours {
          _id
          day
          open {
            hour
            minute
          }
          close {
            hour
            minute
          }
          isOpen
        }
      }
      createdAt
    }
  }
`;

export function logout() {
  return async function logoutActionCreator(dispatch) {
    await firebaseApp.auth().signOut();
    dispatch({
      type: LOGOUT,
    });
    dispatch({
      type: RESET_CUSTOMERS,
    });
    dispatch({
      type: RESET_BOOKINGS,
    });
    dispatch({
      type: RESET_STAFF,
    });
    dispatch({
      type: RESET_STORES,
    });
    history.push(LOGIN_ROUTE);
  };
}

export function setAuthUser(cb) {
  return async (dispatch) => client.query({
    query: GQL_ME,
  })
    .then(({ data: { me: user } }) => {
      dispatch({
        type: SET_USER,
        payload: user,
      });
      if (cb) cb(user);
    })
    .catch();
}


export function setCurrentCompany(company) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_COMPANY,
      payload: company,
    });
  };
}
