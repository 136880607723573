import { gql } from 'apollo-boost';
import gqlClient from './GraphQLService';
import CompanyService from './CompanyService';

const GQL_REGISTER_COMPANY = gql`
    mutation registerCompany($registrationData: CompanyRegistrationInput) {
        registerCompany(registrationData:$registrationData) {
            _id
            name
            email
            phone
            website
            shopifyShopOrigin
        }
    }
`;

const GQL_REGISTER_USER = gql`
    mutation registerUser($registrationData: UserRegistrationInput) {
        registerUser(registrationData:$registrationData) {
            _id
            name
            phone
            email
            uid
            role
        }
    }
`;

const GQL_UPDATE_ACTIVE_USER_COMPANY = gql`
    mutation updateActiveUserCompany($activeUserCompanyId: ID!) {
      updateActiveUserCompany(activeUserCompanyId: $activeUserCompanyId) {
        _id
        name
        phone
        email
        uid
        role
        UserCompanies {
          company
          role
        }
      }
    }
`;

const GQL_USER_BY_UID = gql`
    query userByUID($uid: ID!) {
      userByUID(uid: $uid) {
        _id
      }
    }
`;

const GQL_ACCEPT_STAFF_MEMBER_INVITATION = gql`
    mutation acceptStaffMemberInvitation($email: String!, $uid: ID!) {
      acceptStaffMemberInvitation(email: $email, uid: $uid) {
        _id
        uid
        name
      }
    }
`;

class UserService {
  static parseUser(user) {
    return {
      uid: user.uid,
      email: user.email,
      phone: user.phone,
      name: user.displayName,
      picture: user.photoURL,
    };
  }

  static registerCompany(company) {
    return gqlClient.mutate({
      mutation: GQL_REGISTER_COMPANY,
      variables: {
        registrationData: {
          company: CompanyService.parseCompany(company),
        },
      },
    });
  }

  static registerUser(user, role) {
    return gqlClient.mutate({
      mutation: GQL_REGISTER_USER,
      variables: {
        registrationData: this._parseUserRegistrationData(user, role),
      },
    });
  }

  static updateActiveUserCompany(activeUserCompanyId) {
    return gqlClient.mutate({
      mutation: GQL_UPDATE_ACTIVE_USER_COMPANY,
      variables: {
        activeUserCompanyId,
      },
    });
  }

  static getUserByUID(uid) {
    return gqlClient.query({
      query: GQL_USER_BY_UID,
      variables: {
        uid,
      },
    });
  }

  static acceptStaffInvitation(email, uid) {
    return gqlClient.mutate({
      mutation: GQL_ACCEPT_STAFF_MEMBER_INVITATION,
      variables: {
        email,
        uid,
      },
    });
  }

  static _parseUserRegistrationData(user, role) {
    return {
      user: this.parseUser(user),
      role,
    };
  }
}

export default UserService;
