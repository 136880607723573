import { useState } from 'react';
import * as firebase from 'firebase';
import firebaseConfig from '../config/firebaseConfig';

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export default function useFirebase() {
  const auth = firebaseApp.auth();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
  } = auth;

  function signInWithGoogle() {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(googleProvider);
  }

  function signInWithFacebook() {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    return auth.signInWithPopup(facebookProvider);
  }

  auth.onAuthStateChanged((firebaseUser) => {
    setUser(firebaseUser);
    setLoading(false);
  });

  return {
    auth,
    user,
    loading,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithGoogle,
    signInWithFacebook,
  };
}
