import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import { Button, Grid, Typography } from '@material-ui/core';
import * as jwt from 'jsonwebtoken';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get } from 'lodash';
import { renderLureTextField } from '../shared/formHelpers/fields';
import PreAuthFooter from './shared/PreAuthFooter';
import useFirebase from '../../hooks/useFirebase';
import { snackError } from '../../store/actions/snackbar';
import UserService from '../../services/UserService';
import { setAuthUser } from '../../store/actions/auth';

const styles = (theme) => ({
  containerPadding: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  loader: {
    color: 'white',
  },
});

function AcceptInvite() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { auth } = useFirebase();

  const { token } = QueryString.parse(location.search);
  const [user, setUser] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const decodedToken = jwt.decode(token.toString());
    setUser(decodedToken);
  }, [token, setUser]);

  const handleAcceptInvite = useCallback(async ({ email, password }) => {
    setSubmitting(true);
    try {
      await auth.createUserWithEmailAndPassword(email, password)
        .then(async ({ user: firebaseUser }) => {
          await firebaseUser.updateProfile({
            displayName: get(user, 'fullName'),
          });
          const uid = get(firebaseUser, 'uid');
          await UserService.acceptStaffInvitation(email, uid);
          dispatch(setAuthUser());
        });
    } catch (err) {
      dispatch(snackError(`Authentication failed. ${err.code}`));
    } finally {
      setSubmitting(false);
    }
  }, [auth, dispatch, user]);

  return (
    <Form
      onSubmit={handleAcceptInvite}
      initialValues={user}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete={'off'}
        >
          <Grid container direction={'column'}>
            <Grid container item className={classes.containerPadding}>
              <Grid item xs={12}>
                <Typography align={'center'} variant={'h4'} gutterBottom>Accept Invitation</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.containerPadding} spacing={3}>
              <Grid container item spacing={3}>
                <Grid container item justify={'center'}>
                  <Grid item xs={10}>
                    <Field
                      fullWidth
                      readOnly
                      label={'Name'}
                      name={'fullName'}
                      type={'text'}
                      placeholder={'Enter your name'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container item justify={'center'}>
                  <Grid item xs={10}>
                    <Field
                      fullWidth
                      readOnly
                      label={'Email'}
                      name={'email'}
                      type={'text'}
                      placeholder={'Enter your email'}
                      autoComplete={'email'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container item justify={'center'}>
                  <Grid item xs={10}>
                    <Field
                      fullWidth
                      autoFocus
                      label={'Password'}
                      name={'password'}
                      placeholder={'Enter your password'}
                      type={'password'}
                      autoComplete={'current-password'}
                      component={renderLureTextField}
                    />
                  </Grid>
                </Grid>
                <Grid container item justify={'center'}>
                  <Grid item xs={10}>
                    <Button
                      fullWidth
                      variant={'contained'}
                      color={'primary'}
                      type={'submit'}
                    >
                      { submitting ? <CircularProgress size={20} className={classes.loader} /> : 'Accept Invite' }
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <PreAuthFooter>
              <Typography variant={'body1'}>
                &copy; Lure 2020
              </Typography>
            </PreAuthFooter>
          </Grid>
        </form>
      )}
    />
  );
}

export default AcceptInvite;
