import { setAuthUser, setCurrentCompany } from './auth';
import CompanyService from '../../services/CompanyService';
import { snackSuccess } from './snackbar';

import { closeRegistrationModal } from './modals';

export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';

export function createCompany(company, cb) {
  return function createCompanyAction(dispatch) {
    return CompanyService.createCompany(company)
      .then((newCompany) => {
        dispatch(snackSuccess(`${newCompany.name} created.`));
        dispatch(setAuthUser());
        dispatch(closeRegistrationModal());
        if (cb) cb(newCompany);
      });
  };
}

export function updateCompany(_id, company, cb) {
  return (dispatch) => CompanyService.updateCompany(_id, company)
    .then(({ data: { updateCompany: Company } }) => {
      dispatch(snackSuccess('Company details updated.'));
      dispatch(setCurrentCompany(Company));
      if (cb) cb(Company);
    });
}
