import React, { useCallback, useState } from 'react';
import { truncate, get } from 'lodash';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Grid, makeStyles, CircularProgress, Typography, InputAdornment, IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import {
  LureDialog,
  LureDialogActions,
  LureDialogContent,
  LureDialogTitle,
} from '@lureapps/lure-ui';
import { closeProductTestModal } from '../../../store/actions/modals';
import { renderLureTextField } from '../../shared/formHelpers/fields';
import { upsertProduct } from '../../../services/ProductService';
import { snackError } from '../../../store/actions/snackbar';

const styles = (theme) => ({
  urlField: {
    width: '100%',
  },
  image: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    backgroundColor: 'grey',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  muted: {
    color: theme.colors.darkGrey,
  },
});

const Product = ({ product, loading, classes }) => {
  if (!loading && product) {
    return (
      <Grid container spacing={3}>
        <Grid item container alignItems={'center'} justify={'center'} xs={2}>
          <Grid
            style={{ backgroundImage: `url(${get(product, 'image')})` }}
            className={classes.image}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant={'h6'}>
            {truncate(
              get(product, 'formattedName', 'No Name Found'),
              { length: 48 },
            )}
          </Typography>
          <Typography variant={'body2'} className={classes.muted}>
            {truncate(get(product, 'description', 'No Description Found'), { length: 134 })}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h5'}>{get(product, 'formattedPrice', '$0.00')}</Typography>
        </Grid>
      </Grid>
    );
  }
  if (loading) {
    return <CircularProgress />;
  }
  return <Typography variant={'body2'} className={classes.muted}>Your test product will appear here</Typography>;
};

const ProductTestModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modals.productTestModal);
  const Company = useSelector((state) => state.auth.user.CurrentCompany);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const submit = useCallback(({ url }) => {
    if (!url) return;
    setLoading(true);
    upsertProduct(url, Company.uid)
      .then(({ data: { upsertByUrl: productData } }) => {
        setProduct(productData);
      })
      .catch(() => {
        dispatch(snackError('Unfortunately something went wrong when attempting to display this product'));
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const onClose = useCallback(() => {
    setLoading(false);
    setProduct(null);
    dispatch(closeProductTestModal());
  });

  return (
    <LureDialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      disableBackdropClick
    >
      <LureDialogTitle>
        Test a Product
      </LureDialogTitle>
      <LureDialogContent>
        <Grid container spacing={5}>
          <Grid item>
            <Typography variant={'body1'}>
              Here you can test to see what a Product from your site will look like when
              it&#39;s added to Lure. Lure uses your website&#39;s Structured Data to
              build the Product from your webpage.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Form
              onSubmit={submit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    fullWidth
                    name={'url'}
                    label={'Enter the URL of the product here'}
                    placeholder={'http://www.myshop.com/product/t-shirt'}
                    endAdornment={(
                      <InputAdornment position={'end'}>
                        <IconButton
                          type={'submit'}
                          aria-label={'toggle password visibility'}
                          edge={'end'}
                          size={'small'}
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    )}
                    component={renderLureTextField}
                  />
                </form>
              )}
            />
          </Grid>
          <Grid item container xs={12} justify={'center'}>
            <Product product={product} loading={loading} classes={classes} />
          </Grid>
        </Grid>
      </LureDialogContent>
      <LureDialogActions>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              color={'primary'}
              variant={'outlined'}
              onClick={onClose}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </LureDialogActions>
    </LureDialog>
  );
};

export default ProductTestModal;
