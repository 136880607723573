import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';

const styles = () => ({
  facebookBtn: {
    background: '#3B77F2',
    color: 'white !important',
    textTransform: 'none',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#3B77F2',
    },
  },
});

const SignInWithFacebook = ({ handleConnectWithFacebook }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Button
        fullWidth
        className={classes.facebookBtn}
        onClick={handleConnectWithFacebook}
        startIcon={<FacebookIcon />}
      >
        Continue with Facebook
      </Button>
    </>
  );
};

export default SignInWithFacebook;
