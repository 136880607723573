import React, { Component } from 'react';
import {
  Button, Grid, Paper, withStyles, Chip,
} from '@material-ui/core';
import moment from 'moment';
import { Add as PlusIcon, Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { LurePageHeading, LureTable, LureTheme } from '@lureapps/lure-ui';
import { get } from 'lodash';
import { openCustomerModal as _openCustomerModal } from '../../store/actions/modals';
import {
  CLEAR_ACTIVE_CUSTOMER,
  deleteCustomer as _deleteCustomer,
  getCustomers as _getCustomers,
  SET_ACTIVE_CUSTOMER,
} from '../../store/actions/AdminPortal/customer';


const styles = (theme) => ({
  addIcon: {
    marginRight: theme.spacing(1),
  },
});

function EditCustomerButton({
  row, openCustomerModal, setActive, deleteCustomer,
}) {
  const handleEditClick = function handleEditClick(e, _id) {
    e.stopPropagation();
    setActive(row);
    openCustomerModal(_id);
  };

  const handleDeleteClick = function handleDeleteClick(e, _id) {
    e.stopPropagation();
    deleteCustomer(_id);
  };


  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <Chip
            label={'Edit'}
            size={'large'}
            onClick={(e) => handleEditClick(e, row._id)}
          />
        </Grid>
        <Grid item>
          <IconButton
            variant={'text'}
            color={'primary'}
            size={'small'}
            onClick={(e) => handleDeleteClick(e, row._id)}
          >
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

class Customers extends Component {
  columns = [{
    title: 'Name',
    render: (customer) => get(customer, 'fullName'),
  }, {
    title: 'Phone',
    field: 'phone',
    render: (customer) => (get(customer, 'phone')
      ? get(customer, 'phone')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No phone provided</span>),
  }, {
    title: 'Email',
    field: 'email',
    render: (customer) => (get(customer, 'email')
      ? get(customer, 'email')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No email provided</span>),
  }, {
    title: 'Primary Store',
    field: 'primaryStore.name',
    render: (customer) => (get(customer, 'primaryStore._id', '')
      ? get(customer, 'primaryStore.name')
      : <span style={{ color: LureTheme.colors.darkGrey }}>No primary store provided</span>),
  }, {
    title: 'Last contact date',
    field: 'createdAt',
    render: (customer) => moment(customer.createdAt, 'x').format('Do MMM YYYY'),
  }, {
    title: 'Actions',
    render: (row) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <EditCustomerButton row={row} {...this.props} />
    ),
  }];

  componentDidMount() {
    const { getCustomers } = this.props;
    getCustomers();
  }

  handleAddCustomer = () => {
    const { clearActive, openCustomerModal } = this.props;

    clearActive();
    openCustomerModal();
  };

  render() {
    const {
      classes, history, customers, loading,
    } = this.props;

    return (
      <Grid container direction={'column'}>
        <LurePageHeading
          title={'Customers'}
          action={(
            <Button variant={'contained'} color={'primary'} onClick={this.handleAddCustomer}>
              <PlusIcon className={classes.addIcon} fontSize={'small'} />
              Add Customer
            </Button>
          )}
        />
        <Grid item>
          <Paper>
            <LureTable
              columns={this.columns}
              data={customers}
              onRowClick={(e, Customer) => history.push(`/app/customers/${Customer._id}`)}
              isLoading={loading}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    openCustomerModal: (_id) => dispatch(_openCustomerModal(_id)),
    getCustomers: () => dispatch(_getCustomers()),
    deleteCustomer: (_id) => dispatch(_deleteCustomer(_id)),
    setActive: (data) => dispatch({
      type: SET_ACTIVE_CUSTOMER,
      payload: data,
    }),
    clearActive: () => dispatch({
      type: CLEAR_ACTIVE_CUSTOMER,
    }),
  };
}

function mapStateToProps(state) {
  return {
    customers: state.AdminPortal.customer.list,
    loading: state.AdminPortal.customer.listLoading,
    activeLoading: state.AdminPortal.customer.activeLoading,
  };
}

Customers = withStyles(styles)(Customers);
Customers = connect(mapStateToProps, mapDispatchToProps)(Customers);
Customers = withRouter(Customers);
export default Customers;
