/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import StoreService from '../../../services/StoreService';

const StoreAutocomplete = ({
  label = 'Select Store', size = '', onChange, ...restAutocomplete
}) => {
  const [Stores, setStores] = useState([]);
  useEffect(() => {
    StoreService.getStores()
      .then(({ data: { getStores: stores } }) => setStores(stores));
  }, []);
  return (
    <>
      <Autocomplete
        {...restAutocomplete}
        options={Stores}
        getOptionLabel={(option) => option.name}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            variant={'outlined'}
            margin={'normal'}
            size={size}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);

          return (
            <div>
              { parts.map((part) => (
                <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  { part.text }
                </span>
              )) }
            </div>
          );
        }}
      />
    </>
  );
};

export default StoreAutocomplete;
