import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { renderLureTextField } from '../../shared/formHelpers/fields';
import { required } from '../../shared/formHelpers/validation';

const CustomerFields = () => (
  <>
    <Grid item xs={12}>
      <Field
        fullWidth
        name={'user.displayName'}
        label={'Your name *'}
        type={'text'}
        placeholder={'Enter your full name'}
        component={renderLureTextField}
        validate={required}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        readOnly
        name={'user.email'}
        label={'Your email *'}
        type={'email'}
        placeholder={'Enter your email'}
        component={renderLureTextField}
        validate={required}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name={'user.phone'}
        label={'Your phone'}
        type={'phone'}
        placeholder={'Enter your phone number'}
        component={renderLureTextField}
      />
    </Grid>
  </>
);

export default CustomerFields;
