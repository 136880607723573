import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles, Grid, Button, Typography, Collapse,
} from '@material-ui/core';
import {
  LureDialog,
  LureDialogContent,
  LureDialogTitle,
  LureDialogActions,
} from '@lureapps/lure-ui';
import { Form, Field } from 'react-final-form';
import { get, pick } from 'lodash';
import { closeBookingModal } from '../../../store/actions/modals';
import {
  RenderCustomerAutosuggest,
  RenderStoreAutosuggest,
  renderTextField,
} from '../../shared/formHelpers/fields';
import NewCustomerForm from './NewCustomerForm';
import BookingService from '../../../services/BookingService';
import { snackError, snackSuccess } from '../../../store/actions/snackbar';

import { getBookings } from '../../../store/actions/AdminPortal/booking';
import KeyboardDateTimePicker from '../../shared/formHelpers/KeyboardDateTimePicker';
import useMomentTz from '../../../hooks/useMomentTz';

const styles = (theme) => ({
  rightBuffer: {
    marginRight: theme.spacing(5),
  },
  leftBuffer: {
    marginLeft: theme.spacing(5),
  },
  notesPadding: {
    marginBottom: theme.spacing(2),
  },
  addCustomerBtnContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1.3),
  },
  addCustomerBtn: {
    padding: theme.spacing(1.3),
  },
  dateHeading: {
    marginBottom: theme.spacing(3),
  },
  overflow: {
    overflow: 'hidden',
  },
});

const _parseCustomer = (customer) => pick(customer, ['_id', 'firstName', 'lastName', 'phone', 'email']);

const BookingModal = () => {
  const [initialDate, setInitialDate] = useState(null);
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modals.bookingModal);
  const [newCustomerOpen, setNewCustomerOpen] = useState(false);
  const momentTz = useMomentTz();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    setInitialDate(
      momentTz().add(1, 'd').startOf('h'),
    );
  }, [momentTz]);

  const handleClose = useCallback(() => {
    dispatch(closeBookingModal());
  }, [dispatch]);

  const submitBooking = useCallback(({
    customer, newCustomer, store, date, message,
  }) => {
    let Customer = {};

    // Build Customer
    if (get(customer, '_id')) {
      Customer = _parseCustomer(customer);
    } else {
      Customer = newCustomer;
    }

    // Build Booking
    const booking = {
      Customer,
      storeId: get(store, '_id'),
      timeslots: [
        momentTz(date)
          .valueOf()
          .toString(),
      ],
      message,
    };
    // Submit Booking
    BookingService.createBooking(booking)
      .then(({ data: { createBookingFromBMS: Booking } }) => {
        dispatch(snackSuccess(
          `Booking for ${Booking.Customer.firstName} \
          ${Booking.Customer.lastName} has been created`,
        ));
        dispatch(getBookings());
        dispatch(closeBookingModal());
      })
      .catch(() => {
        dispatch(snackError('Booking could not be created'));
      });
  }, [dispatch, momentTz]);

  return (
    <LureDialog
      open={isOpen}
      onClose={() => dispatch(closeBookingModal())}
      maxWidth={'sm'}
      fullWidth
      disableEnforceFocus
      disableBackdropClick
    >
      <LureDialogTitle>
        Create a Booking
      </LureDialogTitle>
      <Form
        onSubmit={submitBooking}
        initialValues={{ date: initialDate }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <LureDialogContent className={classes.overflow}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant={'h6'}>Customer</Typography>
                  <Field
                    fullWidth
                    name={'customer'}
                    size={'small'}
                    component={RenderCustomerAutosuggest}
                    disabled={newCustomerOpen}
                  />
                </Grid>
                <Grid item xs={4} className={classes.addCustomerBtnContainer}>
                  <Button
                    color={'primary'}
                    fullWidth
                    size={'small'}
                    className={classes.addCustomerBtn}
                    onClick={() => {
                      form.change('customer', {});
                      setNewCustomerOpen(!newCustomerOpen);
                    }}
                  >
                    {newCustomerOpen ? 'Cancel' : 'New Customer'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Collapse
                    in={newCustomerOpen}
                    timeout={200}
                  >
                    <Grid item xs={12}>
                      <NewCustomerForm />
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>Store</Typography>
                  <Field
                    fullWidth
                    name={'store'}
                    size={'small'}
                    component={RenderStoreAutosuggest}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h6'} className={classes.dateHeading}>Date & Time</Typography>
                  <Field
                    fullWidth
                    name={'date'}
                    size={'small'}
                    component={KeyboardDateTimePicker}
                    label={'Date / Time'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h6'} className={classes.notesPadding}>Notes</Typography>
                  <Field
                    fullWidth
                    name={'message'}
                    label={'Message'}
                    component={renderTextField}
                    multiline
                    variant={'outlined'}
                    rows={4}
                  />
                </Grid>
              </Grid>
            </LureDialogContent>
            <LureDialogActions>
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant={'outlined'}
                    color={'primary'}
                    className={classes.rightBuffer}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    className={classes.leftBuffer}
                    type={'submit'}
                  >
                    Create Booking
                  </Button>
                </Grid>
              </Grid>
            </LureDialogActions>
          </form>
        )}
      />
    </LureDialog>
  );
};

export default BookingModal;
