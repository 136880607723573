import React, { useCallback, useEffect } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LureCalendar, LurePageHeading, LurePaper } from '@lureapps/lure-ui';
import { Add as PlusIcon } from '@material-ui/icons';
import Filter from './Filter';
import { setCalendarBookings, setCalendarFilteredBookings } from '../../../store/actions/AdminPortal/booking';
import theme from '../../../theme/main';
import { ACCEPTED } from '../../../constants/bookingStatuses';
import { openAcceptBookingModal, openBookingModal } from '../../../store/actions/modals';
import BookingService from '../../../services/BookingService';

const styles = (t) => ({
  addIcon: {
    marginRight: t.spacing(1),
  },
});

function eventColorHelper(bookingStatus, isBackgroundColor) {
  if (bookingStatus === ACCEPTED) {
    if (isBackgroundColor) return 'rgba(34,204,150, 0.2)';
    return theme.colors.green;
  }

  if (isBackgroundColor) return 'rgba(117, 81, 233, 0.2)';
  return '#7551E9';
}

function parseBookingsToCalendarEvents(Bookings = []) {
  return Bookings.map((br) => {
    let { timeslot } = br.timeslots[0];
    if (br.acceptedTimeslot) timeslot = br.acceptedTimeslot.timeslot;
    const fullName = get(br, 'Customer.fullName');
    const bookingTime = moment(timeslot, 'x').format('h:mma');
    const startDate = moment(timeslot, 'x').valueOf();
    const endDate = moment(timeslot, 'x').valueOf();

    return {
      id: br._id,
      title: `${fullName} - ${bookingTime}`,
      start: startDate,
      end: endDate,
      color: eventColorHelper(br.status, true),
      borderColor: eventColorHelper(br.status),
      textColor: eventColorHelper(br.status),
    };
  });
}

const BookingCalendar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const Bookings = useSelector((state) => state
    .AdminPortal.bookings.calendarBookings.filtered);
  const bookingList = useSelector((state) => state
    .AdminPortal.bookings.bookingList);
  const bookingEvents = parseBookingsToCalendarEvents(Bookings);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    BookingService.getBookings()
      .then(({ data: { bookings } }) => {
        dispatch(setCalendarBookings(bookings));
        dispatch(setCalendarFilteredBookings(bookings));
      });
  }, [dispatch, bookingList]);

  const handleEventClick = useCallback((e) => {
    const Booking = Bookings.find((br) => br._id === e.event.id);
    if (Booking.status === ACCEPTED) {
      history.push(`/app/bookings/requests/${e.event.id}`);
      return;
    }
    dispatch(openAcceptBookingModal(Booking));
  }, [dispatch, Bookings, history]);

  return (
    <>
      <LurePageHeading
        title={'Calendar'}
        action={(
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => dispatch(openBookingModal())}
          >
            <PlusIcon className={classes.addIcon} fontSize={'small'} />
            Create Booking
          </Button>
        )}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={3} xl={2}>
          <LurePaper noPadding>
            <Filter />
          </LurePaper>
        </Grid>
        <Grid item xs={12} md={12} lg={9} xl={10}>
          <LurePaper>
            <LureCalendar
              events={bookingEvents}
              eventClick={handleEventClick}
              displayEventTime={false}
              displayEventEnd={false}
              slotEventOverlap={false}
              scrollTime={'09:00:00'}
              eventLimit
            />
          </LurePaper>
        </Grid>
      </Grid>
    </>
  );
};

export default BookingCalendar;
