/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Select, TextField, Switch,
  CircularProgress,
} from '@material-ui/core';
import { get } from 'lodash';
import { LureFormTextInput } from '@lureapps/lure-ui';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup } from '@material-ui/lab';
import { StaffService } from '../../../services/StaffService';
import StoreService from '../../../services/StoreService';
import CustomerService from '../../../services/CustomerService';


export const renderLureSelectField = ({
  input,
  meta: { touched, error },
  children,
  variant,
  fullWidth,
  label,
  displayEmpty,
  disabled,
  onSelect,
}) => (
  <>
    <Select
      label={label}
      fullWidth={fullWidth}
      variant={variant}
      value={input.value}
      onChange={(e) => {
        if (onSelect) onSelect(e.target.value);
        input.onChange(e.target.value);
      }}
      error={Boolean(touched && error)}
      displayEmpty={displayEmpty}
      disabled={disabled}
    >
      {children}
    </Select>
  </>
);

export const renderLureSwitchField = ({
  input, meta: { touched, error },
}) => (
  <>
    <Switch
      disableRipple
      size={'small'}
      color={'primary'}
      checked={input.value}
      value={input.value}
      onChange={(e) => {
        input.onChange(e.target.checked);
      }}
      error={(touched && error) ? error : undefined}
    />
  </>
);

export const renderTextField = ({
  input, label, meta: { touched, error }, ...custom
}) => (
  <>
    <TextField
      fullWidth
      label={label}
      error={Boolean(touched && error)}
      helperText={(touched && error) ? error : null}
      {...input}
      {...custom}
    />
  </>
);

export const renderLureTextField = ({
  input, label, meta: { touched, error }, ...custom
}) => (
  <>
    <LureFormTextInput
      fullWidth
      label={label}
      {...input}
      {...custom}
    />
    <Typography color={'error'} variant={'body2'} style={{ marginTop: '6px' }}>
      {(touched && error) ? error : null}
    </Typography>
  </>
);

export const RenderStoreAutosuggest = ({
  label = 'Select Store',
  size = '',
  input,
  input: { value, onChange, ...restInput },
  getStoreOnChange,
  ...rest
}) => {
  const [Stores, setStores] = useState([]);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    StoreService.getStores()
      .then(({ data: { getStores: stores } }) => setStores(stores));
  }, []);


  useEffect(() => {
    if (value.name) {
      setTextValue(value.name);
    } else {
      setTextValue(value);
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        {...rest}
        {...restInput}
        options={Stores}
        getOptionLabel={(option) => option.name}
        inputValue={textValue}
        onChange={(e, val) => {
          onChange(val);
          if (getStoreOnChange) getStoreOnChange(val);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            variant={'outlined'}
            margin={'normal'}
            size={size}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={value}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);

          return (
            <div>
              { parts.map((part) => (
                <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  { part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </>
  );
};


export const RenderStaffAutosuggest = ({
  label = 'Select Staff', size = '', onChange,
}) => {
  const [Staff, setStaff] = useState([]);
  useEffect(() => {
    StaffService.getStaff()
      .then(({ data: { getStaff: staff } }) => setStaff(staff));
  }, []);
  return (
    <>
      <Autocomplete
        options={Staff}
        getOptionLabel={(option) => option.fullName}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={'outlined'}
            fullWidth
            margin={'normal'}
            size={size}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.fullName, inputValue);
          const parts = parse(option.fullName, matches);

          return (
            <div>
              { parts.map((part) => (
                <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  { part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </>
  );
};

export const StoreStaffAutocomplete = ({
  label = 'Select Staff',
  size = 'small',
  Staff,
  storeId,
  input: { value, onChange },
  meta: { touched, error },
  ...restAutocompleteProps
}) => (
  <>
    <Autocomplete
      {...restAutocompleteProps}
      options={Staff}
      getOptionLabel={(option) => option.fullName}
      onChange={(e, staff) => onChange(staff)}
      inputValue={value.fullName}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={'outlined'}
          fullWidth
          margin={'normal'}
          size={size}
          value={value}
          error={Boolean(touched && error)}
          helperText={touched && error ? error : ''}
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.fullName, inputValue);
        const parts = parse(option.fullName, matches);

        return (
          <div>
            { parts.map((part) => (
              <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                { part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  </>
);

export const RenderCustomerAutosuggest = ({
  label = 'Select Customer', size = '', input: { value, onChange }, disabled,
}) => {
  const [Customers, setCustomers] = useState([]);
  useEffect(() => {
    CustomerService.getCustomers()
      .then((companyCustomers) => setCustomers(companyCustomers));
  }, []);
  return (
    <>
      <Autocomplete
        options={Customers}
        disabled={disabled}
        getOptionLabel={(option) => option.fullName}
        inputValue={value.fullName}
        onChange={(e, val) => {
          onChange(val);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={'outlined'}
            fullWidth
            margin={'normal'}
            size={size}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.fullName, inputValue);
          const parts = parse(option.fullName, matches);

          return (
            <div>
              { parts.map((part) => (
                <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  { part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </>
  );
};


// Google Places Autocomplete

// Google Places Styles
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const autocompleteService = { current: null };

export const RenderGooglePlacesAutocomplete = ({
  label,
  size = 'small',
  variant = 'outlined',
  meta: { touched, error },
  input: { value, onChange, ...restInput },
  setMetaFields,
  placeholder,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (value && value.formatted) {
      setInputValue(value.formatted);
    }
  }, [value]);

  // Textfield onChange handler
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  // Called when inputValue changes
  const fetch = React.useMemo(
    () => throttle((defaultOptions, callback) => {
      const placeAutocompleteRequestOptions = {
        ...defaultOptions,
        componentRestrictions: {
          country: ['au'],
        },
      };
      autocompleteService.current.getPlacePredictions(placeAutocompleteRequestOptions, callback);
    }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });
    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const onPlaceSelection = useCallback(async (e, Place) => {
    const placeId = get(Place, 'place_id');
    // If no placeId, return defaults
    if (!placeId) {
      onChange({ googlePlaceId: '', formatted: get(Place, 'description', '') });
      setInputValue(get(Place, 'description', ''));
      return;
    }

    // Retrieve Place Details from API
    setLoading(true);
    const {
      data: {
        placeDetails: {
          formattedAddress,
          name,
          phone,
        },
      },
    } = await StoreService.googlePlaceDetail(placeId);

    // Update fields with Place Details
    onChange({
      googlePlaceId: get(Place, 'place_id', ''),
      formatted: formattedAddress,
    }); // Sets form field value for Address (this field)
    setInputValue(formattedAddress); // Sets Autocomplete's input value
    if (setMetaFields) setMetaFields(name, phone); // Sets name/phone fields
    setLoading(false);
  });

  return (
    <>
      <Autocomplete
        {...restInput}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        options={options}
        onChange={async (e, Place) => {
          onPlaceSelection(e, Place);
        }}
        autoComplete
        includeInputInList
        disableOpenOnFocus
        inputValue={inputValue}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              size={size}
              variant={variant}
              fullWidth
              onChange={handleChange}
              value={value}
              helperText={(touched && error) ? error : null}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          </>
        )}
        renderOption={(option) => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((m) => [m.offset, m.offset + m.length]),
          );

          return (
            <Grid container alignItems={'center'}>
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part) => (
                  <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    { part.text}
                  </span>
                ))}

                <Typography variant={'body2'} color={'textSecondary'}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
};

export const renderButtonGroup = ({
  input: { onChange, value },
  children,
  exclusive,
  className,
  size = 'medium',
}) => (
  <ToggleButtonGroup
    onChange={(event, newRole) => {
      // Enforce role selection
      if (newRole !== null) {
        onChange(newRole);
      }
    }}
    value={value}
    exclusive={exclusive}
    className={className}
    size={size}
  >
    { children}
  </ToggleButtonGroup>
);
