import React, { useCallback } from 'react';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import { closeCompanyModal } from '../../store/actions/modals';
import { updateCompany } from '../../store/actions/company';
import { renderLureTextField } from '../shared/formHelpers/fields';
import { required } from '../shared/formHelpers/validation';
import { snackError } from '../../store/actions/snackbar';


const styles = (theme) => ({
  leftBuffer: {
    paddingLeft: theme.spacing(5),
  },
  rightBuffer: {
    paddingRight: theme.spacing(5),
  },
});

function CompanyModal() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.companyModal.isOpen);
  const Company = useSelector((state) => state.auth.user.CurrentCompany);

  const handleClose = useCallback(() => dispatch(closeCompanyModal()), [dispatch]);

  const handleCompanyDetailsSubmit = useCallback((values) => {
    const { tradingHours, ...company } = values;
    dispatch(updateCompany(values._id, company))
      .then(() => dispatch(closeCompanyModal()))
      .catch(() => dispatch(snackError('Error updating company details.')));
  }, [dispatch]);

  return (
    <LureDialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth
      disableBackdropClick
    >
      <LureDialogTitle>
        Edit Company
      </LureDialogTitle>
      <Form
        onSubmit={handleCompanyDetailsSubmit}
        initialValues={Company}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <LureDialogContent>
              <Grid container direction={'column'} spacing={3}>
                <Grid item xs>
                  <Field
                    fullWidth
                    name={'name'}
                    label={'Company Name'}
                    component={renderLureTextField}
                    validate={required}
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    fullWidth
                    name={'email'}
                    label={'Company Email'}
                    component={renderLureTextField}
                    validate={required}
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    fullWidth
                    name={'website'}
                    label={'Company Website'}
                    placeholder={'Company Website'}
                    component={renderLureTextField}
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    fullWidth
                    name={'phone'}
                    label={'Phone'}
                    placeholder={'Company Phone'}
                    component={renderLureTextField}
                  />
                </Grid>
              </Grid>
            </LureDialogContent>
            <LureDialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.rightBuffer}>
                  <Button
                    fullWidth
                    color={'primary'}
                    variant={'outlined'}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.leftBuffer}>
                  <Button
                    fullWidth
                    color={'primary'}
                    variant={'contained'}
                    type={'submit'}
                  >
                    Save Company
                  </Button>
                </Grid>
              </Grid>
            </LureDialogActions>
          </form>
        )}
      />
    </LureDialog>
  );
}

export default CompanyModal;
