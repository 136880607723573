import React, { useState } from 'react';
import { Form } from 'react-final-form';
import {
  Grid, Typography, makeStyles, Link as MuiLink,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import PreAuthFooter from '../shared/PreAuthFooter';
import { LOGIN_ROUTE } from '../../../constants/routes';
import RoleSelection from './RoleSelection';
import AuthSelection from './AuthSelection';
import { COMPANY_MANAGER } from '../../../constants/userRoles';

const styles = (theme) => ({
  containerPadding: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
});

// eslint-disable-next-line import/no-mutable-exports
const Register = () => {
  const [registrationStep, setRegistrationStep] = useState(0);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const history = useHistory();

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{ role: COMPANY_MANAGER }}
      render={({ handleSubmit, form }) => (
        <form autoComplete={'off'} onSubmit={handleSubmit}>
          <Grid container direction={'column'}>
            <Grid item container className={classes.containerPadding}>
              { registrationStep === 0
                ? <RoleSelection setRegistrationStep={setRegistrationStep} />
                : (
                  <AuthSelection
                    setRegistrationStep={setRegistrationStep}
                    formValues={get(form.getState(), 'values')}
                    role={get(form.getState(), 'values.role')}
                  />
                )}
            </Grid>
            <PreAuthFooter>
              <Typography variant={'body1'}>
                Return to
                {' '}
                <MuiLink style={{ cursor: 'pointer' }} onClick={() => history.push(LOGIN_ROUTE)}>Login</MuiLink>
              </Typography>
            </PreAuthFooter>
          </Grid>
        </form>
      )}
    />
  );
};

export default Register;
