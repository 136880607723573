import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

function useMomentTz() {
  const store = useSelector((state) => state.AdminPortal.store.activeStore);
  const [timezone, setTimezone] = useState('Australia/Brisbane');
  const momentTz = useCallback(
    (date) => moment(date).tz(timezone),
    [timezone],
  );

  useEffect(() => {
    if (store && store.timezone) {
      moment.tz().add(store.timezone);
      setTimezone(store.timezone);
    }
  }, [store]);

  return momentTz;
}

export default useMomentTz;
