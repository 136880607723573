import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';
import {
  Button, Grid, TableCell as MuiTableCell,
  Table, TableHead, TableRow, withStyles,
} from '@material-ui/core';
import moment from 'moment';
import { floor, get } from 'lodash';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import { updateCompany } from '../../store/actions/company';
import { updateStore } from '../../store/actions/AdminPortal/store';
import { closeTradingHourModal } from '../../store/actions/modals';
import { renderLureSelectField, renderLureSwitchField } from '../shared/formHelpers/fields';
import { snackError } from '../../store/actions/snackbar';

const TableCell = withStyles(() => ({
  body: {
    border: 0,
  },
}))(MuiTableCell);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  leftBuffer: {
    paddingLeft: theme.spacing(5),
  },
  rightBuffer: {
    paddingRight: theme.spacing(5),
  },
  columnHeading: {
    textAlign: 'center',
    marginBottom: '1em',
  },
  field: {
    textAlign: 'center',
  },
  day: {
    marginLeft: 50,
  },
});

function HeaderCell({ children }) {
  return (
    <TableCell>
      <Typography align={'center'}>
        <strong>{children}</strong>
      </Typography>
    </TableCell>
  );
}

function formatTradingHours(tradingHours) {
  return tradingHours.map((th) => ({
    ...th,
    open: moment()
      .startOf('day')
      .hour(th.open.hour)
      .minute(th.open.minute)
      .valueOf(),
    close: moment()
      .startOf('day')
      .hour(th.close.hour)
      .minute(th.close.minute)
      .valueOf(),
  }));
}

function TradingHoursModal() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.tradingHoursModal.isOpen);

  const Store = useSelector((state) => state.modals.tradingHoursModal.options);
  const company = useSelector((state) => state.auth.user.CurrentCompany);

  const initEntity = {
    name: 'Company',
    value: {
      tradingHours: [],
    },
    updateFunction: updateCompany,
  };
  const [entity, setEntity] = useState(initEntity);

  useEffect(() => {
    if (Store && Store._id) {
      setEntity({
        name: 'Store',
        value: {
          _id: Store._id,
          tradingHours: formatTradingHours(Store.tradingHours),
        },
        updateFunction: updateStore,
      });
    } else if (company && company._id) {
      setEntity({
        name: 'Company',
        value: {
          _id: company._id,
          tradingHours: formatTradingHours(company.tradingHours),
        },
        updateFunction: updateCompany,
      });
    }
  }, [Store, company]);

  const handleTradingHoursSubmit = useCallback((values) => {
    const tradingHours = values.tradingHours.map(({
      open, close, tableData, ...rest
    }) => ({
      ...rest,
      open: {
        hour: moment(open).hours(),
        minute: moment(close).minutes(),
      },
      close: {
        hour: moment(close).hours(),
        minute: moment(close).minutes(),
      },
    }));
    const updatePayload = entity.name === 'Store' ? { ...Store, tradingHours } : { tradingHours };
    dispatch(entity.updateFunction(entity.value._id, updatePayload))
      .then(() => dispatch(closeTradingHourModal()))
      .catch(() => dispatch(snackError('Error updating trading hours.')));
  }, [dispatch, entity]);

  const handleClose = useCallback(() => dispatch(closeTradingHourModal()), [dispatch]);

  const getTimeslots = useCallback(() => {
    const timeslots = [];
    let halfHour = false;

    for (let i = 0; i < 48; i += 1) {
      timeslots.push({
        hour: floor(i / 2),
        minute: halfHour ? 30 : 0,
      });
      halfHour = !halfHour;
    }

    return timeslots.map((th) => (
      moment()
        .startOf('day')
        .hour(th.hour)
        .minute(th.minute)
        .valueOf()
    ));
  }, []);

  return (
    <LureDialog
      disableEnforceFocus
      open={isOpen}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth
      disableBackdropClick
    >
      <LureDialogTitle>
        Edit {entity.name} Hours
      </LureDialogTitle>
      <Form
        onSubmit={handleTradingHoursSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={entity.value}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <LureDialogContent>
              <Table>
                <TableHead>
                  <TableRow style={{ border: 0 }}>
                    <HeaderCell>Day</HeaderCell>
                    <HeaderCell>Opening Hours</HeaderCell>
                    <HeaderCell>Closing Hours</HeaderCell>
                    <HeaderCell>Open?</HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name={'tradingHours'}>
                    {({ fields }) => (
                      fields.map((name) => {
                        const value = get(values, name, {});
                        return (
                          <TableRow key={name} hover>
                            <TableCell>
                              <Typography align={'center'}>
                                <strong>
                                  {moment().day(value.day + 1).format('dddd')}
                                </strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Field
                                fullWidth
                                name={`${name}.open`}
                                component={renderLureSelectField}
                                variant={'outlined'}
                                disabled={!get(values, `${name}.isOpen`, true)}
                              >
                                {
                                  getTimeslots().map((ts) => (
                                    <MenuItem
                                      value={ts}
                                      key={ts}
                                    >
                                      { moment(ts).format('h:mma')}
                                    </MenuItem>
                                  ))
                                }
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Field
                                fullWidth
                                name={`${name}.close`}
                                component={renderLureSelectField}
                                variant={'outlined'}
                                disabled={!get(values, `${name}.isOpen`, true)}
                              >
                                {
                                  getTimeslots().map((ts) => (
                                    <MenuItem
                                      value={ts}
                                      key={ts}
                                    >
                                      { moment(ts).format('h:mma')}
                                    </MenuItem>
                                  ))
                                }
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Grid container justify={'center'} alignItems={'center'}>
                                <Grid item>
                                  <Field
                                    fullWidth
                                    name={`${name}.isOpen`}
                                    component={renderLureSwitchField}
                                  />
                                </Grid>
                              </Grid>

                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </FieldArray>
                </TableBody>
              </Table>
            </LureDialogContent>
            <LureDialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.rightBuffer}>
                  <Button
                    fullWidth
                    color={'primary'}
                    variant={'outlined'}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.leftBuffer}>
                  <Button
                    fullWidth
                    color={'primary'}
                    variant={'contained'}
                    type={'submit'}
                  >
                    Save {entity.name} Hours
                  </Button>
                </Grid>
              </Grid>
            </LureDialogActions>
          </form>
        )}
      />
    </LureDialog>
  );
}

export default TradingHoursModal;

HeaderCell.propTypes = {
  children: PropTypes.string.isRequired,
};
