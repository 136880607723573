import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  Grid,
  MuiThemeProvider,
  Typography,
} from '@material-ui/core';
import {
  LureChip, LurePageHeading, LurePaper,
} from '@lureapps/lure-ui';
import { useHistory, useParams } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { ChevronDown } from 'react-feather';
import { getBooking } from '../../../store/actions/AdminPortal/booking';
import CustomerPanel from './CustomerPanel';
import StorePanel from './StorePanel';
import StaffPanel from './StaffPanel';
import ProductPanel from './ProductPanel';
import {
  ACCEPTED, CANCELLED, COMPLETED, REJECTED, REQUESTED,
} from '../../../constants/bookingStatuses';
import BookingActionMenu from './BookingActionMenu';
import { CUSTOMER } from '../../../constants/userRoles';
import BookingUpdatePanel from './BookingUpdatePanel';
import MessagePanel from './MessagePanel';
import { useIsTablet } from '../../shared/mediaQueries';

const styles = (theme) => ({
  mute: {
    color: theme.colors.darkGrey,
  },
  pageHeadingBuffer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(10),
  },
  subtitle: {
    color: theme.colors.darkGrey,
    fontSize: 14,
  },
  actionsButton: {
    marginRight: theme.spacing(2),
  },
  reducedPadding: {
    paddingRight: theme.spacing(5),
  },
  heading: {
    textTransform: 'uppercase',
    color: theme.colors.darkGrey,
  },
});


function BookingDetail() {
  const dispatch = useDispatch();
  const Booking = useSelector((state) => state.AdminPortal.bookings.activeBooking);
  const {
    Customer, Store, StaffMember, BookingProducts,
  } = Booking;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const { bookingId } = useParams();
  const bookingStatus = get(Booking, 'status', '-');
  let formattedTime = '';

  const userRole = useSelector((state) => state.auth.user.role);

  useEffect(() => {
    dispatch(getBooking(bookingId));
  }, [dispatch, bookingId]);

  const colourHelper = useCallback((status) => {
    switch (status) {
      case ACCEPTED:
        return 'blue';

      case REQUESTED:
        return 'purple';

      case CANCELLED:
        return 'red';

      case COMPLETED:
        return 'green';

      default:
        return 'orange';
    }
  }, []);

  if (Booking._id && Booking.Store) {
    formattedTime = moment(get(Booking, 'timeslots[0].timeslot'), 'x')
      .tz(get(Booking, 'Store.timezone'))
      .format('dddd Do MMM YYYY, h:mma');
  }

  return (
    <>
      <Grid
        container
        item
        justify={'space-between'}
        alignItems={'center'}
        className={
          classNames(
            classes.pageHeadingBuffer,
            { [classes.reducedPadding]: useIsTablet() },
          )
        }
      >
        <LurePageHeading
          title={'Bookings'}
          action={(
            <Grid container direction={'row'} justify={'center'} spacing={3}>
              <Grid className={classes.bottomBuffer}>
                {
                  userRole !== CUSTOMER
                  && (
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      endIcon={<ChevronDown size={16} />}
                      className={classes.actionsButton}
                      onClick={(e) => setAnchorEl(e.target)}
                    >
                      Booking Actions
                    </Button>
                  )
                }
              </Grid>
              <MuiThemeProvider theme={'black'}>
                <Button variant={'outlined'} onClick={() => history.goBack()}>Go back</Button>
              </MuiThemeProvider>
            </Grid>
          )}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={5} xl={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LurePaper shadow>
                  <Grid container direction={'column'} spacing={3}>
                    <Grid item container direction={'column'} spacing={2} xs>
                      <Grid item xs>
                        <Typography
                          variant={'body2'}
                          className={classes.heading}
                        >
                          Booking
                        </Typography>
                      </Grid>
                      <Grid item container xs>
                        <Grid item xs={6}>
                          <Typography variant={'h4'}>{`#${get(Booking, 'bookingReference', '')}`}</Typography>
                        </Grid>
                        <Grid item container justify={'flex-end'} xs>
                          <LureChip
                            label={bookingStatus}
                            color={colourHelper(bookingStatus)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <Typography variant={'body1'}>
                          {`${bookingStatus === REQUESTED ? 'Requested' : 'Booking'} time ${formattedTime.length ? formattedTime : ' - No store selected'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                    {
                      userRole === CUSTOMER && bookingStatus === REJECTED
                      && (
                        <Grid item xs>
                          <BookingUpdatePanel Booking={Booking} />
                        </Grid>
                      )
                    }
                    <Grid item xs>
                      <CustomerPanel bookingId={Booking._id} Customer={Customer} />
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                    <Grid item xs>
                      <StorePanel Booking={Booking} Store={Store} />
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                    <Grid item xs>
                      <StaffPanel Booking={Booking} StaffMember={StaffMember} />
                    </Grid>
                  </Grid>
                </LurePaper>
              </Grid>
              <Grid item xs={12}>
                <LurePaper>
                  <MessagePanel Booking={Booking} />
                </LurePaper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={7} xl={8}>
            <Grid container spacing={3}>
              <Grid item xs>
                <ProductPanel
                  emptyStateCopy={'No fitting rooms products'}
                  Products={get(BookingProducts, 'nodes')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BookingActionMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          Booking={Booking}
        />
      </Grid>
    </>
  );
}

export default BookingDetail;
