import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  heading: {
    textTransform: 'uppercase',
    color: theme.colors.darkGrey,
  },
});

function MessagePanel({ Booking }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { message } = Booking;

  return (
    <>
      <Typography variant={'body2'} className={classes.heading} gutterBottom>
        Message
      </Typography>
      <Typography variant={'body1'}>
        {message || 'No message was left'}
      </Typography>
    </>
  );
}

export default MessagePanel;
