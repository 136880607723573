import React, { useCallback } from 'react';
import {
  Grid, IconButton, Typography, makeStyles,
} from '@material-ui/core';
import { get } from 'lodash';
import { LureIcon } from '@lureapps/lure-ui';
import { useDispatch, useSelector } from 'react-redux';
import { openStaffModal } from '../../../store/actions/modals';

const styles = (theme) => ({
  bottomBuffer: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.colors.darkGrey,
  },
});

const Information = () => {
  const dispatch = useDispatch();
  const StaffMember = useSelector((state) => state.AdminPortal.staff.activeStaffMember);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const handleEdit = useCallback(() => {
    dispatch(openStaffModal(StaffMember));
  }, [dispatch, StaffMember]);

  return (
    <>
      <Grid container direction={'column'}>
        <Grid item container justify={'space-between'} alignItems={'center'} className={classes.bottomBuffer}>
          <Typography
            variant={'h4'}
          >
            {`${get(StaffMember, 'firstName')} ${get(StaffMember, 'lastName')}`}
          </Typography>
          <IconButton onClick={handleEdit} color={'primary'}>
            <LureIcon name={'edit-2'} color={'primary'} size={'small'} />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant={'body1'}>{get(StaffMember, 'email', '-')}</Typography>
        </Grid>
        <Grid item>
          <Typography>{get(StaffMember, 'phone', '-')}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Information;
