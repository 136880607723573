import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import GoogleLogo from '../../../assets/logos/google-g-logo.svg';

const styles = () => ({
  googleBtn: {
    background: 'rgba(0, 0, 0, .05)',
    color: 'rgba(0, 0, 0, .54)',
    textTransform: 'none',
    borderRadius: '4px',
  },
  googleLogo: {
    width: '1em',
    height: '1em',
    marginRight: '15px',
  },
});

const SignInWithGoogle = ({ handleConnectWithGoogle, buttonText = 'Sign in with Google' }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Button
        fullWidth
        className={classes.googleBtn}
        onClick={handleConnectWithGoogle}
      >
        <img alt={'google logo'} src={GoogleLogo} className={classes.googleLogo} />
        {buttonText}
      </Button>
    </>
  );
};

export default SignInWithGoogle;
