import { gql } from 'apollo-boost';
import gqlClient from './GraphQLService';

const GQL_CREATE_COMPANY = gql`
    mutation createCompany($company: CompanyInput!) {
        createCompany(company: $company) {
            name
            phone
            email
            website
        }
    }
`;

const GQL_UPDATE_COMPANY = gql`
    mutation updateCompany($companyId:ID!, $company: CompanyUpdateInput!) {
        updateCompany(companyId:$companyId, company:$company) {
            _id
            name
            email
            phone
            website,
            tradingHours {
              day
              open {
                hour
                minute
              }
              close {
                hour
                minute
              }
              isOpen
            }
        }
    }
`;

const GQL_CUSTOMER_COMPANIES = gql`
    query customerCompanies($customerId: ID!) {
        customerCompanies(customerId: $customerId) {
            _id
            name
            phone
            email
            website
        }
    }
`;

export const GQL_COMPANY_SCRIPT = gql`
    query me {
      me {
        CurrentCompany {
          LureScript
        }
      }
    }
`;

class CompanyService {
  static parseCompany(company) {
    return {
      name: company.name,
      email: company.email,
      phone: company.phone,
      website: company.website,
      tradingHours: company.tradingHours,
    };
  }

  static createCompany(data) {
    return gqlClient.mutate({
      mutation: GQL_CREATE_COMPANY,
      variables: {
        company: data,
      },
    })
      .then(({ data: { createCompany: company } }) => company);
  }

  static updateCompany(companyId, company) {
    return gqlClient.mutate({
      mutation: GQL_UPDATE_COMPANY,
      variables: {
        companyId,
        company: this.parseCompany(company),
      },
    });
  }

  static customerCompanies(customerId) {
    return gqlClient.query({
      query: GQL_CUSTOMER_COMPANIES,
      variables: {
        customerId,
      },
    });
  }
}

export default CompanyService;
