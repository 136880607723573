import React, { useCallback } from 'react';
import { get } from 'lodash';
import { Button, Grid, Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import {
  LureDialog, LureDialogActions, LureDialogContent, LureDialogTitle,
} from '@lureapps/lure-ui';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { renderButtonGroup } from '../../shared/formHelpers/fields';
import { COMPANY_MANAGER, CUSTOMER } from '../../../constants/userRoles';
import CompanyFields from './CompanyFields';
import CustomerFields from './CustomerFields';
import useFirebase from '../../../hooks/useFirebase';
import { logout, setAuthUser } from '../../../store/actions/auth';
import { closeUserRegistrationModal } from '../../../store/actions/modals';
import UserService from '../../../services/UserService';
import CompanyService from '../../../services/CompanyService';

const styles = (theme) => ({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 10,
    marginBottom: theme.spacing(1.2),
  },
  btnGroup: {
    width: '100%',
  },
  btnGroupButtons: {
    width: '100%',
  },
});

const UserRegistrationModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.userRegistrationModal.isOpen);
  const { user: firebaseUser } = useFirebase();
  const firebaseEmail = get(firebaseUser, 'email');

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const submit = useCallback(async ({ user, company, role }) => {
    // eslint-disable-next-line no-param-reassign
    user.uid = firebaseUser.uid;
    await firebaseUser.updateProfile({
      displayName: user.displayName,
    });
    await UserService.registerUser(user, role);
    if (role === COMPANY_MANAGER) {
      await CompanyService.createCompany(company);
    }
    dispatch(setAuthUser());
    dispatch(closeUserRegistrationModal());
  }, [dispatch, firebaseUser]);

  const close = useCallback(() => {
    dispatch(logout());
    dispatch(closeUserRegistrationModal());
  }, [dispatch]);

  return (
    <>
      <LureDialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth
        disableBackdropClick
      >
        <LureDialogTitle>
          Register a User
        </LureDialogTitle>
        <Form
          onSubmit={submit}
          initialValues={{ role: COMPANY_MANAGER, user: { email: firebaseEmail } }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <LureDialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>Account Type</Typography>
                    <Field
                      name={'role'}
                      component={renderButtonGroup}
                      className={classes.btnGroup}
                      size={'small'}
                      exclusive
                    >
                      <ToggleButton value={COMPANY_MANAGER} className={classes.btnGroupButtons}>
                        Company
                      </ToggleButton>
                      <ToggleButton value={CUSTOMER} className={classes.btnGroupButtons}>
                        Customer
                      </ToggleButton>
                    </Field>
                  </Grid>
                  {
                    form.getState().values.role === COMPANY_MANAGER
                      ? <CompanyFields />
                      : <CustomerFields />
                  }
                </Grid>
              </LureDialogContent>
              <LureDialogActions>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'contained'}
                      type={'submit'}
                    >
                      Complete Registration
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={'outlined'}
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </LureDialogActions>
            </form>
          )}
        />
      </LureDialog>
    </>
  );
};

export default UserRegistrationModal;
