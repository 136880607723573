import { combineReducers } from 'redux';
import booking from './booking';
import marketplace from './marketplace';

const CustomerPortal = combineReducers({
  booking,
  marketplace,
});

export default CustomerPortal;
