import { Grid, IconButton, Typography } from '@material-ui/core';
import { LureIcon } from '@lureapps/lure-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  link: {
    overflowWrap: 'anywhere',
  },
});

function CustomerDetails() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { fullName, email, phone } = useSelector((state) => state.auth.user.Customer);
  return (
    <Grid container direction={'column'}>
      <Grid item container justify={'space-between'} alignItems={'center'} style={{ marginBottom: '12px' }}>
        <Typography variant={'h4'}>{fullName}</Typography>
        <IconButton color={'primary'}>
          <LureIcon name={'edit-2'} color={'primary'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant={'body1'} className={classes.link}>{email}</Typography>
      </Grid>
      <Grid item>
        <Typography>{phone}</Typography>
      </Grid>
    </Grid>
  );
}

export default CustomerDetails;
