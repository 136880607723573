import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import { get } from 'lodash';
import { StoreStaffAutocomplete } from '../../shared/formHelpers/fields';
import { required } from '../../shared/formHelpers/validation';

const styles = (theme) => ({
  allocateStaffInput: {
    width: '100%',
  },
  headingBlock: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
  },
});

const AllocateStaff = ({ Booking }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid item container xs={12} direction={'column'} spacing={3}>
        <Grid item>
          <Typography variant={'h6'} className={classes.headingBlock} gutterBottom>Staff Allocation</Typography>
          <Typography variant={'subheading'}>Please select a staff member to facilitate this booking</Typography>
        </Grid>
        <Grid item xs={5}>
          <Field
            name={'StaffMember'}
            component={StoreStaffAutocomplete}
            Staff={get(Booking, 'Store.Staff', [])}
            validate={required}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AllocateStaff;
